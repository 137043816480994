import React, { Component } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TextField, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const styles = theme => ({
  root: {},
  container: {
    maxHeight: '70vh'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 170
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
});

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seach_text: ''
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <TextField
          placeholder="User_id, SDT, email, Mã yêu câu"
          id="outlined-basic"
          label="Tìm kiếm"
          variant="outlined"
          value={this.state.seach_text}
          onChange={event => this.setState({ seach_text: event.target.value })}
        />
        <IconButton>
          <SearchIcon
            onClick={() => this.props.onClick(this.state.seach_text)}
          />
        </IconButton>
      </div>
    );
  }
}

export default withStyles(styles)(User);
