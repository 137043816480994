const list_check_fail = [
  { title: 'Ảnh chưa đúng nội dung', check: false },
  { title: 'Ảnh mặt trước và mặt sau không cùng loại', check: false },
  { title: 'Ảnh CMTND/CCCD thiếu 4 góc', check: false },
  { title: 'Ảnh thuộc loại photocopy hoặc chụp ảnh từ màn hình', check: false },
  { title: 'Ảnh quá tối hoặc bị lóe sáng', check: false },
  { title: 'Ảnh chụp bị nhòe số CMTND/CCCD', check: false },
  { title: 'CMTND/CCCD hết hạn sử dụng', check: false },
  { title: 'Không có khuôn mặt xuất hiện trong ảnh selfie', check: false },
  { title: 'Ảnh selfie bị nhắm mắt', check: false },
  { title: 'Ảnh selfie quá tối hoặc lóe sáng', check: false },
  { title: 'Người dùng chưa đủ 18 tuổi', check: false },
  {
    title: 'Ảnh selfie và ảnh mặt trước của CMTND/CCCD không cùng một người',
    check: false
  }
];

export { list_check_fail };
