import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, CircularProgress } from '@material-ui/core';
import UsersTable from './Table';
import CONST_DATA from 'constant';
import Axios from 'axios';
import TableAll from './TableAll';
import { to_date_unix } from 'utility';

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  }
});

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: null,
      user_id: null,
      data_success: false,
      data: null,
      data_ticket: null,
      data_ticket_success: false,
      page: 1,
      totalPage: 50,
      limit: 20,
      search_status_id: null,
      loading: false
    };
  }

  check_login = () => {
    let login_data = localStorage.getItem(CONST_DATA.localStorage_login);

    if (login_data == null) {
      this.props.history.push(CONST_DATA.router_sign_in);
    } else {
      login_data = JSON.parse(login_data);
      this.setState({
        access_token: login_data.access_token,
        user_id: login_data.user_id,
        role_admin: login_data.role,
        // data_success: true
      });
    }
  };

  async componentDidMount() {
    await this.check_login();
    if (this.props.type_get == 'viec_cua_toi') {
      await this.get_data_viec_cua_toi();
    }
    else if (this.props.type_get === 'all') {
      this.setState({ data_success: true })
    }
    else if (this.props.type_get !== 'all') {
      await this.get_data();
    }
  }

  handOnChangeStatus = (value) => {
    this.setState({ search_status_id: value });
  }
  handleOnChangePage = (element, value) => {
    this.setState({ page: value });
  }

  componentDidUpdate(prevProps, prevState) {
    const flag = (prevState.page !== this.state.page) || (prevState.search_status_id !== this.state.search_status_id)
    if (flag) {
      this.get_data();
    }
  }

  get_data_viec_cua_toi = () => {
    const body_req = {
      user_id: this.state.user_id,
      access_token: this.state.access_token
    };

    Axios.post(CONST_DATA.get_ticket_by_admin_id, body_req)
      .then(response => {
        if (response.data.status == 'success') {
          this.setState({
            data_success: true,
            data: response.data.data
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  get_data = () => {
    const body_req = {
      user_id: this.state.user_id,
      type_get: this.props.type_get,
      access_token: this.state.access_token,
      page: this.state.page,
      limit: this.state.limit,
      // search_status_id: this.state.search_status_id,
    };
    if(this.props.type_get == 'can_xu_ly') {
      body_req.limit = 1000
    }
    this.setState({ loading: true })
    Axios.post(CONST_DATA.get_ticket_admin, body_req)
      .then(response => {
        if (response.data.status == 'success') {
          this.setState({
            data_success: true,
            data: response.data.data,
            loading: false
          });
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false })
      });
  };

  render_table = () => {
    if (this.props.type_get == 'all') {
      return (
        <TableAll
          access_token={this.state.access_token}
          user_id={this.state.user_id}
          role_admin={this.state.role_admin}
        />
      );
    } else {
      return (
        <UsersTable
          role_admin={this.state.role_admin}
          user_id={this.state.user_id}
          data={this.state.data}
          page={this.state.page}
          totalPage={this.state.totalPage}
          typeGet={this.props.type_get}
          handleOnChangePage={this.handleOnChangePage}
          handOnChangeStatus={this.handOnChangeStatus}
          loading={this.state.loading}
        />
      );
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            {this.state.data_success ? (
              this.render_table()
            ) : (
              <CircularProgress />
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(User);