import React, { Component } from 'react';
import { toast } from 'react-toastify';
import CONST_DATA from 'constant';
import Axios from 'axios';
import CmtItem from './CmtItem';
import {
  Button,
  CircularProgress,
  TextField,
  Typography
} from '@material-ui/core';
import Selfie from './Selfie';
import UpdateUserInfo from './UpdateUserInfo';
import IDCard from './IDCard';
import VirtualizedList from 'components/VirtualizedList/ListVirtualized';
import CheckUserPassport from './Passport';
import ReEkyc from './ReEkyc';

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: null,
      user_id: null,
      data_success: false,
      data: null,

      is_loading: false,
      user_id_chosing: this.props.match.params.user_id,

      seach_text: null,

      data_detail: null,
      data_detail_success: false,

      data_cmt: null,
      data_cmt_success: false,

      need_cs_check_ly_do: 1,
      data_true_id: null,
      loading_confirm: false
    };
  }

  check_login = () => {
    let login_data = localStorage.getItem(CONST_DATA.localStorage_login);

    if (login_data === null) {
      this.props.history.push(CONST_DATA.router_sign_in);
    } else {
      login_data = JSON.parse(login_data);
      this.setState({
        access_token: login_data.access_token,
        user_id: login_data.user_id
      });
    }
  };

  async componentDidMount() {
    await this.check_login();
    await this.get_list(this.state.need_cs_check_ly_do);

    if (this.state.user_id_chosing != null) {
      this.get_data_detail(this.state.user_id_chosing);
    }
  }

  send_accept = data => {
    const body_req = {
      admin_assign: this.state.user_id,
      access_token: this.state.access_token,
      user_id: this.state.user_id_chosing,
      ocr_id: data
    };
    this.setState({ loading_confirm: true })

    Axios.post(CONST_DATA.cmt_trueid_api_cs_accept, body_req)
      .then(response => {
        if (response.data.status === 'success') {
          this.props.history.push(CONST_DATA.router_cmt);
          toast.success('Cập nhật thông tin thành công.')
          window.location.reload();
        }
        this.setState({ loading_confirm: false })
      })
      .catch(error => {
        this.setState({ loading_confirm: false })
        toast.error('Cập nhật thông tin không thành công vui lòng thử lại.')
      });
  };

  send_accept_new = data => {
    const body_req = {
      admin_assign: this.state.user_id,
      access_token: this.state.access_token,
      user_id: this.state.user_id_chosing,
      ocr_id: data
    };
    this.setState({ loading_confirm: true })

    Axios.post(CONST_DATA.ekyc_api_cs_accept, body_req)
      .then(response => {
        if (response.data.error_code === '0') {
          this.props.history.push(CONST_DATA.router_cmt);
          toast.success('Cập nhật thông tin người dùng thành công.');
          window.location.reload();
        } else {
          toast.error(`Cập nhật thông tin người dùng không thành công: ${response.data.message}`)
        }
        this.setState({ loading_confirm: false })
      })
      .catch(error => {
        this.setState({ loading_confirm: false })
        toast.error('Cập nhật thông tin không thành công vui lòng thử lại.')
      });
  };

  send_confirm_selfie = (status, reason) => {
    const body_req = {
      admin_assign: this.state.user_id,
      access_token: this.state.access_token,
      user_id: this.state.user_id_chosing,
      accept: status,
      // content: reason
    };
    // if (reason) {
    //   body_req.content = reason;
    // }
    // console.log({ body_req });

    Axios.post(CONST_DATA.check_selfie, body_req)
      .then(response => {
        const result = response.data;
        if (result.error_code === '0') {
          this.props.history.push(CONST_DATA.router_cmt);
          toast.success('Cập nhật thông tin người dùng thành công.');
        }
        else {
          toast.error(`Cập nhật thông tin người dùng không thành công: ${result.message}`)
        }
      })
      .catch(error => {
        console.log(error)
      });
  };

  send_reject = data => {
    const body_req = {
      admin_assign: this.state.user_id,
      access_token: this.state.access_token,
      user_id: this.state.user_id_chosing,
      reject_data: data
    };
    this.setState({ loading_confirm: true })
    Axios.post(CONST_DATA.cmt_trueid_api_cs_reject, body_req)
      .then(response => {
        if (response.data.status === 'success') {
          this.props.history.push(CONST_DATA.router_cmt);
          window.location.reload();
          this.setState({ loading_confirm: false })
        }
        this.setState({ loading_confirm: false })
      })
      .catch(error => {
        toast.error('Cập nhật không thành công vui lòng thử lại.')
        this.setState({ loading_confirm: false })
      });
  };

  send_reject_new = data => {
    const body_req = {
      admin_assign: this.state.user_id,
      access_token: this.state.access_token,
      user_id: this.state.user_id_chosing,
      reject_data: data
    };
    this.setState({ loading_confirm: true })
    Axios.post(CONST_DATA.ekyc_api_cs_reject, body_req)
      .then(response => {
        if (response.data.error_code === '0') {
          this.props.history.push(CONST_DATA.router_cmt);
          toast.success('Cập nhật thông tin người dùng thành công.');
          window.location.reload();
        } else {
          toast.error(`Cập nhật thông tin người dùng không thành công: ${response.data.message}`)
        }
        this.setState({ loading_confirm: false })
      })
      .catch(error => {
        toast.error('Cập nhật không thành công vui lòng thử lại.')
        this.setState({ loading_confirm: false })
      });
  };

    // re-ekyc
    send_re_ekyc_accept = data => {
        const body_req = {
            admin_assign: this.state.user_id,
            access_token: this.state.access_token,
            user_id: this.state.user_id_chosing,
            ocr_id: data
        };
        this.setState({ loading_confirm: true })

        this.send_cs_action(CONST_DATA.re_ekyc_cs_accept, body_req)
    };

    send_re_ekyc_reject = data => {
        const body_req = {
            admin_assign: this.state.user_id,
            access_token: this.state.access_token,
            user_id: this.state.user_id_chosing,
            reject_data: data
        };
        this.setState({ loading_confirm: true })
        
        this.send_cs_action(CONST_DATA.re_ekyc_cs_reject, body_req)
    };

    send_cs_action = (url, data) => {
        Axios.post(url, data)
            .then(response => {
                if (response.data.error_code === '0') {
                    this.props.history.push(CONST_DATA.router_cmt);
                    toast.success('Cập nhật thông tin người dùng thành công.');
                    window.location.reload();
                } else {
                    toast.error(`Cập nhật thông tin người dùng không thành công: ${response.data.message}`)
                }
                this.setState({ loading_confirm: false })
            })
            .catch(error => {
                toast.error('Cập nhật không thành công vui lòng thử lại.')
                this.setState({ loading_confirm: false })
            });
    }

  get_list = need_cs_check_ly_do => {
    const body_req = {
      admin_assign: this.state.user_id,
      access_token: this.state.access_token,
      need_cs_check_ly_do: need_cs_check_ly_do
    };

    Axios.post(CONST_DATA.cmt_get_list, body_req)
      .then(response => {
        if (response.data.status === 'success') {
          this.setState({
            data_success: true,
            data: response.data.data
          });
        }
      })
      .catch(error => {
      });
  };

  get_data_detail = async uid => {
    const body_req = {
      admin_assign: this.state.user_id,
      access_token: this.state.access_token,
      user_id: uid,
      is_re_ekyc: [14,15,16].includes(this.state.need_cs_check_ly_do)
    };

    await this.setState({
      data_detail_success: false,
      data_detail: null,
      data_cmt_success: false,
      data_cmt: null,
      is_loading: true
    });

    await Axios.post(CONST_DATA.cmt_get_image, body_req)
      // Axios.post('https://cs.finhay.com.vn/api_finhaycs/cmt/get_image', body_req)
      .then(response => {
        if (response.data.status === 'success') {
          this.setState({
            data_detail_success: true,
            data_detail: response.data.data,
            is_loading: false
          });
        }
        else {

        }
      })
      .catch(error => {
        console.log(error);
      });


    // cap nhat thong tin user
    if ([5,7,9,10,11,12,13,14,15,16].includes(this.state.need_cs_check_ly_do)) {
      await Axios.post(CONST_DATA.cmt_get_detail_user_id, body_req)
        .then(response => {
          if (response.data.status === 'success') {
            const { user_info_updated_by_user, true_id } = response.data.data;
            if (true_id && true_id.ocr_id && true_id.ocr_id.data_fields) {
              console.log(true_id.ocr_id.data_fields);
              const data_true_id = true_id.ocr_id.data_fields;
              const data_true_id_state = {};
              for (const key in data_true_id) {
                data_true_id_state[key] = data_true_id[key].value;
              }
              this.setState({ data_true_id: data_true_id_state })
            }
            this.setState({ data_cmt_success: true, data_cmt: user_info_updated_by_user, is_loading: false });
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
    else {
      await Axios.post(CONST_DATA.cmt_get_detail_user_id, body_req)
        .then(response => {
          if (response.data.status === 'success') {
            this.setState({
              data_cmt_success: true,
              data_cmt: response.data.data,
              is_loading: false
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  seach_user_id = () => {
    this.props.history.push(
      CONST_DATA.router_cmt + '/' + this.state.seach_text
    );

    this.setState({
      user_id_chosing: this.state.seach_text
    });
  };

  render_detail_info = () => {
    if (this.state.need_cs_check_ly_do === 6) {
      return <Selfie
        data={this.state.data_detail}
        user_id={this.state.user_id_chosing}
        data_cmt={this.state.data_cmt}
        send_confirm_selfie={this.send_confirm_selfie}
        loading_confirm={this.state.loading_confirm}
      ></Selfie>
    }
    if (this.state.need_cs_check_ly_do === 5) {
      return <UpdateUserInfo
        data={this.state.data_detail}
        user_id={this.state.user_id_chosing}
        data_cmt={this.state.data_cmt}
        handleAccept={data => this.handleAccept(data)}
        data_true_id={this.state.data_true_id}
        loading_confirm={this.state.loading_confirm}
        send_confirm_selfie={this.send_confirm_selfie}
      ></UpdateUserInfo>
    }
    if (this.state.need_cs_check_ly_do === 8) {
      return <CheckUserPassport
        data={this.state.data_detail}
        user_id={this.state.user_id_chosing}
        data_cmt={this.state.data_cmt}
        send_accept={data => this.acceptAndUpdatePassportInfor(data)}
        send_reject={data => this.rejectPassportInfor(data)}
        loading_confirm={this.state.loading_confirm}
      ></CheckUserPassport>
    }
    if ([7,9,10,11,12,13].includes(this.state.need_cs_check_ly_do)) {
      return <IDCard
        data={this.state.data_detail}
        user_id={this.state.user_id_chosing}
        data_cmt={this.state.data_cmt}
        data_true_id={this.state.data_true_id}
        loading_confirm={this.state.loading_confirm}
        send_accept={data => this.send_accept_new(data)}
        send_reject={data => this.send_reject_new(data)}
      ></IDCard>
    }
    if ([14,15,16].includes(this.state.need_cs_check_ly_do)) {
      return <ReEkyc
        data={this.state.data_detail}
        user_id={this.state.user_id_chosing}
        data_cmt={this.state.data_cmt}
        data_true_id={this.state.data_true_id}
        loading_confirm={this.state.loading_confirm}
        send_accept={data => this.send_re_ekyc_accept(data)}
        send_reject={data => this.send_re_ekyc_reject(data)}
      ></ReEkyc>
    }
    return <CmtItem
      data={this.state.data_detail}
      user_id={this.state.user_id_chosing}
      data_cmt={this.state.data_cmt}
      send_accept={data => this.send_accept(data)}
      send_reject={data => this.send_reject(data)}
      loading_confirm={this.state.loading_confirm}
    />
  };

  handleAccept = async (data_ocr) => {
    const body_req = {
      access_token: this.state.access_token,
      admin_assign: this.state.user_id,
      user_id: this.state.user_id_chosing,
      ocr_id: data_ocr
    };
    this.setState({ loading_confirm: true })
    await Axios.post(CONST_DATA.accept_trueid_v2, body_req)
      .then(response => {
        const result = response.data;
        // if (result.status === 'success') {
        //   toast.success('Cập nhật thông tin thành công.');
        //   window.location.reload();
        // }
        // else if (result.status === 'error') {
        //   toast.error(`Cập nhật thông tin không thành công: ${result.data}`);
        // }
        if (result.error_code === '0') {
          toast.success('Cập nhật thông tin thành công.');
          window.location.reload();
        }
        else {
          toast.error(`Cập nhật thông tin không thành công: ${result.message}`);
        }
        this.setState({ loading_confirm: false })
      })
      .catch(error => {
        this.setState({ loading_confirm: false })
        console.log(error);
      });
  }

  // accept và update thông tin passport người dùng
  acceptAndUpdatePassportInfor = async (passport_info) => {
    const body_req = {
      access_token: this.state.access_token,
      admin_assign: this.state.user_id,
      user_id: this.state.user_id_chosing,
      passport_info: passport_info
    };
    this.setState({ loading_confirm: true })
    await Axios.put(CONST_DATA.cmt_passport_api_cs_accept, body_req)
      .then(response => {
        const result = response.data;
        if (result.error_code === '0') {
          this.props.history.push(CONST_DATA.router_cmt);
          toast.success('Cập nhật thông tin thành công.');
          window.location.reload();
        }
        else {
          toast.error(`Cập nhật thông tin không thành công: ${result.message}`);
        }
        this.setState({ loading_confirm: false })
      })
      .catch(error => {
        this.setState({ loading_confirm: false })
        console.log(error);
      });
  }

  //reject passport user
  rejectPassportInfor = async (reason) => {
    const body_req = {
      access_token: this.state.access_token,
      admin_assign: this.state.user_id,
      user_id: this.state.user_id_chosing,
      reason: reason
    };
    this.setState({ loading_confirm: true })
    await Axios.put(CONST_DATA.cmt_passport_api_cs_reject, body_req)
      .then(response => {
        const result = response.data;
        if (result.error_code === '0') {
          toast.success('Đã thành công.');
          this.props.history.push(CONST_DATA.router_cmt);
          window.location.reload();
        }
        else {
          toast.error(`Cập nhật thông tin không thành công: ${result.message}`);
        }
        this.setState({ loading_confirm: false })
      })
      .catch(error => {
        this.setState({ loading_confirm: false })
        console.log(error);
      });
  }

  handleOnClickItemUserId = (user_id) => {
    this.props.history.push(
      CONST_DATA.router_cmt + '/' + user_id
    );
    this.setState({
      user_id_chosing: user_id,
      data_cmt_success: false,
      data_detail_success: false
    });

    this.get_data_detail(user_id);
  }

  render() {
    let need_cs_check_ly_do_arr = [
      // { code: 1, title: 'Quá 3 lần check' },
      // { code: 2, title: 'Hộ chiếu' },
      // { code: 3, title: 'Sai mặt' },
      // { code: 4, title: 'Tên - bank - cmt không khớp' },
      { code: 5, title: 'Cập nhật thông tin người dùng' },
      { code: 6, title: 'Đối chiếu Selfie - CMT' },
      { code: 8, title: 'Đối chiếu Hộ chiếu' },
      { code: 7, title: 'Dưới 18 tuổi' },
      { code: 9, title: 'CMT hết hạn' },
      { code: 10, title: 'Cập nhật thông tin người dùng(Mới)' },
      { code: 11, title: 'Đối chiếu Selfie - CMT(Mới)' },
      { code: 12, title: 'Trùng Số CMT' },
      { code: 13, title: 'ID Validity lỗi' },
      { code: 14, title: '[Re-eKYC] Chụp ảnh sai 3 lần' },
      { code: 15, title: '[Re-eKYC] User thay đổi thông tin' },
      { code: 16, title: '[Re-eKYC] ID Validity lỗi' },
    ];

    return (
      <div style={{ display: 'flex', padding: 10 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}>
          {need_cs_check_ly_do_arr.map(item => (
            <Button
              onClick={() => {
                this.setState({
                  data: null,
                  data_success: false,
                  need_cs_check_ly_do: item.code,
                  data_cmt_success: false
                });
                this.get_list(item.code);
              }}
              style={{ margin: 10 }}
              variant="contained"
              color={
                this.state.need_cs_check_ly_do === item.code
                  ? 'primary'
                  : 'default'
              }>
              <Typography
                style={{
                  color:
                    this.state.need_cs_check_ly_do === item.code
                      ? 'rgb(250,250,250)'
                      : 'rgb(63,81,181)'
                }}>
                {item.title}
              </Typography>
            </Button>
          ))}

          <TextField
            placeholder="User_id"
            id="outlined-basic"
            label="Tìm kiếm"
            variant="outlined"
            value={this.state.seach_text}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                this.seach_user_id();
                this.get_data_detail(this.state.seach_text);
              }
            }}
            onChange={event =>
              this.setState({ seach_text: event.target.value })
            }
          />


          {this.state.data_success
            ? <VirtualizedList data={this.state.data} handleOnCick={this.handleOnClickItemUserId}  ></VirtualizedList>
            : null}
        </div>

        <div style={{ flex: 8 }}>
          {this.state.is_loading ? <CircularProgress /> : null}
          {this.state.data_detail_success && this.state.data_cmt_success && (
            this.render_detail_info()
          )}
        </div>
      </div>
    );
  }
}

export default User;
