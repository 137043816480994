import React, { Component } from 'react';
import {
    Button,
    FormControl,
    FormGroup,
    Checkbox,
    FormControlLabel,
    Card,
    Box,
    Typography,
    TextField
} from '@material-ui/core';
import CONST_DATA from 'constant';
import Axios from 'axios';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import { list_check_fail } from './data';

class IDCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ly_do_reject: 0,
            reject: false,
            accept: false,
            deg_rotate: 0,
            list_check_fail: list_check_fail,

            is_accept: null,

            id_name: null,
            id_number: null,
            id_dob: null,
            id_issue_address: null,
            id_issue_date: null,
            unrender:false,
        };
    }

    getValueFromTrueIdAndUser(key, data_cmt, key_true_id, data_true_id) {
        if (data_cmt && data_cmt[key]) return data_cmt[key]
        if (data_true_id && data_true_id[key_true_id]) return data_true_id[key_true_id]
        return ''
    }

    componentDidMount() {
        let full_name = this.getValueFromTrueIdAndUser('full_name', this.props.data_cmt, 'name', this.props.data_true_id);
        let identity_number = this.getValueFromTrueIdAndUser('identity_number', this.props.data_cmt, 'id_number', this.props.data_true_id);
        let dob = this.getValueFromTrueIdAndUser('dob', this.props.data_cmt, 'dob', this.props.data_true_id);
        let id_issue_address = this.getValueFromTrueIdAndUser('id_issue_address', this.props.data_cmt, 'given_place', this.props.data_true_id);
        let id_issue_date = this.getValueFromTrueIdAndUser('id_issue_date', this.props.data_cmt, 'given_date', this.props.data_true_id);

        this.setState({
            id_name: full_name,
            id_number: identity_number,
            id_dob: dob,
            id_issue_date,
            id_issue_address,
        })
    }

    render_img = src_link => {
        let max_h = 400;
        return (
            <div style={{ maxHeight: max_h, maxWidth: max_h }}>
                <img
                    src={src_link}
                    style={{
                        transform: 'rotate(' + this.state.deg_rotate + 'deg)',
                        maxHeight: max_h,
                        maxWidth: max_h
                    }}
                    alt=""
                />
            </div>
        );
    };

    render_img_selfie = src_link => {
        let max_h = 150;
        return (
            <div style={{ height: max_h, width: max_h }}>
                <img src={src_link} style={{ maxHeight: max_h, maxWidth: max_h }} alt="" />
            </div>
        );
    };

    onChangeList_fail = event => {

        let pre_list_check = this.state.list_check_fail;
        let index_of_item = this.state.list_check_fail.findIndex(
            a => a.title === event.target.name
        );
        pre_list_check[index_of_item].check = event.target.checked;
        this.setState({ list_check_fail: pre_list_check });
    };

    card_text = (label, value, onChange, key) => {
        return (
            <div
                style={{
                    margin: 5,
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                <Typography style={{ flex: 1 }}>{label}</Typography>
                <TextField
                    style={{ flex: 3 }}
                    id="outlined-basic"
                    variant="outlined"
                    fullWidth
                    value={value}
                    onChange={event => onChange(event.target.value)}
                />
            </div>
        );
    };

    render_accept = () => {
        return (
            <Card
                style={{
                    margin: 10,
                    padding: 10,
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                {this.card_text('Họ và tên', this.state.id_name, value =>
                    this.setState({ id_name: value }), 'name'
                )}
                {this.card_text('Số CMTND/CCCD hộ chiếu', this.state.id_number, value =>
                    this.setState({ id_number: value }), 'id_number'
                )}
                {this.card_text('Ngày sinh', this.state.id_dob, value =>
                    this.setState({ id_dob: value }), 'dob'
                )}
                {this.card_text('Ngày cấp', this.state.id_issue_date, value =>
                    this.setState({ id_issue_date: value }), 'given_date'
                )}
                {this.card_text('Nơi cấp', this.state.id_issue_address, value =>
                    this.setState({ id_issue_address: value }), 'given_place'
                )}
            </Card>
        );
    };

    render_rejcet = () => {
        return (
            <Card
                style={{
                    margin: 10,
                    padding: 10,
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                <FormControl required component="fieldset">
                    <FormGroup>
                        {this.state.list_check_fail.map(item => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={item.check}
                                        onChange={event => this.onChangeList_fail(event)}
                                        name={item.title}
                                    />
                                }
                                label={item.title}
                            />
                        ))}
                    </FormGroup>
                </FormControl>
            </Card>
        );
    };

    isValidDate(date) {
        return /^\d{2}\/\d{2}\/\d{4}$/.test(date);
    }

    render_cs_confirm = () => {
        if (this.state.is_accept) {
            if (
                this.state.id_number && this.state.id_name && this.state.id_issue_address && this.isValidDate(this.state.id_issue_date)
                && this.isValidDate(this.state.id_dob)
            ) {
                return (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                            this.props.send_accept({
                                identity_number: this.state.id_number,
                                full_name: this.state.id_name,
                                dob: this.state.id_dob,
                                id_issue_date: this.state.id_issue_date,
                                id_issue_address: this.state.id_issue_address
                            })
                        }>
                        Xác nhận
                    </Button>
                );
            }
        }
    };

    render() {
        return (
            <div style={{display: this.state.unrender ? 'none' : 'block' }}>
                <Card style={{ margin: 10, padding: 10 }}>
                    <Box style={{ margin: 10, display: 'flex', flexDirection: 'row' }}>
                        {this.render_img_selfie(this.props.data.result.selfie)}
                        {this.render_img(this.props.data.result.front)}
                        {this.render_img(this.props.data.result.back)}
                    </Box>

                    <Button
                        onClick={() =>
                            this.setState({ deg_rotate: this.state.deg_rotate + 90 })
                        }>
                        <RotateRightIcon />
                    </Button>
                </Card>

                <div>
                    <Button
                        variant="contained"
                        color="secondary"
                        style={{ marginLeft: "11px" }}
                        onClick={() => this.setState({ is_accept: true })}>
                        Accept
          </Button>
          <Button
                variant="contained"
                style={{
                  backgroundColor: "rgb(244,67,54)",
                  color: "white",
                  marginLeft: "10px",
                }}
                onClick={() =>
                  this.props.send_reject('')
                }
              >
                Reject
              </Button>
                    {this.state.is_accept === true ? this.render_accept() : null}
                    {this.state.is_accept === false ? this.render_rejcet() : null}

                    {this.render_cs_confirm()}
                </div>
            </div>
        );
    }
}

export default IDCard;