import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {

  Card,
  Typography
} from '@material-ui/core';
import { to_date_min } from 'utility';
import Axios from 'axios';
import CONST_DATA from 'constant';

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
});

class GiaoViec extends Component {
  constructor(props) {
    super(props);
    this.state = {
      all_admin_data: null,
      all_admin_success: false
    };
  }

  componentDidMount() {
    this.get_all_admin();
  }

  get_all_admin = () => {
    Axios.get(CONST_DATA.get_all_admin)
      .then(response => {
        if (response.data.status === 'success') {
          this.setState({
            all_admin_data: response.data.data,
            all_admin_success: true
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  find_admin = id => {
    return this.state.all_admin_data.find(x => x.user_id == id).full_name;
  };

  card_info = (from, to, time, index) => {
    return (
      <Card
        key={index}
        style={{
          padding: 10,
          marginTop: 10,
          backgroundColor: 'rgb(187,222,251)'
        }}>
        <Typography>Từ : {this.find_admin(from)}</Typography>
        <Typography>Đến : {this.find_admin(to)}</Typography>
        <Typography>{to_date_min(time)}</Typography>
      </Card>
    );
  };

  render() {
    if (this.state.all_admin_success) {
      return (
        <Card style={{ padding: 20, marginTop: 10 }}>
          <Typography variant="h4" gutterBottom>
            Lịch sử giao việc
          </Typography>
          {this.props.admin_assign_log.map((item, index) =>
            this.card_info(item.from, item.to, item.date, index)
          )}
        </Card>
      );
    } else {
      return null;
    }
  }
}

export default withStyles(styles)(GiaoViec);
