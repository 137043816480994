import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ButtonGroup,
  Typography,
  TextField
} from '@material-ui/core';
import { portfolio_type_to_string } from 'utility';
import { toast } from 'react-toastify';
import Axios from 'axios';
import CONST_DATA from 'constant';
import KhopLenhDauTu from './KhopLenhDauTu';
import KhopLenhTietkiem from './KhopLenhTietkiem';
import KhopLenhCuuLong from './KhopLenhCuuLong';
import KhopLenhCD3M from './KhopLenhCD3M';
import KhopLenhCD12M from "./KhopLenhCD12M"

const columns = [
  { label: 'khớp lệnh', minWidth: 10 },
  { label: 'Trans_id', minWidth: 10 },
  { label: 'Ngày', minWidth: 10 },
  { label: 'Số tiền user đặt', minWidth: 10 },
  { label: 'Hũ đầu tư', minWidth: 10 }
];

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  },
  container: {
    maxHeight: '40vh'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  card: {
    backgroundColor: 'rgb(187,222,251)',
    marginTop: 10,
    padding: 10
  }
});

class KhopLenh extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      data_success: false,
      loai_lenh: null,
      data_chuyen_tien_sai: null,
      data_chuyen_tien_sai_success: false,
      response_data: null,
      payment_type: 0
    };
  }

  get_pending = loai_lenh => {
    // loai_lenh = 'cd_3_thang'
    // this.setState({
    //   loai_lenh: loai_lenh
    // });

    let body_req = {

      access_token: this.props.access_token,
      admin_id: this.props.user_id,
      user_id: this.props.data.user_id,
      loai_lenh: loai_lenh
    };
    // let body_req = {
    //   "access_token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjotMTU5MTY5MTExOCwidXNlcl9uYW1lIjoia2V0b2Fua2hvcGxlbmhAZmluaGF5LmNvbS52biIsInBhc3N3b3JkIjoia2V0b2Fua2hvcGxlbmhAZmluaGF5LmNvbS52biIsImZ1bGxfbmFtZSI6IktlIHRvYW4ga2hvcCBsZW5oIiwiY3JlYXRlZF9hdCI6MTU5MTY5MTExOCwicm9sZSI6MTAwfQ.MevbILzksNKWl8_3h-OQmrWa1ZuEOeAlyUo04cl6bXI",
    //   "admin_id": "-1591691118",
    //   "loai_lenh": "cd_3_thang",
    //   "user_id": 1153
    // }



    Axios.post(CONST_DATA.lay_list_pending, body_req)
      .then(response => {
        const messageResponse = response.data.message
        if (messageResponse === 'success' || messageResponse === 'Success') {
          this.setState({
            data_success: true,
            data: response.data.result,
            loai_lenh: loai_lenh
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  get_chuyen_tien_sai = () => {
    const body_req = {
      user_id: this.props.user_id,
      access_token: this.props.access_token,
      id_chuyen_tien_sai: this.props.data.id_chuyen_tien_sai
    };

    Axios.post(CONST_DATA.ketoan_get_by_id, body_req)
      .then(response => {
        if (response.data.status === 'success') {
          this.setState({
            data_chuyen_tien_sai_success: true,
            data_chuyen_tien_sai: response.data.data
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  componentDidMount() {
    let loai_lenh = this.props.data.ticket_detail[0].data;
    // alert(loai_lenh);

    if (loai_lenh === 'Cửu long') {
      loai_lenh = CONST_DATA.loai_cuu_long;
    }
    if (loai_lenh === 'Tiết kiệm') {
      loai_lenh = CONST_DATA.loai_tiet_kiem;
    }
    if (loai_lenh === 'Đầu tư') {
      loai_lenh = CONST_DATA.loai_dau_tu;
    }

    if (loai_lenh === 'Tích lũy 3 tháng') {
      loai_lenh = CONST_DATA.loai_cd_3_thang;
    }
    if (loai_lenh === 'CD 3 tháng') {
      loai_lenh = CONST_DATA.loai_cd_3_thang;
    }

    if (loai_lenh === 'Tích luỹ 12 tháng') {
      loai_lenh = CONST_DATA.loai_cd_12_thang;
    }



    this.setState({
      loai_lenh: loai_lenh
    });

    this.get_pending(loai_lenh);
    this.get_chuyen_tien_sai();
  }

  render_button = (loai_lenh, title) => {
    return (
      <Button
        variant="contained"
        color={this.state.loai_lenh == loai_lenh ? 'primary' : 'default'}
        onClick={() => this.get_pending(loai_lenh)}>
        {title}
      </Button>
    );
  };

  render_button_payment_type = (title, payment_type) => {
    if ([CONST_DATA.loai_cd_3_thang, CONST_DATA.loai_cd_12_thang].includes(this.state.loai_lenh)
      && [5, 6].includes(payment_type)) {
      return null
    }
    return (
      <Button
        variant="contained"
        onClick={() => this.setState({ payment_type: payment_type })}
        color={this.state.payment_type == payment_type ? 'primary' : 'default'}>
        {title}
      </Button>
    );
  };

  notify = (type, message) => {
    switch (type) {
      case 'success':
        toast.success(message);
        break;
      case 'error':
        toast.error(message);
        break;
      default:
        break;
    }

  }

  confirm_khop_lenh = row => {
    let transactionRef = this.getTransactionRef()
    if (transactionRef) {
      let actualPaymentType = this.getActualPaymentType()

      const body_req = {
        access_token: this.props.access_token,
        admin_id: this.props.user_id,
        loai_lenh: this.state.loai_lenh,
        trans_id: row.transaction_id,
        money: this.state.data_chuyen_tien_sai.so_tien,
        ticket_id: this.props.ticket_id,
        payment_type: actualPaymentType,
        transaction_ref: transactionRef,
        user_id: this.props.data.user_id
      };

      Axios.post(CONST_DATA.ke_toan_khop_lenh, body_req)
        .then(response => {
          this.setState({
            response_data: response.data.data.message
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  confirm_khop_lenh_cd3M = row => {
    let transactionRef = this.getTransactionRef()
    if (transactionRef) {
      let actualPaymentType = this.getActualPaymentType()
      let { id, partner_code, source } = row;
      if (!partner_code) partner_code = 'FINHAY';
      const body_req = {
        access_token: this.props.access_token,
        admin_id: this.props.user_id,
        loai_lenh: 'cd_3_thang',
        trans_id: id,
        money: this.state.data_chuyen_tien_sai.so_tien,
        ticket_id: this.props.ticket_id,
        payment_type: actualPaymentType,
        source: 'CS',
        partner_code,
        user_id: this.props.data.user_id,
        transaction_ref: transactionRef
      };

      Axios.post(CONST_DATA.ke_toan_khop_lenh, body_req)
        .then(response => {
          // this.setState({
          //  response_data: response.data.data.message
          // }); 
          const result = response.data;
          if (result.data.message === 'success') {
            this.setState({
              response_data: result.data.message
            });
            this.notify('success', 'Khớp lệnh thành công cho CD3M.')
            setTimeout(() => {
              window.location.reload()
            }, 1500);
          }
          else {
            this.notify('error', result.data.message)
          }
        })
        .catch(error => {
          this.notify('error', error.message)
          console.log(error);
        })
    }
  };

  confirm_khop_lenh_cd12M = row => {
    let transactionRef = this.getTransactionRef()
    if (transactionRef) {
      let actualPaymentType = this.getActualPaymentType()
      let { id, partner_code, source } = row;
      if (!partner_code) partner_code = 'FINHAY';
      const body_req = {
        access_token: this.props.access_token,
        admin_id: this.props.user_id,
        loai_lenh: 'cd_12_thang',
        trans_id: id,
        money: this.state.data_chuyen_tien_sai.so_tien,
        ticket_id: this.props.ticket_id,
        payment_type: actualPaymentType,
        source: 'CS',
        partner_code,
        user_id: this.props.data.user_id,
        transaction_ref: transactionRef
      };

      Axios.post(CONST_DATA.ke_toan_khop_lenh, body_req)
        .then(response => {
          // this.setState({
          //  response_data: response.data.data.message
          // }); 
          const result = response.data;
          if (result.data.message === 'success') {
            this.setState({
              response_data: result.data.message
            });
            this.notify('success', 'Khớp lệnh thành công cho CD12M.')
            setTimeout(() => {
              window.location.reload()
            }, 1500);
          }
          else {
            this.notify('error', result.data.message)
          }
        })
        .catch(error => {
          this.notify('error', error.message)
          console.log(error);
        });
    }
  }

  render_create_and_match = () => {
    if (this.state.loai_lenh == CONST_DATA.loai_dau_tu) {
      return (
        <KhopLenhDauTu
          id_chuyen_tien_sai={this.props.data.id_chuyen_tien_sai}
          data={this.props.data}
          access_token={this.props.access_token}
          user_id={this.props.user_id}
          ticket_id={this.props.ticket_id}
          payment_type={this.state.payment_type}
          transaction_ref={this.state.transaction_ref}
        />
      );
    }
    if (this.state.loai_lenh == CONST_DATA.loai_tiet_kiem) {
      return (
        <KhopLenhTietkiem
          id_chuyen_tien_sai={this.props.data.id_chuyen_tien_sai}
          data={this.props.data}
          access_token={this.props.access_token}
          user_id={this.props.user_id}
          ticket_id={this.props.ticket_id}
          payment_type={this.getActualPaymentType()}
          transaction_ref={this.state.transaction_ref}
        />
      );
    }
    // if (this.state.loai_lenh == CONST_DATA.loai_cuu_long) {
    //   return (
    //     <KhopLenhCuuLong
    //       id_chuyen_tien_sai={this.props.data.id_chuyen_tien_sai}
    //       data={this.props.data}
    //       access_token={this.props.access_token}
    //       user_id={this.props.user_id}
    //       ticket_id={this.props.ticket_id}
    //     />
    //   );
    // }
    if (this.state.loai_lenh == CONST_DATA.loai_cd_3_thang) {
      return (
        <KhopLenhCD3M
          id_chuyen_tien_sai={this.props.data.id_chuyen_tien_sai}
          data={this.props.data}
          access_token={this.props.access_token}
          user_id={this.props.data.user_id}
          admin_id={this.props.user_id}
          ticket_id={this.props.ticket_id}
          money={this.state.data_chuyen_tien_sai && this.state.data_chuyen_tien_sai.so_tien}
          payment_type={this.getActualPaymentType()}
          loai_lenh={'cd_3_thang'}
          transaction_ref={this.state.transaction_ref}
        />
      );
    }
    if (this.state.loai_lenh == CONST_DATA.loai_cd_12_thang) {
      return (
        <KhopLenhCD12M
          id_chuyen_tien_sai={this.props.data.id_chuyen_tien_sai}
          data={this.props.data}
          access_token={this.props.access_token}
          user_id={this.props.data.user_id}
          admin_id={this.props.user_id}
          ticket_id={this.props.ticket_id}
          money={this.state.data_chuyen_tien_sai && this.state.data_chuyen_tien_sai.so_tien}
          payment_type={this.getActualPaymentType()}
          loai_lenh={'cd_12_thang'}
          transaction_ref={this.state.transaction_ref}
        />
      )
    }
  };

  render_table_data_CD3M = (data) => {
    return <TableBody>
      {data.map((row, index) => {
        return (
          <TableRow hover tabIndex={-1} key={index} >
            <TableCell>
              <Button
                variant="contained"
                onClick={() => this.confirm_khop_lenh_cd3M(row)}>
                Confirm{' '}
                {this.state.data_chuyen_tien_sai != null
                  ? this.state.data_chuyen_tien_sai.so_tien
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  : null}
              </Button>
            </TableCell>
            <TableCell>{row.id}</TableCell>
            <TableCell>{row.created_at}</TableCell>
            <TableCell>
              {row.place_amount}
            </TableCell>
            <TableCell>
              {row.portfolio_type == null
                ? null
                : portfolio_type_to_string(row.portfolio_type)}
            </TableCell>
          </TableRow>
        )
      })
      }
    </TableBody>
  }


  render_table_data_CD12M = (data) => {
    console.log(data)
    return <TableBody>
      {data.map((row, index) => {
        return (
          <TableRow hover tabIndex={-1} key={index} >
            <TableCell>
              <Button
                variant="contained"
                onClick={() => this.confirm_khop_lenh_cd12M(row)}>
                Xác nhận{' '}
                {this.state.data_chuyen_tien_sai != null
                  ? this.state.data_chuyen_tien_sai.so_tien
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  : null}
              </Button>
            </TableCell>
            <TableCell>{row.id}</TableCell>
            <TableCell>{row.created_at}</TableCell>
            <TableCell>
              {row.place_amount}
            </TableCell>
            <TableCell>
              {row.portfolio_type == null
                ? null
                : portfolio_type_to_string(row.portfolio_type)}
            </TableCell>
          </TableRow>
        )
      })
      }
    </TableBody>
  }

  render_table_data = (data) => {
    // alert('table')
    return <TableBody>
      {data.map((row, index) => (
        <TableRow hover tabIndex={-1} key={index}>
          <TableCell>
            <Button
              variant="contained"
              onClick={() => this.confirm_khop_lenh(row)}>
              Confirm{' '}
              {this.state.data_chuyen_tien_sai != null
                ? this.state.data_chuyen_tien_sai.so_tien
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                : null}
            </Button>
          </TableCell>
          <TableCell>{row.transaction_id}</TableCell>
          <TableCell>{row.created_at}</TableCell>
          <TableCell>
            {row.money
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </TableCell>
          <TableCell>
            {row.portfolio_type == null
              ? null
              : portfolio_type_to_string(row.portfolio_type)}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  }

  render() {
    const { classes } = this.props;
    let table;
    if (this.state.data_success && this.state.loai_lenh == CONST_DATA.loai_cd_3_thang) {
      table = this.render_table_data_CD3M(this.state.data)
    }
    else if (this.state.data_success && this.state.loai_lenh == CONST_DATA.loai_cd_12_thang) {
      table = this.render_table_data_CD12M(this.state.data)
    }
    else if (this.state.data_success) {
      table = this.render_table_data(this.state.data)
    }
    return (
      <div>
        <Card className={classes.card}>
          <Typography variant="h4">Lựa chọn loại khớp lệnh</Typography>
          <ButtonGroup
            style={{ margin: 10 }}
            variant="text"
            color="primary"
            aria-label="text primary button group">
            {this.render_button(CONST_DATA.loai_dau_tu, 'Đầu tư')}
            {this.render_button(CONST_DATA.loai_tiet_kiem, 'Tiết kiệm')}
            {/* {this.render_button(CONST_DATA.loai_cuu_long, 'Cửu long')} */}
            {this.render_button(CONST_DATA.loai_cd_3_thang, 'CD 3 tháng')}
            {this.render_button(CONST_DATA.loai_cd_12_thang, 'CD 12 tháng')}
          </ButtonGroup>
        </Card>

        <Card className={classes.card}>
          <Typography variant="h4">Phương thức nạp</Typography>
          <ButtonGroup
            style={{ margin: 10 }}
            variant="text"
            color="primary"
            aria-label="text primary button group">
            {this.render_button_payment_type('Ngân hàng', 0)}
            {this.render_button_payment_type('Megapay', 6)}
            {this.render_button_payment_type('Airpay', 5)}
            {this.render_button_payment_type('Onepay', 15)}
          </ButtonGroup>
          {this.renderTransactionRefInput()}

        </Card>

        {this.render_create_and_match()}

        <Card className={classes.card} style={{ color: 'white' }}>
          <Typography variant="h4">Danh sách lệnh pending</Typography>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {/* {this.state.data_success && (this.state.loai_lenh === CONST_DATA.loai_cd_3_thang ? this.render_table_data_CD3M(this.state.data) : this.render_table_data(this.state.data))} */}
              {table}
            </Table>
          </TableContainer>
        </Card>
        {this.state.response_data == null ? null : (
          <Card className={classes.card}>
            <Typography variant="h4">
              Trạng thái: {this.state.response_data}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.location.reload()}>
              Reload
            </Button>
          </Card>
        )}
      </div>
    );
  }

  //Rendering helpers
  renderTransactionRefInput() {
    if (this.state.payment_type != 15) {
      return null
    }
    return <TextField
      multiline={false}
      fullWidth
      name="transaction_ref"
      type="text"
      variant="outlined"
      placeholder="Mã giao dịch format OnePAY-FTxxxxx"
      value={this.state.transaction_ref}
      onChange={event => this.setState({ transaction_ref: event.target.value })}
    />
  }

  //Helper methods
  getActualPaymentType() {
    let paymentType = this.state.payment_type
    switch (this.state.loai_lenh) {
      case CONST_DATA.loai_tiet_kiem:
        if (paymentType == 15) {
          paymentType = 18
        }
        break
      case CONST_DATA.loai_cd_3_thang:
      case CONST_DATA.loai_cd_12_thang:
        if (paymentType == 15) {
          paymentType = 2
        }
        break
    }
    return paymentType
  }

  getTransactionRef() {
    let returnTransactionRef = this.state.transaction_ref
    if (this.state.payment_type != 15) {
      returnTransactionRef = this.props.ticket_id
    }
    else if (!returnTransactionRef) {
      this.notify('error', 'Cần điền mã giao dịch cho OnePAY')
    }
    else {
      if (!returnTransactionRef.match(/^OnePAY-FT\w{14}$/)) {
        this.notify('error', 'Mã giao dịch OnePAY cần có định dạng OnePAY-FTxxxxxx')
        returnTransactionRef = null
      }
    }
    return returnTransactionRef
  }
}

export default withStyles(styles)(KhopLenh);
