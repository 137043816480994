import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  Typography,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Checkbox,
  Button
} from '@material-ui/core';

import { get_time_second } from 'utility';
import CONST_DATA from 'constant';
import Axios from 'axios';

class QuyTrinh extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quy_trinh: {
        key_1_1: {
          title: '1.1 Sai mã đơn',
          check: false,
          cs_id: null,
          time: null
        },
        key_1_2: {
          title: '1.2 Trùng mã đơn',
          check: false,
          cs_id: null,
          time: null
        },
        key_2_1: {
          title: '2.1 Tìm tiền về, xác định ngày chuyển tiền của user',
          check: false,
          cs_id: null,
          time: null
        },
        key_3_1: {
          title:
            '3.1 Kiểm tra trên hệ thống (mb với tiết kiệm, CL và DBM với đầu tư) xem giao dịch đó đã khớp chưa',
          check: false,
          cs_id: null,
          time: null
        },
        key_4_1: {
          title: '4.1 Nếu chưa khớp --> Check xem có nối được không',
          check: false,
          cs_id: null,
          time: null
        },
        key_4_2: {
          title:
            '4.2 Nếu đã khớp --> bỏ qua, viết comment lý do bỏ qua cho user',
          check: false,
          cs_id: null,
          time: null
        },
        key_5_1: {
          title: '5.1 có giao dịch tiền để nối',
          check: false,
          cs_id: null,
          time: null
        },
        key_5_2: {
          title:
            '5.2 Nếu chưa có giao dịch tiền để nối --> assign sang kế toán đề nghị thêm giao dịch tiền về (viết chi tiết trong phần ghi chú, gồm có: Mã khớp lệnh + Số bút toán đã được khớp lệnh Số bút toán chưa khớp lệnh)',
          check: false,
          cs_id: null,
          time: null
        },
        key_6_1: {
          title:
            '6.1 Kế toán up giao dịch CS đề nghị lên phần Bổ sung giao dịch trùng (note rõ ghi chú nếu cần), assign lại cho CS',
          check: false,
          cs_id: null,
          time: null
        },
        key_7_1: {
          title: '7.1 Tìm tiền, nối Assign sang kế toán khớp lệnh',
          check: false,
          cs_id: null,
          time: null
        },
        key_8_1: {
          title:
            '8.1 Đảm bảo tiền về (check sao kê ngân hàng) Đảm bảo giao dịch chưa được khớp (check hệ thống) + check phần note của kế toán up lệnh Đọc hội thoại cmt của user với Finhay đã có',
          check: false,
          cs_id: null,
          time: null
        },
        key_9_1: {
          title:
            '9.1  TH1. Có tiền về, đảm bảo chưa khớp, đã đọc note về hũ, lưu ý --> ok --> ấn Khớp lệnh và gửi trạng thái thành công',
          check: false,
          cs_id: null,
          time: null
        },
        key_9_2: {
          title:
            '9.2 TH2. Check thấy sai .viết diễn giải chi tiết lỗi sai, đưa ra quyết định với đơn: Bỏ qua (nếu đã khớp tự động)Assign lại CS, nếu lệnh nối sai',
          check: false,
          cs_id: null,
          time: null
        }
      }
    };
  }

  componentDidMount() {
    if (this.props.quy_trinh != null) {
      this.setState({
        quy_trinh: this.props.quy_trinh
      });
    }
  }

  send_quy_trinh = () => {
    const body_req = {
      access_token: this.props.access_token,
      user_id: this.props.admin_id,
      quy_trinh: this.state.quy_trinh,
      ticket_id: this.props.ticket_id
    };

    Axios.post(CONST_DATA.edit_quy_trinh, body_req)
      .then(response => {
      })
      .catch(error => {
        console.log(error);
      });
  };

  check_box_key = (state_key, state_name) => {
    return (
      <Card style={{ margin: 3, padding: 3 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={state_key.check}
              onChange={e => this.handleChange(e, state_key)}
              name={state_name}
            />
          }
          label={state_key.title}
        />
      </Card>
    );
  };

  handleChange = (event, old_obj) => {
    let new_obj = {
      ...old_obj,
      check: event.target.checked,
      cs_id: this.props.admin_id,
      time: get_time_second()
    };

    this.setState({
      quy_trinh: {
        ...this.state.quy_trinh,
        [event.target.name]: new_obj
      }
    });
  };

  render() {
    return (
      <div>
        <Typography variant="h4">Quy trình</Typography>
        <FormControl component="fieldset">
          <FormGroup>
            <FormLabel component="legend">Bước 1</FormLabel>
            {this.check_box_key(this.state.quy_trinh.key_1_1, 'key_1_1')}
            {this.check_box_key(this.state.quy_trinh.key_1_2, 'key_1_2')}
            {this.state.quy_trinh.key_1_2.check ? (
              <div>
                <FormLabel component="legend">Bước 2</FormLabel>
                {this.check_box_key(this.state.quy_trinh.key_2_1, 'key_2_1')}

                <FormLabel component="legend">Bước 3</FormLabel>
                {this.check_box_key(this.state.quy_trinh.key_3_1, 'key_3_1')}

                {this.state.quy_trinh.key_3_1.check ? (
                  <div>
                    <FormLabel component="legend">Bước 4</FormLabel>
                    {this.check_box_key(
                      this.state.quy_trinh.key_4_1,
                      'key_4_1'
                    )}
                    {this.check_box_key(
                      this.state.quy_trinh.key_4_2,
                      'key_4_2'
                    )}

                    {this.state.quy_trinh.key_4_1.check ? (
                      <div>
                        <FormLabel component="legend">Bước 5</FormLabel>
                        {this.check_box_key(
                          this.state.quy_trinh.key_5_1,
                          'key_5_1'
                        )}
                        {this.check_box_key(
                          this.state.quy_trinh.key_5_2,
                          'key_5_2'
                        )}

                        {this.state.quy_trinh.key_5_2.check &&
                          this.props.role_admin == CONST_DATA.ROLE_KE_TOAN ? (
                          <div>
                            <FormLabel component="legend">Bước 6</FormLabel>
                            {this.check_box_key(
                              this.state.quy_trinh.key_6_1,
                              'key_6_1'
                            )}
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
            ) : null}

            {(this.state.quy_trinh.key_1_1.check ||
              this.state.quy_trinh.key_5_1.check ||
              this.state.quy_trinh.key_6_1.check) &&
              !this.state.quy_trinh.key_4_2.check ? (
              <div>
                <FormLabel component="legend">Bước 7</FormLabel>
                {this.check_box_key(this.state.quy_trinh.key_7_1, 'key_7_1')}
              </div>
            ) : null}

            {this.props.role_admin == CONST_DATA.ROLE_KE_TOAN_KHOP_LENH ||
              this.props.role_admin == CONST_DATA.ROLE_ADMIN ? (
              <div>
                <FormLabel component="legend">Bước 8</FormLabel>
                {this.check_box_key(this.state.quy_trinh.key_8_1, 'key_8_1')}

                {this.state.quy_trinh.key_8_1.check ? (
                  <div>
                    <FormLabel component="legend">Bước 9</FormLabel>
                    {this.check_box_key(
                      this.state.quy_trinh.key_9_1,
                      'key_9_1'
                    )}
                    {this.check_box_key(
                      this.state.quy_trinh.key_9_2,
                      'key_9_2'
                    )}
                  </div>
                ) : null}
              </div>
            ) : null}
          </FormGroup>
        </FormControl>
        <Button
          variant="contained"
          size="small"
          onClick={() => this.send_quy_trinh()}>
          Lưu lại
        </Button>
      </div>
    );
  }
}

export default QuyTrinh;
