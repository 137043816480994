import React, { Component } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  TextField,
  CircularProgress
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import {
  to_date,
  to_date_min,
  status_detail,
  to_date_search,
  convert_date_to_search
} from 'utility';
import CONST_DATA from 'constant';
import SearchText from './SearchText';
import BasicPagination from 'components/Pagination/Pagination';

const columns = [
  { label: 'STT', minWidth: 10 },
  { label: 'Ngày tạo', minWidth: 10 },
  { label: 'User id', minWidth: 10 },
  { label: 'SDT', minWidth: 10 },
  { label: 'Email', minWidth: 10 },
  { label: 'Hạng', minWidth: 10 },
  { label: 'Mã yêu cầu', minWidth: 10 },
  { label: 'Vấn đề ', minWidth: 10 },
  { label: 'Trạng thái', minWidth: 10 },
  { label: 'Admin', minWidth: 10 }
  // { label: 'Ghi chú', minWidth: 10 }
];

const styles = theme => ({
  root: {},
  container: {
    maxHeight: '70vh'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 170
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
});

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      type_of_ticket: 'ALL',
      type_of_status: 0,
      type_of_rank: 'ALL',
      seach_text: '',
      search_date: null
    };
  }

  ren_admin = data => {
    if (data.admin_assign != null) {
      return <TableCell>{data.admin_assign.full_name}</TableCell>;
    } else {
      return <TableCell></TableCell>;
    }
  };

  check_condition = data => {
    let result_type = false;

    if (this.state.type_of_ticket == 'ALL') {
      result_type = true;
    }
    if (data.ticket_type == this.state.type_of_ticket) {
      result_type = true;
    }

    let result_rank = false;
    if (this.state.type_of_rank == 'ALL') {
      result_rank = true;
    }
    if (data.rank_title == this.state.type_of_rank) {
      result_rank = true;
    }

    let result_status = false;

    if (this.state.type_of_status == 0) {
      result_status = true;
    }
    if (data.status_id == this.state.type_of_status) {
      result_status = true;
    }

    let result_text = false;
    if (data.user_id.toString().includes(this.state.seach_text)) {
      result_text = true;
    }
    if (data.ticket_id.includes(this.state.seach_text)) {
      result_text = true;
    }

    if (data.user_profile != null) {
      if (data.user_profile.phone_number != null) {
        if (data.user_profile.phone_number.includes(this.state.seach_text)) {
          result_text = true;
        }
      }
      if (data.user_profile.email != null) {
        if (data.user_profile.email.includes(this.state.seach_text)) {
          result_text = true;
        }
      }
    }

    if (data.note != null) {
      if (data.note.toString().includes(this.state.seach_text)) {
        result_text = true;
      }
    }

    let result_date = false;

    if (this.state.search_date != null) {
      let date_tim_kiem = convert_date_to_search(this.state.search_date);
      let date_item = to_date_search(data.created_at);

      result_date = date_tim_kiem == date_item;
    }
    if (this.state.search_date == null || this.state.search_date == '') {
      result_date = true;
    }

    return result_type && result_status && result_rank && result_text && result_date;
  };

  render() {
    const { classes } = this.props;

    const data = this.props.data.map((el) => {
      return el.user_id;
    });
    return (
      <div>
        {this.props.loading ? <CircularProgress></CircularProgress> : <>
          <Box display="flex" alignItems="center" justifyContent="center">
            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                className={classes.textField}
                value={this.state.search_date}
                onChange={event => {
                  this.setState({ search_date: event.target.value });
                }}
                type="date"
              />
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel style={{ marginTop: "-6px" }} id="demo-simple-select-label">
                Lọc theo vấn đề
            </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={this.state.type_of_ticket}
                onChange={event =>
                  this.setState({ type_of_ticket: event.target.value })
                }>
                {CONST_DATA.type_of_ticket.map(item => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel style={{ marginTop: "-6px" }} id="demo-simple-select-label">
                Lọc theo trạng thái
            </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={this.state.type_of_status}
                onChange={event => {
                  this.setState({ type_of_status: event.target.value })
                  this.props.handOnChangeStatus(event.target.value)
                }


                }>
                {CONST_DATA.type_of_status.map(item => (
                  <MenuItem value={item.status_id}>{item.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <SearchText
              onClick={seach_text => this.setState({ seach_text: seach_text })}
            />
          </Box>

          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map(column => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.data.map((row, index) => {
                    if (this.check_condition(row)) {
                      return (
                        <TableRow hover tabIndex={-1}>
                          <TableCell>
                            <Link
                              to={CONST_DATA.router_detail + '/' + row.ticket_id}
                              target="_blank">
                              <Button
                                variant="outlined"
                                size="small"
                                color="primary">
                                {index + 1}
                              </Button>
                            </Link>
                          </TableCell>
                          <TableCell>{to_date_min(row.created_at)}</TableCell>

                          <TableCell>
                            {this.props.role_admin == CONST_DATA.ROLE_TU_VAN
                              ? ''
                              : row.user_id}
                          </TableCell>
                          <TableCell>
                            {this.props.role_admin == CONST_DATA.ROLE_TU_VAN ||
                              row.user_profile == null
                              ? ''
                              : row.user_profile.user_profile.phone_number}
                          </TableCell>
                          <TableCell>
                            {row.user_profile == null ||
                              this.props.role_admin == CONST_DATA.ROLE_TU_VAN
                              ? ''
                              : row.user_profile.user_profile.email}
                          </TableCell>
                          <TableCell>{row.rank_title}</TableCell>
                          <TableCell>{row.ticket_id}</TableCell>
                          <TableCell>{row.ticket_type}</TableCell>
                          <TableCell>{status_detail(row.status_id)}</TableCell>

                          {this.ren_admin(row)}
                          {/* <TableCell>
                          {row.note == null ? null : row.note.toString()}
                        </TableCell> */}
                        </TableRow>
                      );
                    } else {
                      return null;
                    }
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {this.props.typeGet == 'can_xu_ly' ? '' :
            <BasicPagination handleOnChangePage={this.props.handleOnChangePage} totalPage={this.props.totalPage} page={this.props.page} ></BasicPagination>
            }
          </Paper>
        </>}
      </div>
    );
  }
}

export default withStyles(styles)(User);