import React, { Component } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  TextField,
  IconButton,
  Button,
  Typography,
  CircularProgress
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const styles = theme => ({
  root: {},
  container: {
    maxHeight: '70vh'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 170
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
});

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seach_text: ''
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <TextField
          placeholder="User_id, SDT, email, Mã yêu câu"
          id="outlined-basic"
          label="Từ khóa"
          variant="outlined"
          value={this.state.seach_text}
          onKeyDown={e => {
            if (e.key == 'Enter') {
              this.props.onClick(this.state.seach_text);
            }
          }}
          onChange={event => this.setState({ seach_text: event.target.value })}
        />

        {this.props.loading ? (
          <CircularProgress />
        ) : (
          <Button
            style={{ margin: 10 }}
            variant="contained"
            color="primary"
            onClick={() => this.props.onClick(this.state.seach_text)}>
            <Typography style={{ color: 'white' }}>Tìm kiếm</Typography>
            <SearchIcon />
          </Button>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(User);