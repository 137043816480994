import React, { Component } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Button,
  Card,
  CircularProgress
} from '@material-ui/core';
import CONST_DATA from 'constant';
import Axios from 'axios';
import { get_time_second } from 'utility';

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    margin: theme.spacing(1)
  },
  textField: {
    margin: theme.spacing(1)
  }
});

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: null,
      full_name: null,
      user_name: null,
      data: null,
      data_success: false
    };
  }

  check_login = () => {
    let login_data = localStorage.getItem(CONST_DATA.localStorage_login);

    if (login_data == null) {
      this.props.history.push(CONST_DATA.router_sign_in);
    } else {
      login_data = JSON.parse(login_data);
      this.setState({
        access_token: login_data
      });
    }
  };

  async componentDidMount() {
    await this.check_login();
    await this.get_all_admin();
  }

  get_all_admin = () => {
    Axios.get(CONST_DATA.get_all_admin)
      .then(response => {
        if (response.data.status == 'success') {
          this.setState({
            data: response.data.data,
            data_success: true
          });
        }
      })
      .catch(error => {
      });
  };

  render_list_admin = (item, classes) => {
    return (
      <Card className={classes.root}>
        <Typography>full_name: {item.full_name}</Typography>
        <Typography>user_name: {item.user_name}</Typography>
        <Typography>user_id: {item.user_id}</Typography>
      </Card>
    );
  };

  send_data_add = () => {
    const body_req = {
      full_name: this.state.full_name,
      user_name: this.state.user_name,
      access_token: this.state.access_token
    };

    Axios.post(CONST_DATA.creat_user_admin, body_req)
      .then(response => {
        if (response.data.status == 'success') {
          window.location.reload();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item lg={6} md={6} xl={12} xs={12}>
            {this.state.data_success ? (
              this.state.data.map(item => this.render_list_admin(item, classes))
            ) : (
              <CircularProgress />
            )}

            <Card className={classes.root}>
              <Typography>Add admin</Typography>
              <TextField
                className={classes.textField}
                fullWidth
                label="full_name"
                variant="outlined"
                value={this.state.full_name}
                onChange={event =>
                  this.setState({ full_name: event.target.value })
                }
              />
              <TextField
                className={classes.textField}
                fullWidth
                label="user_name"
                variant="outlined"
                value={this.state.user_name}
                onChange={event =>
                  this.setState({ user_name: event.target.value })
                }
              />
              <Button
                style={{ margin: 10 }}
                color="primary"
                variant="contained"
                onClick={() => this.send_data_add()}>
                Add admin
              </Button>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(User);
