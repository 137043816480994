import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from '@material-ui/core';

import CONST_DATA from 'constant';
import Axios from 'axios';
import SearchText from './SearchText';

const columns = [
  { label: 'Lựa chọn', minWidth: 10 },
  { label: 'Ngày', minWidth: 10 },
  { label: 'Số tiền', minWidth: 10 },
  { label: 'Mã rút tiền', minWidth: 10 },
  { label: 'Diễn giải', minWidth: 10 },
  { label: 'ID', minWidth: 10 },
  { label: 'Mã case', minWidth: 10 },
  { label: 'CS', minWidth: 10 },
  { label: 'Ghi chú', minWidth: 10 }
];

const styles = theme => ({
  root: {},
  container: {
    maxHeight: '70vh'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 170
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
});

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: this.props.access_token,
      user_id: this.props.user_id,
      data: this.props.data,
      data_success: false,
      open_delete: false,
      id_delete: null,
      data_delete: null,
      note_change: null
    };
  }

  get_data = () => {
    const body_req = {
      user_id: this.state.user_id,
      access_token: this.state.access_token,
      search_key: ''
    };

    Axios.post(CONST_DATA.ketoan_hoan_tra_search_item, body_req)
      .then(response => {
        if (response.data.status === 'success') {
          this.setState({
            data_success: true,
            data: response.data.data
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  search_data = search_key => {
    const body_req = {
      user_id: this.state.user_id,
      access_token: this.state.access_token,
      search_key: search_key
    };

    Axios.post(CONST_DATA.ketoan_hoan_tra_search_item, body_req)
      .then(response => {
        if (response.data.status === 'success') {
          this.setState({
            data_success: true,
            data: response.data.data
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.get_data();
  }

  cs_confirm = (id_hoan_tra, ticket_id) => {
    const body_req = {
      user_id: this.state.user_id,
      access_token: this.state.access_token,
      id_hoan_tra: id_hoan_tra,
      ticket_id: ticket_id
    };

    Axios.post(CONST_DATA.ketoan_hoan_tra_cs_match, body_req)
      .then(response => {
        if (response.data.status == 'success') {
          this.props.refesh_page();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <SearchText onClick={search_key => this.search_data(search_key)} />
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.data_success
                  ? this.state.data.map((row, index) => (
                    <TableRow hover tabIndex={-1}>
                      <TableCell>
                        <Button
                          onClick={() =>
                            this.cs_confirm(row.id, this.props.ticket_id)
                          }
                          variant="outlined"
                          size="small"
                          color="primary">
                          Select
                          </Button>
                      </TableCell>
                      <TableCell>{row.date}</TableCell>
                      <TableCell>
                        {row.so_tien
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </TableCell>

                      <TableCell>{row.ma_rut_tien}</TableCell>
                      <TableCell>{row.dien_giai}</TableCell>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.ma_case}</TableCell>
                      <TableCell>{row.cs_match ? 'yes' : 'no'}</TableCell>
                      <TableCell>
                        {row.note == null ? null : row.note.toString()}
                      </TableCell>
                    </TableRow>
                  ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(User);
