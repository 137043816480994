import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/styles';
import { Avatar, Typography, CircularProgress } from '@material-ui/core';
import CONST_DATA from 'constant';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  }
});

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data_success: false
    };
  }

  check_login = () => {
    const login_data = localStorage.getItem(CONST_DATA.localStorage_login);
    if (login_data != null) {
      this.setState({
        thong_tin_hien_tai: JSON.parse(login_data),
        data_success: true
      });
    }
  };

  componentDidMount() {
    this.check_login();
  }

  render() {
    const { classes } = this.props;
    if (this.state.data_success) {
      return (
        <div className={classes.root}>
          <Typography className={classes.name}>
            Tên: {this.state.thong_tin_hien_tai.full_name}
          </Typography>
          <Typography className={classes.name}>
            {this.state.thong_tin_hien_tai.user_name}
          </Typography>
        </div>
      );
    } else {
      return <CircularProgress />;
    }
  }
}

export default withStyles(styles)(Profile);
