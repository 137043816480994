import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Card
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import CONST_DATA from 'constant';
import Axios from 'axios';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    margin: theme.spacing(1)
  },
  textField: {
    margin: theme.spacing(1)
  }
});

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: null,
      user_id: null,
      full_name: null,
      old_password: '',
      password1: '',
      password2: '',
      showOldPass: false,
      showNewPass: false,
      showConfirmPass: false
    };
  }

  handleClickShowPassword = (type) => {
    switch (type) {
      case 'old':
        this.setState((state) => ({
          showOldPass: !state.showOldPass
        }))
        break;
      case 'new':
        this.setState((state) => ({
          showNewPass: !state.showNewPass
        }))
        break;
      case 'confirmNew':
        this.setState((state) => ({
          showConfirmPass: !state.showConfirmPass
        }))
        break;
      default:
        break;
    }

  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  check_login = () => {
    let login_data = localStorage.getItem(CONST_DATA.localStorage_login);

    if (login_data == null) {
      this.props.history.push(CONST_DATA.router_sign_in);
    } else {
      login_data = JSON.parse(login_data);

      this.setState({
        access_token: login_data.access_token,
        user_id: login_data.user_id
      });
    }
  };

  async componentDidMount() {
    await this.check_login();
  }

  edit_data = (url, body_req) => {
    Axios.post(url, body_req)
      .then(response => {
        if (response.data.status === 'success') {
          window.location.reload();
        } else {
          alert(response.data.data);
        }
      })

      .catch(error => {
      });
  };

  render_send_pass = () => {
    if (
      this.state.password1 == this.state.password2 &&
      this.state.password1 != null &&
      this.state.password2 != null
    ) {
      const body_req = {
        user_id: this.state.user_id,
        password: this.state.old_password,
        password_new: this.state.password2,
        access_token: this.state.access_token
      };

      return (
        <Button
          style={{ margin: 10 }}
          color="primary"
          variant="contained"
          onClick={() =>
            this.edit_data(CONST_DATA.edit_user_admin_password, body_req)
          }>
          Đổi password
        </Button>
      );
    }
  };

  send_full_name = () => {
    const body_req = {
      user_id: this.state.user_id,
      full_name: this.state.full_name,
      access_token: this.state.access_token
    };
    this.edit_data(CONST_DATA.edit_user_admin_full_name, body_req);
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item lg={6} md={6} xl={12} xs={12}>
            <Card className={classes.root}>
              <TextField
                multiline={true}
                className={classes.textField}
                fullWidth
                label="Tên hiển thị"
                variant="outlined"
                value={this.state.full_name}
                onChange={event =>
                  this.setState({ full_name: event.target.value })
                }
              />
              <Button
                style={{ margin: 10 }}
                color="primary"
                variant="contained"
                onClick={() => this.send_full_name()}>
                Sửa tên
              </Button>
            </Card>

            <Card className={classes.root}>
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                className={classes.textField}
                fullWidth
                type={this.state.showOldPass ? 'text' : 'password'}
                variant="outlined"
                value={this.state.old_password}
                onChange={event =>
                  this.setState({ old_password: event.target.value })
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => this.handleClickShowPassword('old')}
                      onMouseDown={(e) => this.handleMouseDownPassword(e)}
                      edge="end"
                    >
                      {this.state.showOldPass ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <InputLabel htmlFor="outlined-adornment-password">New password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                className={classes.textField}
                fullWidth
                type={this.state.showNewPass ? 'text' : 'password'}
                variant="outlined"
                value={this.state.password1}
                onChange={event =>
                  this.setState({ password1: event.target.value })
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => this.handleClickShowPassword('new')}
                      onMouseDown={(e) => this.handleMouseDownPassword(e)}
                      edge="end"
                    >
                      {this.state.showNewPass ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <InputLabel htmlFor="outlined-adornment-password">New password confirm</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                className={classes.textField}
                fullWidth
                type={this.state.showConfirmPass ? 'text' : 'password'}
                variant="outlined"
                value={this.state.password2}
                onChange={event =>
                  this.setState({ password2: event.target.value })
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => this.handleClickShowPassword('confirmNew')}
                      onMouseDown={(e) => this.handleMouseDownPassword(e)}
                      edge="end"
                    >
                      {this.state.showConfirmPass ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />

              {this.render_send_pass()}
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(User);
