import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
} from '@material-ui/core';
import CONST_DATA from 'constant';
import TaoHoDon from './TaoHoDon';

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    margin: theme.spacing(1)
  },
  textField: {
    margin: theme.spacing(1)
  }
});

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  check_login = () => {
    let login_data = localStorage.getItem(CONST_DATA.localStorage_login);

    if (login_data == null) {
      this.props.history.push(CONST_DATA.router_sign_in);
    } else {
      login_data = JSON.parse(login_data);
      this.setState({
        access_token: login_data.access_token,
        admin_id: login_data.user_id
      });
    }
  };

  componentDidMount() {
    this.check_login();
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item lg={6} md={6} xl={12} xs={12}>
            <TaoHoDon
              access_token={this.state.access_token}
              admin_id={this.state.admin_id}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(User);
