import React, { Component } from 'react';
import {
    Button,
    FormControl,
    FormGroup,
    Checkbox,
    FormControlLabel,
    Card,
    Box,
    Typography,
    TextField,
    Input,
    CircularProgress
} from '@material-ui/core';

import RotateRightIcon from '@material-ui/icons/RotateRight';
import { list_check_fail } from './data';

class Selfie extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ly_do_reject: '',
        };
    }

    componentDidMount() {
        let data_cmt = this.props.data_cmt;

        try {
            this.setState({
                id_name: data_cmt.true_id.ocr_id.data_fields.name.value
            });
        } catch (error) {
            try {
                this.setState({
                    id_name: data_cmt.true_id.final.kyc_result.front.name.value
                });
            } catch (error) {
                try {
                    this.setState({
                        id_name: data_cmt.user_info.name
                    });
                } catch (error) { }
            }
        }

        try {
            this.setState({
                id_number: data_cmt.true_id.ocr_id.data_fields.id_number.value
            });
        } catch (error) {
            try {
                this.setState({
                    id_number: data_cmt.true_id.final.kyc_result.front.id_number.value
                });
            } catch (error) {
                try {
                    this.setState({
                        id_number: data_cmt.user_info.id_number
                    });
                } catch (error) { }
            }
        }

        try {
            this.setState({
                id_dob: data_cmt.true_id.ocr_id.data_fields.dob.value
            });
        } catch (error) {
            try {
                this.setState({
                    id_dob: data_cmt.true_id.final.kyc_result.front.dob.value
                });
            } catch (error) {
                try {
                    this.setState({
                        id_dob: data_cmt.user_info.dob
                    });
                } catch (error) { }
            }
        }

        try {
            this.setState({
                id_address: data_cmt.true_id.ocr_id.data_fields.id_address.value
            });
        } catch (error) {
            try {
                this.setState({
                    id_address: data_cmt.true_id.final.kyc_result.front.id_address.value
                });
            } catch (error) {
                try {
                    this.setState({
                        id_address: data_cmt.user_info.address
                    });
                } catch (error) { }
            }
        }

        try {
            this.setState({
                id_issue_date: data_cmt.true_id.ocr_id.data_fields.given_date.value
            });
        } catch (error) {
            try {
                this.setState({
                    id_issue_date: data_cmt.true_id.final.kyc_result.back.given_date.value
                });
            } catch (error) {
                try {
                    this.setState({
                        id_issue_date: data_cmt.user_info.id_issue_date
                    });
                } catch (error) { }
            }
        }

        try {
            this.setState({
                id_issue_address: data_cmt.true_id.ocr_id.data_fields.given_place.value
            });
        } catch (error) {
            try {
                this.setState({
                    id_issue_address:
                        data_cmt.true_id.final.kyc_result.back.given_place.value
                });
            } catch (error) {
                try {
                    this.setState({
                        id_issue_address: data_cmt.user_info.id_issue_address
                    });
                } catch (error) { }
            }
        }
    }

    render_img = src_link => {
        let max_h = 400;
        return (
            <div style={{ maxHeight: max_h, maxWidth: max_h }}>
                <img
                    src={src_link}
                    style={{
                        transform: 'rotate(' + this.state.deg_rotate + 'deg)',
                        maxHeight: max_h,
                        maxWidth: max_h
                    }}
                    alt=""
                />
            </div>
        );
    };

    render_img_selfie = src_link => {
        let max_h = 150;
        return (
            <div style={{ height: max_h, width: max_h }}>
                <img src={src_link} style={{ maxHeight: max_h, maxWidth: max_h }} alt="" />
            </div>
        );
    };

    onChangeList_fail = event => {

        let pre_list_check = this.state.list_check_fail;
        let index_of_item = this.state.list_check_fail.findIndex(
            a => a.title === event.target.name
        );
        pre_list_check[index_of_item].check = event.target.checked;
        this.setState({ list_check_fail: pre_list_check });
    };

    card_text = (label, value, onChange) => {
        return (
            <div
                style={{
                    margin: 5,
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                <Typography style={{ flex: 1 }}>{label}</Typography>
                <TextField
                    style={{ flex: 3 }}
                    id="outlined-basic"
                    variant="outlined"
                    fullWidth
                    value={value}
                    onChange={event => onChange(event.target.value)}
                />
            </div>
        );
    };

    render_reason = () => {
        return (
            <Card
                style={{
                    margin: 10,
                    padding: 10,
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                Lý do:
                <Input onChange={e => this.setState({ ly_do_reject: e.target.value })} value={this.state.ly_do_reject} ></Input>
            </Card>
        );
    };

    render_rejcet = () => {
        return (
            <Card
                style={{
                    margin: 10,
                    padding: 10,
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                <FormControl required component="fieldset">
                    <FormGroup>
                        {this.state.list_check_fail.map(item => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={item.check}
                                        onChange={event => this.onChangeList_fail(event)}
                                        name={item.title}
                                    />
                                }
                                label={item.title}
                            />
                        ))}
                    </FormGroup>
                </FormControl>
            </Card>
        );
    };
    render_cs_confirm = () => {
        if (this.state.is_accept) {
            if (
                this.state.id_number != null &&
                this.state.id_name != null &&
                this.state.id_number != '' &&
                this.state.id_name != ''
            ) {
                return (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                            this.props.send_confirm_selfie(1, this.state.ly_do_reject)
                        }>
                        Xác nhận đổi thông tin người dùng và gửi thông báo
                    </Button>
                );
            }
        }

        if (this.state.is_accept == false) {
            return (
                <Button
                    variant="contained"
                    onClick={() => this.props.send_confirm_selfie(0, this.state.ly_do_reject)}
                    style={{ backgroundColor: 'rgb(244,67,54)', color: 'white' }}>
                    Xác nhận thông tin sai và gửi thông báo
                </Button>
            );
        }
    };

    render() {
        return (
            <div>
                <Card style={{ margin: 10, padding: 10 }}>
                    <Box style={{ margin: 10, display: 'flex', flexDirection: 'row' }}>
                        {this.render_img_selfie(this.props.data.result.selfie)}
                        {this.render_img(this.props.data.result.front)}
                        {this.render_img(this.props.data.result.back)}
                    </Box>

                    <Button
                        onClick={() =>
                            this.setState({ deg_rotate: this.state.deg_rotate + 90 })
                        }>
                        <RotateRightIcon />
                    </Button>
                </Card>

                <div style={{ marginLeft: "10px" }} >
                    {this.props.loading_confirm ? <CircularProgress /> : <> <Button
                        variant="contained"
                        color="secondary"
                        // onClick={() => this.setState({ is_accept: true })}>
                        onClick={() => this.props.send_confirm_selfie(1, this.state.ly_do_reject)}>
                        Accept
                    </Button>

                        <Button
                            variant="contained"
                            style={{ backgroundColor: 'rgb(244,67,54)', color: 'white', marginLeft: "10px" }}
                            // onClick={() => this.setState({ is_accept: false })}
                            onClick={() => this.props.send_confirm_selfie(0, this.state.ly_do_reject)}>
                            Reject
                   </Button>

                    </>}

                    {/* {this.render_reason()}
                    {this.render_cs_confirm()} */}
                </div>
            </div >
        );
    }
}

export default Selfie;
