import React, { Component } from 'react';
import { Box, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CONST_DATA from 'constant';
import { to_date_min } from 'utility';
import CardCS from './CardCS';
// import Note from '../Detail/Note';

class CommentList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // checkLinkImage= ()=>{
  //   const env = process.env.REACT_APP_ENVIRONMENT === 'STAGING' ? 'test' : 'prod';
  //   let linkImage = "";
  //   this.props.comment_log.map((item,index)=>{
  //     let checkSrc = item.comment.search("finhay.s3-ap-southeast-1.amazonaws.com")
  //     if(checkSrc){
  //       linkImage = item.comment
  //     }
  //     else{
  //       linkImage =`https://finhay.s3-ap-southeast-1.amazonaws.com/CS/${env}/${item.comment}`
  //     }
  //   })
  //   return linkImage;
  // }

  render() {
   
    return (
      <div>
        {this.props.comment_log.map((item, index) => {
          console.log({ item });
          if (item.type === 'image') {
            let linkImage = ''
            let checkSrc = item.comment.search("finhay.s3-ap-southeast-1.amazonaws.com")
           if(checkSrc){
             linkImage = item.comment
           }
           else{
             linkImage =CONST_DATA.get_image + item.comment
           }
            return (
              <Box key={index} >
                <img
                  alt="cover"
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'contain'
                  }}
                  // src={`https://finhay.s3-ap-southeast-1.amazonaws.com/CS/${env}/${item.comment}`}></img>
                  src={linkImage}></img>

                {this.props.edit_comment ? (
                  <IconButton
                    aria-label="delete"
                    onClick={() => this.props.remove_comment(item.comment)}>
                    <DeleteIcon />
                  </IconButton>
                ) : null}
              </Box>
            );
          } else {
            return (
              <CardCS
                key={index}
                top={item.full_name}
                mid={item.comment}
                bottom={to_date_min(item.date)}
                extra_data={
                  this.props.edit_comment ? (
                    <IconButton
                      aria-label="delete"
                      onClick={() => this.props.remove_comment(item.comment)}>
                      <DeleteIcon />
                    </IconButton>
                  ) : null
                }
              />
            );
          }
        })}
      </div>
    );
  }
}

export default CommentList;
