import React, { Component } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog
} from '@material-ui/core';

import CONST_DATA from 'constant';
import SearchText from './SearchText';
import Axios from 'axios';
import DialogEdit from './DialogEdit';
import TableAdd from './TableAdd';

const columns = [
  { label: 'Edit', minWidth: 1 },
  { label: 'Ngày', minWidth: 10 },
  { label: 'Số tiền', minWidth: 10 },
  { label: 'Bank', minWidth: 10 },

  { label: 'Diễn giải', minWidth: 10 },
  { label: 'Số bút toán', minWidth: 10 },
  { label: 'Transaction ID', minWidth: 10 },
  { label: 'ID', minWidth: 10 },
  { label: 'Mã case', minWidth: 10 },
  { label: 'CS', minWidth: 10 },
  { label: 'Ghi chú', minWidth: 10 }
];

const styles = theme => ({
  root: {},
  container: {
    maxHeight: '70vh'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 170
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
});

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: this.props.access_token,
      user_id: this.props.user_id,
      data: this.props.data,
      data_success: false,
      open_delete: false,
      id_delete: null,
      data_delete: null,

      note_change: null
    };
  }

  componentDidMount() {
    this.get_data();
  }

  ren_admin = data => {
    if (data.admin_assign != null) {
      return <TableCell>{data.admin_assign.full_name}</TableCell>;
    } else {
      return <TableCell></TableCell>;
    }
  };

  get_data = () => {
    const body_req = {
      user_id: this.state.user_id,
      access_token: this.state.access_token
    };

    Axios.post(CONST_DATA.ketoan_get_all_item, body_req)
      .then(response => {
        if (response.data.status == 'success') {
          this.setState({
            data_success: true,
            data: response.data.data
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  search_data = search_key => {
    const body_req = {
      user_id: this.state.user_id,
      access_token: this.state.access_token,
      search_key: search_key
    };

    Axios.post(CONST_DATA.ketoan_ketoan_search_item, body_req)
      .then(response => {
        if (response.data.status == 'success') {
          this.setState({
            data_success: true,
            data: response.data.data
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  delete_data = id_chuyen_tien_sai => {
    const body_req = {
      user_id: this.state.user_id,
      access_token: this.state.access_token,
      id_chuyen_tien_sai: id_chuyen_tien_sai
    };

    Axios.post(CONST_DATA.ketoan_delete_item, body_req)
      .then(response => {
        if (response.data.status == 'success') {
          this.props.refesh_page();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  edit_note = (id_chuyen_tien_sai, note) => {
    const body_req = {
      user_id: this.state.user_id,
      access_token: this.state.access_token,
      id_chuyen_tien_sai: id_chuyen_tien_sai,
      note: note
    };

    Axios.post(CONST_DATA.ketoan_edit_note, body_req)
      .then(response => {
        if (response.data.status == 'success') {
          this.props.refesh_page();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  render_delete = () => {
    return (
      <Dialog
        open={this.state.open_delete}
        onClose={() => this.setState({ open_delete: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <Button
          style={{ backgroundColor: 'red', color: 'white' }}
          onClick={() => {
            this.setState({ open_delete: false });
            this.delete_data(this.state.id_delete);
          }}>
          Xóa toàn bộ dữ liệu
        </Button>
        <br />
        <br />
        <DialogEdit
          send_note={note => this.edit_note(this.state.id_delete, note)}
        />
      </Dialog>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <TableAdd
          access_token={this.state.access_token}
          user_id={this.state.user_id}
          refesh_page={() => window.location.reload()}
          search_data={search_key => this.search_data(search_key)}
          role_admin={this.props.role_admin}
        />

        {this.render_delete()}
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.data_success
                  ? this.state.data.map((row, index) => (
                    <TableRow hover tabIndex={-1} key={index} >
                      <TableCell>
                        {(this.props.role_admin == CONST_DATA.ROLE_KE_TOAN && row.cs_confirm == false) ? (
                          <Button
                            onClick={() =>
                              this.setState({
                                open_delete: true,
                                id_delete: row.id
                              })
                            }
                            variant="outlined"
                            size="small"
                            color="primary">
                            Edit
                          </Button>
                        ) : null}
                      </TableCell>
                      <TableCell>{row.ngay}</TableCell>
                      <TableCell>
                        {row.so_tien
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </TableCell>
                      <TableCell>{row.bank}</TableCell>
                      <TableCell>{row.dien_giai}</TableCell>
                      <TableCell>{row.so_but_toan}</TableCell>
                      <TableCell>
                        {row.ticket_detail == null
                          ? null
                          : row.ticket_detail.transaction_id}
                      </TableCell>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.ma_case}</TableCell>
                      <TableCell>{row.cs_confirm.toString()}</TableCell>
                      <TableCell>
                        {row.note == null ? null : row.note.toString()}
                      </TableCell>
                    </TableRow>
                  ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(User);
