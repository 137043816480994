import React, { Component } from 'react';
import {
    Button,
    FormControl,
    FormGroup,
    Checkbox,
    FormControlLabel,
    Card,
    Box,
    Typography,
    TextField
} from '@material-ui/core';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import { list_check_fail } from './data';

class CheckUserPassport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reason: '',
            reject: false,
            accept: false,
            deg_rotate: 0,
            list_check_fail: list_check_fail,

            is_accept: null,

            id_name: null,
            id_identity: null,
            id_dob: null,
            id_nationality: null,
            id_issue_date: null,
            unrender: false,
        };
    }

    componentDidMount() {
        if (this.props.data_cmt) {
            console.log(this.props.data_cmt)
            let { passport_info } = this.props.data_cmt;
            this.setState({
                id_name: passport_info.name,
                id_dob: passport_info.dob,
                id_identity: passport_info.identity,
                id_issue_date: passport_info.id_issue_date,
                id_nationality: passport_info.nationality
            })
        }
        else {
            alert("không có dữ liệu")
            this.setState({
                unrender: true
            })
        }

    }

    render_img = src_link => {
        let max_h = 400;
        return (
            <div style={{ maxHeight: max_h, maxWidth: max_h }}>
                <img
                    src={src_link}
                    style={{
                        transform: 'rotate(' + this.state.deg_rotate + 'deg)',
                        maxHeight: max_h,
                        maxWidth: max_h
                    }}
                    alt=""
                />
            </div>
        );
    };

    render_img_selfie = src_link => {
        let max_h = 150;
        return (
            <div style={{ height: max_h, width: max_h }}>
                <img src={src_link} style={{ maxHeight: max_h, maxWidth: max_h }} alt="" />
            </div>
        );
    };

    onChangeList_fail = event => {

        let pre_list_check = this.state.list_check_fail;
        let index_of_item = this.state.list_check_fail.findIndex(
            a => a.title === event.target.name
        );
        pre_list_check[index_of_item].check = event.target.checked;
        this.setState({ list_check_fail: pre_list_check });
    };

    card_text = (label, value, onChange, key) => {
        return (
            <div
                style={{
                    margin: 5,
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                <Typography style={{ flex: 1 }}>{label}</Typography>
                <TextField
                    style={{ flex: 3 }}
                    id="outlined-basic"
                    variant="outlined"
                    fullWidth
                    value={value}
                    onChange={event => onChange(event.target.value)}
                />
            </div>
        );
    };

    card_textarea = (label, value, onChange, key) => {
        return (
            <div
                style={{
                    margin: 5,
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                <TextField
                    id="outlined-multiline-static"
                    label={label}
                    variant="outlined"
                    fullWidth
                    value={value}
                    onChange={event => onChange(event.target.value)}
                />
            </div>
        );
    };

    render_rejcet = () => {
        return (
            <Card style={{
                margin: 10,
                padding: 10,
                display: 'flex',
                flexDirection: 'column'
            }}>
                {this.card_textarea('Lí do từ chối', this.state.reason, value =>
                    this.setState({ reason: value }), 'reason'
                )}
            </Card>
        );
    };

    render_cs_confirm = () => {
        if (this.state.is_accept == false) {
            return (
                <Button
                    variant="contained"
                    onClick={() => {
                        this.props.send_reject(this.state.reason);
                    }}
                    style={{ backgroundColor: 'rgb(244,67,54)', color: 'white' }}>
                    Xác nhận thông tin sai và gửi thông báo
                </Button>
            );
        }
    };

    render() {
        return (
            <div style={{ display: this.state.unrender ? 'none' : 'block' }}>
                <Card style={{ margin: 10, padding: 10 }}>
                    <Box style={{ margin: 10, display: 'flex', flexDirection: 'row' }}>
                        {this.render_img_selfie(this.props.data.result.selfie)}
                        {this.render_img(this.props.data.result.front)}
                        {this.render_img(this.props.data.result.back)}
                    </Box>

                    <Button
                        onClick={() =>
                            this.setState({ deg_rotate: this.state.deg_rotate + 90 })
                        }>
                        <RotateRightIcon />
                    </Button>
                </Card>
                <Card style={{
                    margin: 10,
                    padding: 10,
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    {this.card_text('Họ và tên', this.state.id_name, value =>
                        this.setState({ id_name: value }), 'name'
                    )}
                    {this.card_text('Ngày sinh', this.state.id_dob, value =>
                        this.setState({ id_dob: value }), 'dob'
                    )}
                    {this.card_text('Số hộ chiếu', this.state.id_identity, value =>
                        this.setState({ id_identity: value }), 'id_identity'
                    )}
                    {this.card_text('Ngày cấp', this.state.id_issue_date, value =>
                        this.setState({ id_issue_date: value }), 'given_date'
                    )}
                    {this.card_text('Nơi cấp', this.state.id_nationality, value =>
                        this.setState({ id_nationality: value }), 'given_place'
                    )}
                </Card>
                <div>
                    <Button
                        variant="contained"
                        color="secondary"
                        style={{ marginLeft: "11px" }}
                        onClick={() =>
                            this.props.send_accept({
                                identity: this.state.id_identity,
                                name: this.state.id_name,
                                dob: this.state.id_dob,
                                id_issue_date: this.state.id_issue_date,
                                nationality: this.state.id_nationality
                            })
                        }>
                        Accept
                    </Button>
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: "rgb(244,67,54)",
                            color: "white",
                            marginLeft: "10px",
                        }}
                        onClick={() => this.setState({ is_accept: false })}
                    >
                        Reject
                    </Button>
                    {this.state.is_accept == true ? this.render_accept() : null}
                    {this.state.is_accept == false ? this.render_rejcet() : null}

                    {this.render_cs_confirm()}
                </div>
            </div>
        );
    }
}

export default CheckUserPassport;
