import React, { Component } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button, TextField, Card, Box, Typography, CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';

import CONST_DATA from 'constant';
import SearchText from './SearchText';
import Axios from 'axios';
import CardCS from 'views/Detail/CardCS';

const columns = [
  { label: 'Ngày', minWidth: 10 },
  { label: 'Diễn giải', minWidth: 10 },
  { label: 'Số bút toán', minWidth: 10 },
  { label: 'Số tiền', minWidth: 10 },
  { label: 'Bank', minWidth: 10 },
  { label: 'Ghi chú', minWidth: 10 },
  { label: '', minWidth: 10 }
];

const styles = theme => ({
  root: {}
});

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: this.props.access_token,
      user_id: this.props.user_id,
      text_add: null,
      disabled: false,

      search_key: null,

      insert_log: null,
      button_active:false,
      count:59
    };
    this.timer = 0;
  }

  send_data = async () => {
    const body_req = {
      user_id: this.state.user_id,
      access_token: this.state.access_token,
      text_add: this.state.text_add
    };
    this.setState({
      disabled: true,
    });

    await Axios.post(CONST_DATA.ketoan_hoan_tra_add_item, body_req)
      .then(response => {
        if (response.data.status == 'success') {
          this.setState({
            insert_log: response.data.data
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
    this.setState({
      disabled: false,
    });
  };
  export_file = () => {
    this.props.export_file_ketoan()
    this.setState({ 
      button_active:true
    })
    if (this.timer == 0 && this.state.count > 0) {
      this.timer = setInterval(this.count_down, 1000);
      console.log(this.timer)
    }
  }

  count_down =()=>{
    let count = this.state.count-1
    this.setState({
      count: count
    })

    if (count == 0) { 
      clearInterval(this.timer);
      this.setState({
        count:59,
        button_active:false
      })
      this.timer=0
    }

    
  }

  renderDuplicate = () => {
    return (
      <Card
        style={{
          padding: 20,
          margin: 20,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'row',
          width: 600
        }}>
        <Box>
          <Typography>Đã thêm {this.state.insert_log.success}</Typography>
          <Typography>Bị trùng {this.state.insert_log.duplicate}</Typography>
          <Typography>Bị lỗi {this.state.insert_log.error}</Typography>
        </Box>

        <Button
          onClick={() => this.props.refesh_page()}
          variant="outlined"
          size="small"
          color="primary">
          xác nhận
        </Button>
      </Card>
    );
  };

  showProgressLoading = () => {
    return (
      <CircularProgress />
    );
  }

  render() {
    const { classes } = this.props;
    const { disabled } = this.state;

    return (
      <div>
        <Box
          style={{
            padding: 20,
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row'
          }}>
          {this.props.role_admin == CONST_DATA.ROLE_KE_TOAN ? (
            <Card
              style={{
                flex: 1,
                margin: 10,
                flexDirection: 'row',
                display: 'flex'
              }}>
              <TextField
                placeholder="Copy từ google sheet sang đây"
                id="outlined-basic"
                variant="outlined"
                fullWidth
                type="text"
                className={classes.textField}
                value={this.state.text_add}
                onChange={event =>
                  this.setState({ text_add: event.target.value })
                }
              />

              <Button
                onClick={() => this.send_data()}
                variant="outlined"
                size="small"
                color="primary" disabled={disabled}>
                Gửi dữ liệu
              </Button>
            </Card>
          ) : null}
          <Card
            style={{
              flex: 1,
              margin: 10,
              flexDirection: 'row',
              display: 'flex'
            }}>
            <TextField
              placeholder="Từ khóa tìm kiếm"
              id="outlined-basic"
              variant="outlined"
              fullWidth
              type="text"
              className={classes.textField}
              value={this.state.search_key}
              onChange={event =>
                this.setState({ search_key: event.target.value })
              }
            />

            <Button
              onClick={() => this.props.search_data(this.state.search_key)}
              variant="outlined"
              size="small"
              color="primary">
              Tìm kiếm
            </Button>

            {this.props.role_admin===CONST_DATA.ROLE_KE_TOAN?(
              <Button
              onClick={() => this.export_file()}
              variant="outlined"
              size="small"
              color="primary"
              disabled={this.state.button_active}
              >
              {this.state.button_active===true?`Sử dụng lại sau (${this.state.count})`:'Export File'}
            </Button>
            ):null}
          </Card>
        </Box>
        {this.state.disabled === false ? null : this.showProgressLoading()}
        <Typography variant="h4">Lưu ý copy từng dòng</Typography>

        {this.state.insert_log == null ? null : this.renderDuplicate()}
      </div>
    );
  }
}

export default withStyles(styles)(User);
