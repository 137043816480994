import React, { Component } from 'react';
import {
  Box,
  Button,
} from '@material-ui/core';

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      note: ''
    };
  }

  render() {

    return (
      <Box display="flex" flex={1} flexDirection="column">
        {/* <TextField
          multiline={true}
          fullWidth
          type="text"
          variant="outlined"
          placeholder="Để lại ghi chú"
          value={this.state.note}
          onChange={event => this.setState({ note: event.target.value })}
        /> */}

        {this.state.note != '' ? (
          <Button
            style={{ margin: 10 }}
            color="primary"
            variant="contained"
            onClick={() => this.props.send_note(this.state.note)}>
            Lưu lại ghi chú
          </Button>
        ) : null}
      </Box>
    );
  }
}

export default User;
