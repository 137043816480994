import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList } from 'react-window';
import { List } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 350,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
}));



export default function VirtualizedList({ data, handleOnCick }) {
    const classes = useStyles();
    return (
        <div>
            {data.length > 0 && <List className={classes.root} style={{ marginTop: "20px" }} >
                {data.map(el => {
                    return <ListItem button key={el.user_id}>
                        <ListItemText primary={`${el.user_id}`} onClick={() => handleOnCick(el.user_id)} />
                    </ListItem>
                })}
            </List>}

        </div>
    );
}