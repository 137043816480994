import Axios from 'axios';
import CONST_DATA from 'constant';

const remove_comment_api = (body_req, callback) => {
  Axios.post(CONST_DATA.edit_comment, body_req)
    .then(response => {
      if (response.data.status === 'success') {
        callback();
      }
    })
    .catch(error => {
    });
};

const change_transaction_id_api = (body_req, callback) => {
  Axios.post(CONST_DATA.edit_transaction_id, body_req)
    .then(response => {
      if (response.data.status === 'success') {
        callback();
      }
    })
    .catch(error => {
    });
};

const change_admin_api = (body_req, callback) => {
  Axios.post(CONST_DATA.edit_admin_assign, body_req)
    .then(response => {
      if (response.data.status === 'success') {
        callback();
      }
    })
    .catch(error => {
    });
};

const send_note_api = (body_req, callback) => {
  Axios.post(CONST_DATA.edit_note, body_req)
    .then(response => {
      if (response.data.status === 'success') {
        callback();
      }
    })
    .catch(error => {
    });
};

const send_comment_api = (body_req, callback) => {
  Axios.post(CONST_DATA.add_comment, body_req)
    .then(response => {
      if (response.data.status === 'success') {
        callback();
      }
    })
    .catch(error => {
    });
};

const send_status_api = (body_req, callback) => {
  Axios.post(CONST_DATA.change_status, body_req)
    .then(response => {
      if (response.data.status === 'success') {
        callback();
      }
    })
    .catch(error => {
      console.log(error);
    });
};

const send_khop_lenh_api = (body_req, callback) => {
  Axios.post(CONST_DATA.ketoan_khop_chuyen_tien_dat_lenh_dau_tu, body_req)
    .then(response => {
      if (response.data.status === 'success') {
        callback();
      }
    })
    .catch(error => {
      console.log(error);
    });
};

const send_khop_lenh_tiet_kiem_api = (body_req, callback) => {
  Axios.post(CONST_DATA.ketoan_khop_chuyen_tien_dat_lenh_tiet_kiem, body_req)
    .then(response => {
      if (response.data.status === 'success') {
        callback();
      }
    })
    .catch(error => {
      console.log(error);
    });
};

const send_khop_lenh_cuu_long_api = (body_req, callback) => {
  Axios.post(CONST_DATA.ketoan_khop_chuyen_tien_dat_lenh_cuu_long, body_req)
    .then(response => {
      if (response.data.status === 'success') {
        callback();
      }
    })
    .catch(error => {
      console.log(error);
    });
};




const send_ly_do_bo_qua_api = (body_req, callback) => {
  Axios.post(CONST_DATA.them_ly_do_bo_qua, body_req)
    .then(response => {
      if (response.data.status === 'success') {
        callback();
      }
    })
    .catch(error => {
      console.log(error);
    });
};

const create_order_pay_cs_admin = (body_req, callback) => {
  Axios.post(CONST_DATA.create_order_pay_cs_admin, body_req)
    .then(response => {
      if (response.data.status === 'success') {
        // callback();
      }
    })
    .catch(error => {
      console.log(error);
    });
};


const mo_lai_don_api = (body_req, callback) => {
  Axios.post(CONST_DATA.mo_lai_don, body_req)
    .then(response => {
      if (response.data.status === 'success') {
        callback();
      }
    })
    .catch(error => {
      console.log(error);
    });
};

export {
  mo_lai_don_api,
  send_khop_lenh_cuu_long_api,
  send_ly_do_bo_qua_api,
  send_khop_lenh_tiet_kiem_api,
  send_khop_lenh_api,
  remove_comment_api,
  change_admin_api,
  send_note_api,
  send_comment_api,
  send_status_api,
  change_transaction_id_api,
  // send_khop_lenh_cd_3_thang_api,
  create_order_pay_cs_admin
};
