import React, { Component } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  TextField,
  IconButton,
  Box,
  Card,
  Button,
  CircularProgress
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const styles = theme => ({
  root: {},
  container: {
    maxHeight: '70vh'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 170
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
});

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seach_text: ''
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <Card
        style={{
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 10
        }}>
        <TextField
          placeholder="User_id, SDT, email, Mã yêu câu"
          id="outlined-basic"
          label="Tìm kiếm"
          fullWidth
          variant="outlined"
          value={this.state.seach_text}
          onChange={event => this.setState({ seach_text: event.target.value })}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              this.props.onClick(this.state.seach_text);
            }
          }}
        />
        {this.props.is_loading ? (
          <CircularProgress />
        ) : (
            <Button
              variant="outlined"
              onClick={() => this.props.onClick(this.state.seach_text)}
              size="small"
              color="primary">
              <IconButton>
                <SearchIcon />
              </IconButton>
            Search
            </Button>
          )}
      </Card>
    );
  }
}

export default withStyles(styles)(User);
