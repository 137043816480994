import React, { Component } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button
} from '@material-ui/core';

import CONST_DATA from 'constant';
import Axios from 'axios';

const columns = [
  { label: 'CS unconfirm', minWidth: 10 },
  { label: 'Ngày', minWidth: 10 },
  { label: 'Số tiền', minWidth: 10 },
  { label: 'Bank', minWidth: 10 },
  { label: 'Diễn giải', minWidth: 10 },
  { label: 'Số bút toán', minWidth: 10 },
  { label: 'Mã case', minWidth: 10 },
  { label: 'ID', minWidth: 10 },
  { label: 'Ghi chú', minWidth: 10 }
];

const styles = theme => ({
  root: {},
  container: {
    maxHeight: '70vh'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 170
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
});

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: this.props.access_token,
      user_id: this.props.user_id,
      data: this.props.data,
      data_success: false,
      id_chuyen_tien_sai: this.props.id_chuyen_tien_sai,
      ticket_id: this.props.ticket_id
    };
  }

  get_data = () => {
    const body_req = {
      user_id: this.state.user_id,
      access_token: this.state.access_token,
      id_chuyen_tien_sai: this.state.id_chuyen_tien_sai
    };

    Axios.post(CONST_DATA.ketoan_get_by_id, body_req)
      .then(response => {
        if (response.data.status == 'success') {
          this.setState({
            data_success: true,
            data: response.data.data
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.get_data();
  }

  cs_undo_confirm = (id_chuyen_tien_sai, ticket_id) => {
    const body_req = {
      user_id: this.state.user_id,
      access_token: this.state.access_token,
      id_chuyen_tien_sai: id_chuyen_tien_sai,
      ticket_id: ticket_id
    };

    Axios.post(CONST_DATA.ketoan_cs_undo_confirm, body_req)
      .then(response => {
        if (response.data.status == 'success') {
          this.props.refesh_page();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  render_delete = () => {
    if (this.props.status_id == 400 || this.props.status_id == 200) {
      return null;
    } else {
      return (
        <Button
          onClick={() =>
            this.cs_undo_confirm(this.state.data.id, this.props.ticket_id)
          }
          variant="outlined"
          size="small"
          color="primary">
          Delete
        </Button>
      );
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      key={index}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.data_success == true && this.state.data != null ? (
                  <TableRow hover tabIndex={-1}>
                    <TableCell>{this.render_delete()}</TableCell>
                    <TableCell>{this.state.data.ngay}</TableCell>
                    <TableCell>
                      {this.state.data.so_tien
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </TableCell>
                    <TableCell>{this.state.data.bank}</TableCell>
                    <TableCell>{this.state.data.dien_giai}</TableCell>
                    <TableCell>{this.state.data.so_but_toan}</TableCell>
                    <TableCell>{this.state.data.ma_case}</TableCell>
                    <TableCell>{this.state.data.id}</TableCell>

                    <TableCell>
                      {this.state.data.note == null
                        ? null
                        : this.state.data.note.toString()}
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(User);
