import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import DnsIcon from '@material-ui/icons/Dns';
import SettingsIcon from '@material-ui/icons/Settings';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import CommentIcon from '@material-ui/icons/Comment';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

import { Profile, SidebarNav, UpgradePlan } from './components';
import CONST_DATA from 'constant';
import { SupervisorAccount } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const check_login = () => {
  let login_data = localStorage.getItem(CONST_DATA.localStorage_login);
  if (login_data == null) {
    return 1000;
  } else {
    login_data = JSON.parse(login_data);
    return login_data.role;
  }
};

const new_comment = {
  title: 'Comment mới',
  href: CONST_DATA.router_new_comment,
  icon: <CommentIcon />
};

const cmt = {
  title: 'Chứng minh thư',
  href: CONST_DATA.router_cmt,
  icon: <AccountBoxIcon />
};

const viec_cua_toi = {
  title: 'Việc của tôi',
  href: CONST_DATA.router_viec_cua_toi,
  icon: <DnsIcon />
};

const sua_thong_tin = {
  title: 'Sửa thông tin',
  href: CONST_DATA.router_admin,
  icon: <SettingsIcon />
};
const doi_soat_ekyc = {
  title: 'Đối soát eKYC',
  href: CONST_DATA.router_doi_soat_ekyc,
  icon: <SupervisorAccount />
};
const dang_xuat = {
  title: 'Đăng xuất',
  href: CONST_DATA.router_sign_in,
  icon: <LockIcon />
};

const can_xu_ly = {
  title: 'Cần xử lý',
  href: CONST_DATA.router_can_xu_ly,
  icon: <DnsIcon />
};

const toan_bo_don = {
  title: 'Toàn bộ đơn',
  href: CONST_DATA.router_all,
  icon: <DnsIcon />
};

const ke_toan = {
  title: 'GD chuyển sai, trùng',
  href: CONST_DATA.router_ketoan,
  icon: <MonetizationOnIcon />
};

const ke_toan_hoan_tra = {
  title: 'GD hoàn trả',
  href: CONST_DATA.router_ketoan_hoan_tra,
  icon: <MonetizationOnIcon />
};

const cs_tool = {
  title: 'Công cụ',
  href: CONST_DATA.router_tool,
  icon: <SettingsIcon />
};

const list_user = {
  title: "Danh sách người dùng",
  href: CONST_DATA.router_list_user,
  icon: <SettingsIcon />,
};

const Sidebar = props => {
  const role_admin = check_login();

  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  let pages = [viec_cua_toi, sua_thong_tin, dang_xuat];

  if (role_admin == CONST_DATA.ROLE_CS) {
    //CS
    pages = [
      viec_cua_toi,
      can_xu_ly,
      toan_bo_don,
      cs_tool,
      new_comment,
      cmt,
      sua_thong_tin,
      dang_xuat,
      doi_soat_ekyc,
      ke_toan_hoan_tra,
    ];
  }

  if (role_admin == CONST_DATA.ROLE_KYC) {
    //KYC
    pages = [
      // cmt,
      sua_thong_tin,
      dang_xuat
    ];
  }

  if (role_admin == CONST_DATA.ROLE_TU_VAN) {
    //tu van vien
    pages = [viec_cua_toi, can_xu_ly, toan_bo_don, sua_thong_tin, dang_xuat];
  }

  if (role_admin == CONST_DATA.ROLE_KE_TOAN) {
    //ke toan
    pages = [
      viec_cua_toi,
      toan_bo_don,
      new_comment,
      ke_toan,
      ke_toan_hoan_tra,
      sua_thong_tin,
      dang_xuat,
      doi_soat_ekyc
    ];
  }

  if (role_admin == CONST_DATA.ROLE_KE_TOAN_KHOP_LENH) {
    //ke toan khop lenh
    pages = [
      viec_cua_toi,
      toan_bo_don,
      ke_toan,
      ke_toan_hoan_tra,
      new_comment,
      sua_thong_tin,
      dang_xuat
    ];
  }

  if (role_admin == CONST_DATA.ROLE_ADMIN) {
    //admin
    pages = [
      viec_cua_toi,
      can_xu_ly,
      toan_bo_don,
      ke_toan,
      ke_toan_hoan_tra,
      new_comment,
      cmt,
      cs_tool,
      sua_thong_tin,
      list_user,
      dang_xuat,
    ];
  }

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} />
        <UpgradePlan />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;