import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Card, Typography } from '@material-ui/core';
import { send_khop_lenh_tiet_kiem_api } from './Api';
import Axios from 'axios';
import CONST_DATA from 'constant';
import { toast } from 'react-toastify';

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
});

class KhopLenh extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data_success: false,
      data: null
    };
  }

  send_khop_lenh = money => {
    let transactionRef = this.props.transaction_ref
    if (this.props.payment_type != 18) {
      transactionRef = this.props.ticket_id
    }
    else {
      if (!transactionRef) {
        this.notify('error', 'Cần điền mã giao dịch cho OnePAY')
      }
      else if (!transactionRef.match(/^OnePAY-FT\w{14}$/)) {
        this.notify('error', 'Mã giao dịch OnePAY cần có định dạng OnePAY-FTxxxxxx')
        transactionRef = null
      }
    }
    if (transactionRef) {
      const body_req = {
        access_token: this.props.access_token,
        admin_id: this.props.user_id,
        money: money,
        user_id: this.props.data.user_id,
        ticket_id: this.props.ticket_id,
        payment_type: this.props.payment_type,
        transaction_ref: transactionRef
      };
      send_khop_lenh_tiet_kiem_api(body_req, () => window.location.reload());
    }
  };

  async componentDidMount() {
    await this.get_data();
  }

  get_data = () => {
    const body_req = {
      user_id: this.props.user_id,
      access_token: this.props.access_token,
      id_chuyen_tien_sai: this.props.id_chuyen_tien_sai
    };

    Axios.post(CONST_DATA.ketoan_get_by_id, body_req)
      .then(response => {
        if (response.data.status === 'success') {
          this.setState({
            data_success: true,
            data: response.data.data
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    if (this.state.data_success) {
      return (
        <Card
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 20,
            marginBottom: 20,
            padding: 20,
            backgroundColor: 'rgb(187,222,251)'
          }}>
          <Typography variant="h4" style={{ margin: 5 }}>
            Tạo lệnh mới và khớp lệnh tiết kiệm
          </Typography>
          <Typography variant="h4" style={{ margin: 5 }}>
            Số tiền:{' '}
            {this.state.data.so_tien
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            {' VND'}
          </Typography>

          <Button
            onClick={() => this.send_khop_lenh(this.state.data.so_tien)}
            style={{ marginTop: 10 }}
            variant="contained">
            Xác nhận khớp lênh
          </Button>
        </Card>
      );
    } else {
      return null;
    }
  }

  notify = (type, message) => {
    switch (type) {
      case 'success':
        toast.success(message);
        break;
      case 'error':
        toast.error(message);
        break;
      default:
        break;
    }
  }
}

export default withStyles(styles)(KhopLenh);
