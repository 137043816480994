import React, { Component } from 'react';
import { Button, Box, TextField, Typography } from '@material-ui/core';

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      note_change: null
    };
  }

  render() {
    return (
      <Box style={{ margin: 10, width: 400 }}>
        <Typography>Chinh sửa ghi chú</Typography>
        <TextField
          id="outlined-basic"
          variant="outlined"
          fullWidth
          type="text"
          value={this.state.note_change}
          onChange={event => this.setState({ note_change: event.target.value })}
        />
        <Button onClick={() => this.props.send_note(this.state.note_change)}>
          Cập nhật ghi chú
        </Button>
      </Box>
    );
  }
}

export default User;
