import React, { Component } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  TextField
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import {
  to_date,
  to_date_min,
  status_detail,
  to_date_search,
  convert_date_to_search
} from 'utility';
import CONST_DATA from 'constant';
import Axios from 'axios';

const columns = [
  { label: 'STT', minWidth: 10 },
  { label: 'Ngày comment', minWidth: 10 },
  { label: 'User id', minWidth: 10 },
  { label: 'Comment', minWidth: 10 },
  { label: 'Mã yêu cầu', minWidth: 10 },
  { label: 'Vấn đề ', minWidth: 10 },
  { label: 'Trạng thái', minWidth: 10 }
];

const styles = theme => ({
  root: {},
  container: {
    maxHeight: '70vh'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 170
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
});

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data
    };
  }

  ren_admin = data => {
    if (data.admin_assign != null) {
      return <TableCell>{data.admin_assign.full_name}</TableCell>;
    } else {
      return <TableCell></TableCell>;
    }
  };

  confirm_read_comment = ticket_id => {
    const body_req = {
      user_id: this.props.user_id,
      access_token: this.props.access_token,
      ticket_id: ticket_id
    };

    Axios.post(CONST_DATA.confirm_read_comment, body_req)
      .then(response => {
        this.props.reload();
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.data.map((row, index) => (
                  <TableRow hover tabIndex={-1}>
                    <TableCell>
                      <Link
                        onClick={() => this.confirm_read_comment(row.ticket_id)}
                        to={CONST_DATA.router_detail + '/' + row.ticket_id}
                        target="_blank">
                        <Button variant="outlined" size="small" color="primary">
                          {index + 1}
                        </Button>
                      </Link>
                    </TableCell>
                    <TableCell>{to_date_min(row.new_comment_date)}</TableCell>
                    <TableCell>{row.user_id}</TableCell>
                    <TableCell>
                      {row.comment_log.slice(-1)[0].comment}
                    </TableCell>
                    <TableCell>{row.ticket_id}</TableCell>
                    <TableCell>{row.ticket_type}</TableCell>
                    <TableCell>{status_detail(row.status_id)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(User);
