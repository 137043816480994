import React, { Component } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  TextField
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import {
  to_date,
  to_date_min,
  status_detail,
  to_date_search,
  convert_date_to_search,
  to_date_unix
} from 'utility';
import CONST_DATA from 'constant';
import SearchText from './SearchText';
import Axios from 'axios';
import FilterAdmin from './FilterAdmin';
import BasicPagination from 'components/Pagination/Pagination';

const columns = [
  { label: 'STT', minWidth: 10 },
  { label: 'Ngày tạo', minWidth: 10 },
  { label: 'Ngày cập nhật', minWidth: 10 },
  { label: 'User id', minWidth: 10 },
  { label: 'SDT', minWidth: 10 },
  { label: 'Email', minWidth: 10 },
  { label: 'Hạng', minWidth: 10 },
  { label: 'Mã yêu cầu', minWidth: 10 },
  { label: 'Vấn đề ', minWidth: 10 },
  { label: 'Trạng thái', minWidth: 10 },
  { label: 'Admin', minWidth: 10 }
  // { label: 'Ghi chú', minWidth: 10 }
];

const styles = theme => ({
  root: {},
  container: {
    maxHeight: '70vh'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 170
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
});

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: this.props.access_token,
      user_id: this.props.user_id,
      data: this.props.data,
      data_success: false,
      type_of_ticket: null,
      type_of_status: null,
      search_rank_user: null,
      type_of_admin: null,
      page: 1,
      totalPage: 50,
      limit: 10,
      seach_text: null,
      search_date: null,

      loading: false
    };
  }

  ren_admin = data => {
    if (data.admin_assign != null) {
      return <TableCell>{data.admin_assign.full_name}</TableCell>;
    } else {
      return <TableCell></TableCell>;
    }
  };

  handleOnChangePage = (event, value) => {
    this.setState({ page: value })
  }

  get_data = () => {
    this.setState({
      loading: true
    });
    let rank_title = this.state.search_rank_user
    if (!rank_title || rank_title == 'All' || rank_title == '') {
      rank_title = null
    }
    const body_req = {
      user_id: this.state.user_id,
      access_token: this.state.access_token,
      search_date_creat: to_date_unix(this.state.search_date),
      search_ticket_type: this.state.type_of_ticket,
      search_status_id: this.state.type_of_status,
      search_key_word: this.state.seach_text,
      search_rank_user: rank_title,
      search_admin_id: this.state.type_of_admin,
      page: this.state.page,
      limit: this.state.limit
    };
    Axios.post(CONST_DATA.search_api, body_req)
      .then(response => {
        if (response.data.status == 'success') {
          this.setState({
            data_success: true,
            data: response.data.data,
            loading: false
          });
        }
      })
      .catch(error => {
        this.setState({
          loading: false
        });
      });
  };

  componentDidUpdate(prevProps, prevState) {
    const flag = (prevState.page !== this.state.page) || (prevState.seach_text !== this.state.seach_text) || (prevState.search_date !== this.state.search_date) || (prevState.type_of_ticket !== this.state.type_of_ticket) || (prevState.type_of_status !== this.state.type_of_status) || (prevState.search_rank_user !== this.state.search_rank_user)
    if (flag) {
      this.get_data();
    }
  }


  componentDidMount() {
    this.get_data();
  }

  print_phone_number = (row, type) => {
    if (type == 'email') {
      try {
        return row.user_profile.user_profile.email;
      } catch (error) {
        return '';
      }
    } else {
      try {
        return row.user_profile.user_profile.phone_number;
      } catch (error) {
        return '';
      }
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Box display="flex" alignItems="center" justifyContent="center">
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              fullWidth
              className={classes.textField}
              value={this.state.search_date}
              onChange={event => {
                this.setState({ search_date: event.target.value });
              }}
              type="date"
            />
          </FormControl>

          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">
              Lọc theo vấn đề
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={this.state.type_of_ticket}
              onChange={event =>
                this.setState({ type_of_ticket: event.target.value })
              }>
              {CONST_DATA.type_of_ticket.map(item => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">
              Lọc theo trạng thái
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={this.state.type_of_status}
              onChange={event =>
                this.setState({ type_of_status: event.target.value })
              }>
              {CONST_DATA.type_of_status.map(item => (
                <MenuItem value={item.status_id}>{item.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FilterAdmin
            onChange={data => {
              this.setState({
                type_of_admin: data
              });
            }}
          />

          <SearchText
            loading={this.state.loading}
            onClick={async seach_text => {
              await this.setState({ seach_text: seach_text });

              this.get_data();
            }}
          />
        </Box>

        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.data_success
                  ? this.state.data.map((row, index) => (
                    <TableRow hover tabIndex={-1}>
                      <TableCell>
                        <Link
                          to={CONST_DATA.router_detail + '/' + row.ticket_id}
                          target="_blank">
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary">
                            {index + 1}
                          </Button>
                        </Link>
                      </TableCell>
                      <TableCell>{to_date_min(row.created_at)}</TableCell>
                      <TableCell>{to_date_min(row.update_at.date)}</TableCell>
                      <TableCell>{row.user_id}</TableCell>
                      <TableCell>
                        {this.props.role_admin == CONST_DATA.ROLE_TU_VAN
                          ? ''
                          : this.print_phone_number(row, 'phone_number')}
                      </TableCell>
                      <TableCell>
                        {this.props.role_admin == CONST_DATA.ROLE_TU_VAN
                          ? ''
                          : this.print_phone_number(row, 'email')}
                      </TableCell>
                      <TableCell>{row.rank_title}</TableCell>
                      <TableCell>{row.ticket_id}</TableCell>
                      <TableCell>{row.ticket_type}</TableCell>
                      <TableCell>{status_detail(row.status_id)}</TableCell>
                      {this.ren_admin(row)}
                    </TableRow>
                  ))
                  : null}
              </TableBody>

            </Table>
          </TableContainer>
          <BasicPagination handleOnChangePage={this.handleOnChangePage} totalPage={this.state.totalPage} page={this.state.page} ></BasicPagination>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(User);