import React, { Component } from 'react';
import { Card, Button, Typography, CircularProgress, Paper } from '@material-ui/core';
import Axios from 'axios';
import CONST_DATA from 'constant';
import { status_detail } from 'utility';
import { Link } from 'react-router-dom';
import { send_status_api, send_ly_do_bo_qua_api } from './Api';
const ImageBase64 = ({ data }) => <img style={{ maxWidth: "500px" }} src={`${data}`} />
const renderType = (base64Data) => base64Data.substring("data:image/".length, base64Data.indexOf(";base64"));
class DonTrungLap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_list: false,
      data: null,
      loading: true,
      data_success: false
    };
  }

  checkBase64 = (data) => {
    try {
      return ['png', 'jpeg', 'jpg'].includes(renderType(data))
    } catch (error) {
      return false;
    }
  }

  componentDidMount() {
    this.get_ticket_detail();
  }



  get_ticket_detail = () => {
    const body_req = {
      user_id: this.props.user_id,
      admin_id: this.props.admin_id,
      access_token: this.props.access_token,
      ticket_id: this.props.ticket_id
    };

    Axios.post(CONST_DATA.admin_get_ticket_trung_lap, body_req)
      .then(response => {
        if (response.data.status == 'success') {
          this.setState({
            data_success: true,
            data: response.data.data,
            loading: false
          });
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false })
      });
  };

  send_status = (status_id, ticket_id) => {
    const body_req = {
      user_id: this.props.admin_id,
      ticket_id: ticket_id,
      status_id: status_id,
      access_token: this.props.access_token
    };

    send_status_api(body_req, () => this.get_ticket_detail());

    const body_req_bo_qua = {
      user_id: this.props.admin_id,
      ticket_id: ticket_id,
      status_id: status_id,
      access_token: this.props.access_token,
      bo_qua_id: 1 // 1 la trung don
    };
    send_ly_do_bo_qua_api(body_req_bo_qua, () => window.location.reload());
  };

  render_ticket_detail = (item, index) => {
    return (
      <Card style={{ padding: 10, margin: 10 }}>
        <Link
          to={CONST_DATA.router_detail + '/' + item.ticket_id}
          target="_blank">
          <Button variant="contained" size="small" color="primary">
            {item.ticket_id}
          </Button>
        </Link>
        <Typography variant="h4">
          Trạng thái hiện tại: {status_detail(item.status_id)}
        </Typography>

        {item.status_id == 10 ? (
          <Button
            variant="contained"
            size="small"
            style={{ backgroundColor: 'red', color: 'white' }}
            onClick={() => this.send_status(200, item.ticket_id)}>
            Bỏ qua-trùng đơn
          </Button>
        ) : null}

        <Typography>Loại yêu cầu: {item.ticket_type}</Typography>

        {item.ticket_detail.map(item => {
          if (item.data && typeof item.data === 'string' && item.data.includes('https://finhay.s3-ap-southeast-1.amazonaws.com')) {
            return <Paper key={index} variant="outlined">
              <img style={{ maxHeight: "400px" }} src={item.data} />
            </Paper>
          }
          else if (item.type === 'image_upload') {
            return (
              <img
                style={{ maxWidth: 200 }}
                src={CONST_DATA.get_image + item.data}></img>
            );
          }
          else if (this.checkBase64(item.data)) {
            return <ImageBase64 data={item.data} ></ImageBase64>
          }
          else {
            return (
              <Typography gutterBottom>
                {item.type} {' : '}
                {item.data}
              </Typography>
            );
          }
        })}
      </Card>
    );
  };

  render() {
    return (
      <div style={{ padding: 10 }}>
        {this.state.data_success ? (
          <Button
            color="default"
            style={{ margin: 10 }}
            variant="contained"
            onClick={() => this.setState({ show_list: !this.state.show_list })}>
            Danh sách: {this.state.data.length} đơn
          </Button>
        ) : null}
        {this.state.data_success && this.state.show_list
          ? this.state.data.map((item, index) =>
            this.render_ticket_detail(item, index)
          )
          : null}
        {this.state.loading && <CircularProgress></CircularProgress>}
      </div>
    );
  }
}

export default DonTrungLap;
