import React, { Component } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import CONST_DATA from 'constant';
import Axios from 'axios';
import { get_time_second } from 'utility';

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
});

const STATUS_ID_GHI_NHAN = 300;

class AddCommentImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      comment_image_base64: null,
      comment_image_name: null
    };
  }

  handleChange = e => {
    console.log(e);
    e.preventDefault();
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.upload_image(file, reader.result);
    };
  };

  upload_image = (file, base64) => {
    if (file.size >= 2000000) {
      this.setState({
        error: 'File của bạn quá lớn (tối đa 2MB)'
      });
    } else {
      this.setState({
        error: null,
        comment_image_base64: base64,
        comment_image_name: file.name
      });
    }
  };

  send_comment_image = () => {
    const body_req = {
      user_id: this.props.user_id,
      ticket_id: this.props.ticket_id,
      image_base64: this.state.comment_image_base64,
      image_name: this.state.comment_image_name,
      access_token: this.props.access_token,
      date: get_time_second()
    };
    Axios.post(CONST_DATA.add_comment_image, body_req)
      .then(response => {
        if (response.data.status == 'success') {

          window.location.reload();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Button variant="contained" component="label" style={{ margin: 20 }}>
          Upload Ảnh biên lai
          <input
            type="file"
            style={{ display: 'none' }}
            onChange={e => this.handleChange(e)}
          />
        </Button>

        {this.state.comment_image_base64 != null ? (
          <div>
            <img
              style={{
                height: '100%',
                width: 400,
                objectFit: 'contain'
              }}
              src={this.state.comment_image_base64}></img>
            <Button
              variant="contained"
              component="label"
              onClick={() => this.send_comment_image()}
              style={{ margin: 20 }}>
              Gửi ảnh
            </Button>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(AddCommentImage);
