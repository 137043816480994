import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog
} from '@material-ui/core';

import CONST_DATA from 'constant';
import Axios from 'axios';
import DialogEdit from './DialogEdit';
import TableAdd from './TableAdd';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const columns = [
  { label: 'Edit', minWidth: 10 },
  { label: 'User Id', minWidth: 10 },
  { label: 'Ngày', minWidth: 10 },
  { label: 'Số tiền', minWidth: 10 },
  { label: 'Mã rút tiền', minWidth: 10 },
  { label: 'Diễn giải', minWidth: 10 },
  { label: 'ID', minWidth: 10 },
  { label: 'Mã case', minWidth: 10 },
  { label: 'CS', minWidth: 10 },
  { label: 'Ghi chú', minWidth: 10 }
];

const styles = theme => ({
  root: {},
  container: {
    maxHeight: '70vh'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 170
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
});

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: this.props.access_token,
      user_id: this.props.user_id,
      data: this.props.data,
      data_success: false,
      open_delete: false,
      id_delete: null,
      data_delete: null,
      note_change: null,
      message_error:'',
      open_error:false
    };
  }

  get_data = () => {
    const body_req = {
      user_id: this.state.user_id,
      access_token: this.state.access_token
    };

    Axios.post(CONST_DATA.ketoan_hoan_tra_get_all_item, body_req)
      .then(response => {
        if (response.data.status === 'success') {
          this.setState({
            data_success: true,
            data: response.data.data
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  search_data = search_key => {
    const body_req = {
      user_id: this.state.user_id,
      access_token: this.state.access_token,
      search_key: search_key
    };

    Axios.post(CONST_DATA.ketoan_hoan_tra_ketoan_search_item, body_req)
      .then(response => {
        if (response.data.status === 'success') {
          this.setState({
            data_success: true,
            data: response.data.data
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  delete_data = id_hoan_tra => {
    const body_req = {
      user_id: this.state.user_id,
      access_token: this.state.access_token,
      id_hoan_tra: id_hoan_tra
    };

    Axios.post(CONST_DATA.ketoan_hoan_tra_delete_item, body_req)
      .then(response => {
        if (response.data.status === 'success') {
          this.get_data();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  edit_note = (id_hoan_tra, note) => {
    const body_req = {
      user_id: this.state.user_id,
      access_token: this.state.access_token,
      id_hoan_tra: id_hoan_tra,
      note: note
    };

    Axios.post(CONST_DATA.ketoan_hoan_tra_edit_note, body_req)
      .then(response => {
        if (response.data.status === 'success') {

          window.location.reload();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  export_file_ketoan = ()=>{
    const headers = {
      user_id: this.state.user_id,
      access_token: this.state.access_token,
      "Content-Type": "application/json",
    };

    Axios.post(CONST_DATA.ketoan_hoan_tra_export_file,{},{headers: headers})
    .then(res=>{
      if(res.status==200){
        console.log(res.data.data)
        this.setState({
          message_error:res.data.data,
          open_error:true
        })
      }
    })
    .catch(error => {
      console.log(error);
    })

  }

  componentDidMount() {
    this.get_data();
  }

  render_delete = () => {
    return (
      <Dialog
        open={this.state.open_delete}
        onClose={() => this.setState({ open_delete: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <Button
          style={{ backgroundColor: 'red', color: 'white' }}
          onClick={() => {
            this.setState({ open_delete: false });
            this.delete_data(this.state.id_delete);
          }}>
          Xóa toàn bộ dữ liệu
        </Button>
        <br />
        <br />
        <DialogEdit
          send_note={note => this.edit_note(this.state.id_delete, note)}
        />
      </Dialog>
    );
  };

  handleClose =()=>{
    this.setState({
      open_error:false
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <TableAdd
          access_token={this.state.access_token}
          user_id={this.state.user_id}
          refesh_page={() => window.location.reload()}
          search_data={search_key => this.search_data(search_key)}
          role_admin={this.props.role_admin}
          export_file_ketoan = {(data)=>this.export_file_ketoan(data)}
        />

        {this.render_delete()}
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map(column => {
                    return (
                      <TableCell
                        key={column.label}
                        align={column.align ? column.align : 'left'}
                        style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    )
                  }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.data_success
                  ? this.state.data.map((row, index) => {
                    return (
                      <TableRow key={row.ma_rut_tien} hover tabIndex={-1}>
                        <TableCell>
                          {this.props.role_admin == CONST_DATA.ROLE_KE_TOAN ? (
                            <Button
                              onClick={() =>
                                this.setState({
                                  open_delete: true,
                                  id_delete: row.id
                                })
                              }
                              variant="outlined"
                              size="small"
                              color="primary">
                              Edit
                            </Button>
                          ) : null}
                        </TableCell>
                        <TableCell>{row.user_id ? row.user_id : null}</TableCell>
                        <TableCell>{row.date}</TableCell>
                        <TableCell>
                          {row.so_tien
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </TableCell>

                        <TableCell>{row.ma_rut_tien}</TableCell>
                        <TableCell>{row.dien_giai}</TableCell>
                        <TableCell>{row.id}</TableCell>
                        <TableCell>{row.ma_case}</TableCell>
                        <TableCell>{row.cs_match ? 'yes' : 'no'}</TableCell>
                        <TableCell>{row.note ? row.note : null}</TableCell>

                      </TableRow>
                    )
                  })
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <Snackbar open={this.state.open_error} autoHideDuration={5000} onClose={this.handleClose} anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Alert onClose={this.handleClose} severity="success">
          {this.state.message_error}
        </Alert>
      </Snackbar>
      </div>
    );
  }
}

export default withStyles(styles)(User);
