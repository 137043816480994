const localhost = process.env.REACT_APP_API;
const localhostApiCD3M = process.env.REACT_APP_API_CD_3M;
const url_prefix = '';

const CONST_DATA = {
  add_ticket: localhost + "add_ticket",
  get_ticket_id: localhost + "get_ticket/", //+id
  get_ticket_user: localhost + "get_ticket_user/",
  file_upload: localhost + "file/upload",
  get_image: localhost + "get_image/",
  check_selfie: localhost + "v2.0/cmt_trueid_api/ekyc/cs/selfie/check",
  // check_selfie: localhost + "cmt_trueid_api/ekyc/cs/selfie/check",
  create_order_pay_cs_admin: localhostApiCD3M + "transaction/cms/deposit",
  // get_image: "https://cs.finhay.com.vn/api_finhaycs/" + 'get_image/',
  add_comment_image: localhost + "add_comment_image",
  end_point_login: localhost + "login_admin",
  search_api: localhost + "search_api",
  doi_soat_ekyc: localhost + "ketoan/doisoat/ekyc",
  localStorage_login: "localStorage_login",
  // admin router
  router_sign_in: url_prefix + "/sign-in",
  router_can_xu_ly: url_prefix + "/list_can_xu_ly",
  router_dang_giai_quyet: url_prefix + "/list_dang_giai_quyet",
  router_ghi_nhan: url_prefix + "/list_ghi_nhan",
  router_hoan_thanh: url_prefix + "/list_hoan_thanh",
  router_bo_qua: url_prefix + "/list_bo_qua",

  router_not_found: url_prefix + "/not-found",
  router_all: url_prefix + "/list_all",
  router_for_me: url_prefix + "/list_for_me",
  router_admin_0: url_prefix + "/admin0",
  router_admin: url_prefix + "/admin",
  router_detail: url_prefix + "/detail",
  router_ketoan: url_prefix + "/ketoan",
  router_ketoan_hoan_tra: url_prefix + "/ketoan_hoan_tra",

  router_viec_cua_toi: url_prefix + "/viec_cua_toi",
  router_doi_soat_ekyc: url_prefix + "/doi_soat_ekyc",
  router_new_comment: url_prefix + "/new_comment",
  router_cmt: url_prefix + "/cmt",
  router_tool: url_prefix + "/tool",
  router_list_user: url_prefix + "/danh_sach_nguoi_dung",

  // url back end admin
  get_ticket_admin: localhost + "get_ticket_admin",
  get_ticket_admin_ticket_id: localhost + "get_ticket_admin_ticket_id",
  get_ticket_by_admin_id: localhost + "get_ticket_by_admin_id",

  add_comment: localhost + "add_comment",
  edit_comment: localhost + "admin/edit_ticket/edit_comment",
  edit_note: localhost + "admin/edit_ticket/edit_note",
  edit_admin_assign: localhost + "admin/edit_ticket/edit_admin_assign",
  edit_transaction_id: localhost + "admin/edit_ticket/edit_transaction_id",
  confirm_read_comment: localhost + "admin/edit_ticket/confirm_read_comment",
  them_ly_do_bo_qua: localhost + "admin/edit_ticket/them_ly_do_bo_qua",
  edit_quy_trinh: localhost + "admin/edit_ticket/edit_quy_trinh",
  add_truy_thu: localhost + "admin/edit_ticket/add_truy_thu",
  comment_noi_bo: localhost + "admin/edit_ticket/comment_noi_bo",
  tao_ho_don: localhost + "admin/edit_ticket/tao_ho_don",

  change_status: localhost + "change_status",
  mo_lai_don: localhost + "mo_lai_don",
  get_admin_by_id: localhost + "get_admin_by_id/",

  edit_user_admin_password: localhost + "edit_user_admin_password",
  edit_user_admin_full_name: localhost + "edit_user_admin_full_name",
  get_all_admin: localhost + "get_all_admin",
  get_admin_giao_viec: localhost + "get_admin_giao_viec",

  creat_user_admin: localhost + "creat_user_admin",
  change_status_user: localhost + "admin/",

  ketoan_get_all_item: localhost + "ketoan/get_all_item",
  ketoan_add_item: localhost + "ketoan/add_item",
  ketoan_search_item: localhost + "ketoan/search_item",
  ketoan_ketoan_search_item: localhost + "ketoan/ketoan_search_item",
  ketoan_delete_item: localhost + "ketoan/delete_item",
  ketoan_edit_note: localhost + "ketoan/edit_note",
  ketoan_cs_confirm: localhost + "ketoan/cs_confirm",
  ketoan_get_by_id: localhost + "ketoan/get_by_id",
  ketoan_cs_undo_confirm: localhost + "ketoan/cs_undo_confirm",
  ketoan_thong_ke: localhost + "ketoan/thong_ke",
  cs_match_tk_ngan_hang: localhost + "ketoan_hoan_tra/cs_match",
  cs_un_match_tk_ngan_hang: localhost + "ketoan_hoan_tra/cs_undo_match",

  ketoan_hoan_tra_add_item: localhost + "ketoan_hoan_tra/add_item",
  ketoan_hoan_tra_get_all_item: localhost + "ketoan_hoan_tra/get_all_item",
  ketoan_hoan_tra_search_item: localhost + "ketoan_hoan_tra/search_item",
  ketoan_hoan_tra_ketoan_search_item:
    localhost + "ketoan_hoan_tra/ketoan_search_item",
  ketoan_hoan_tra_export_file: localhost + "hoan_tra/chuyen_tien",
  ketoan_hoan_tra_delete_item: localhost + "ketoan_hoan_tra/delete_item",
  ketoan_hoan_tra_edit_note: localhost + "ketoan_hoan_tra/edit_note",
  ketoan_hoan_tra_get_by_id: localhost + "ketoan_hoan_tra/get_by_id",
  ketoan_hoan_tra_change_status: localhost + "ketoan_hoan_tra/change_status",
  ketoan_hoan_tra_cs_match: localhost + "ketoan_hoan_tra/cs_match",
  ketoan_hoan_tra_cs_undo_match: localhost + "ketoan_hoan_tra/cs_undo_match",
  update_bank_info: localhost + "bank",
  ketoan_khop_chuyen_tien_dat_lenh_dau_tu:
    localhost + "ketoan/khop_chuyen_tien/dat_lenh_dau_tu",

  ketoan_khop_chuyen_tien_dat_lenh_tiet_kiem:
    localhost + "ketoan/khop_chuyen_tien/dat_lenh_tiet_kiem",

  ketoan_khop_chuyen_tien_dat_lenh_cuu_long:
    localhost + "ketoan/khop_chuyen_tien/dat_lenh_cuu_long",

  lay_list_pending: localhost + "ketoan/khop_chuyen_tien/pending",
  ke_toan_khop_lenh: localhost + "ketoan/khop_chuyen_tien/khop_lenh",

  admin_get_ticket_trung_lap: localhost + "admin/get_ticket_trung_lap",
  get_ticket_new_comment: localhost + "get_ticket_new_comment",

  get_goal_id: localhost + "add_ticket/get_goal_id/",

  ocr_get_ocr: localhost + "ocr/get_ocr/", // + image_name

  //cmt
  cmt_get_list: localhost + "cmt_trueid_api/get_list",
  cmt_get_image: localhost + "v2.0/cmt_trueid_api/get_image",
  // cmt_get_image: localhost + "cmt_trueid_api/get_image",
  cmt_get_detail_user_id: localhost + "v2.0/cmt_trueid_api/get_detail_user_id",
  // cmt_get_detail_user_id: localhost + "cmt_trueid_api/get_detail_user_id",
  cmt_get_detail_user_id_v2:
    localhost + "cmt_trueid_api/get_detail_user_id",
  accept_trueid_v2: localhost + "v2.0/cmt_trueid_api/ekyc/cs_accept",

  ekyc_api_cs_accept: localhost + "ekyc_api/cs_accept",
  ekyc_api_cs_reject: localhost + "ekyc_api/cs_reject",
  cmt_trueid_api_cs_accept: localhost + "cmt_trueid_api/cs_accept",
  cmt_trueid_api_cs_reject: localhost + "cmt_trueid_api/cs_reject",

  // accept reject passport cmt
  cmt_passport_api_cs_accept: localhost + "passport/_accept",
  cmt_passport_api_cs_reject: localhost + "passport/_reject",

  // accept reject re-ekyc
  re_ekyc_cs_accept: localhost + "re_ekyc_api/cs_accept",
  re_ekyc_cs_reject: localhost + "re_ekyc_api/cs_reject",

  ROLE_KE_TOAN: 50,
  ROLE_KE_TOAN_KHOP_LENH: 55,
  ROLE_CS: 100,
  ROLE_ADMIN: 0,
  ROLE_TEST: 1000,
  ROLE_KYC: 10000,
  ROLE_TU_VAN: 70,

  type_of_ticket: [
    "ALL",
    "CHUYỂN TIỀN",
    "SỬA THÔNG TIN TÀI KHOẢN NGÂN HÀNG",
    "SỬA THÔNG TIN",
    "ĐỐI SOÁT",
    "SÁP NHẬP",
    "BẢO HIỂM",
    "HOÀN TIỀN",
    "SỬA CMTND CCCD",
  ],

  type_of_rank: [
    "ALL",
    "Vàng",
    "Bạc",
    "Đồng",
    "Mới",
  ],

  type_of_status: [
    { status_id: 0, label: "ALL" },
    { status_id: 10, label: "Nhận đơn" },
    { status_id: 100, label: "Đang giải quyết" },
    { status_id: 200, label: "Bỏ qua" },
    { status_id: 300, label: "Chờ bổ sung thông tin" },
    { status_id: 350, label: "Điều chỉnh đơn" },
    { status_id: 400, label: "Thành công" },
  ],

  loai_dau_tu: "dau_tu",
  loai_tiet_kiem: "tiet_kiem",
  loai_cuu_long: "cuu_long",
  loai_cd_3_thang: "cd_3_thang",
  loai_cd_12_thang:'cd_12_thang',
  fake_cd_3M: [
    {
      bonus_rate: 0.0,
      created_at: "2021-01-20 18:03:15",
      due_date: null,
      fee: 0,
      id: 9789,
      interest_rate: 5.0,
      original_id: null,
      original_paid_at: null,
      paid_amount: 0,
      paid_at: null,
      partner_code: "MOMO",
      payment_type: "BANK_TRANSFER",
      place_amount: 2000000,
      product_id: 11,
      request_id: null,
      server_time: "2021-01-29 14:45:36",
      source: "iOS",
      status: "OPEN",
      temporary_interest: null,
      transaction_ref: null,
      transaction_type: "DEPOSIT",
      updated_at: "2021-01-20 18:03:15",
      user_id: 1153,
    },
    {
      bonus_rate: 0.0,
      created_at: "2021-01-20 18:03:30",
      due_date: null,
      fee: 0,
      id: 9790,
      interest_rate: 5.0,
      original_id: null,
      original_paid_at: null,
      paid_amount: 0,
      paid_at: null,
      partner_code: "FINHAY",
      payment_type: "BANK_TRANSFER",
      place_amount: 3000000,
      product_id: 11,
      request_id: null,
      server_time: "2021-01-29 14:45:36",
      source: null,
      status: "OPEN",
      temporary_interest: null,
      transaction_ref: null,
      transaction_type: "DEPOSIT",
      updated_at: "2021-01-21 13:48:35",
      user_id: 1153,
    },
    {
      bonus_rate: 0.0,
      created_at: "2021-01-20 18:04:40",
      due_date: null,
      fee: 0,
      id: 9791,
      interest_rate: 5.0,
      original_id: null,
      original_paid_at: null,
      paid_amount: 0,
      paid_at: null,
      partner_code: "FINHAY",
      payment_type: "BANK_TRANSFER",
      place_amount: 10000000,
      product_id: 11,
      request_id: null,
      server_time: "2021-01-29 14:45:36",
      source: "iOS",
      status: "OPEN",
      temporary_interest: null,
      transaction_ref: "abc_ref",
      transaction_type: "DEPOSIT",
      updated_at: "2021-01-20 18:04:40",
      user_id: 1153,
    },
    {
      bonus_rate: 0.0,
      created_at: "2021-01-20 18:05:40",
      due_date: null,
      fee: 0,
      id: 9792,
      interest_rate: 5.0,
      original_id: null,
      original_paid_at: null,
      paid_amount: 0,
      paid_at: null,
      partner_code: "MOMO",
      payment_type: "BANK_TRANSFER",
      place_amount: 2000000,
      product_id: 11,
      request_id: null,
      server_time: "2021-01-29 14:45:36",
      source: "iOS",
      status: "OPEN",
      temporary_interest: null,
      transaction_ref: null,
      transaction_type: "DEPOSIT",
      updated_at: "2021-01-20 18:05:40",
      user_id: 1153,
    },
    {
      bonus_rate: 0.0,
      created_at: "2021-01-20 18:17:16",
      due_date: null,
      fee: 0,
      id: 9795,
      interest_rate: 5.0,
      original_id: null,
      original_paid_at: null,
      paid_amount: 0,
      paid_at: null,
      partner_code: "MOMO",
      payment_type: "BANK_TRANSFER",
      place_amount: 2000000,
      product_id: 11,
      request_id: null,
      server_time: "2021-01-29 14:45:36",
      source: "iOS",
      status: "OPEN",
      temporary_interest: null,
      transaction_ref: null,
      transaction_type: "DEPOSIT",
      updated_at: "2021-01-20 18:17:16",
      user_id: 1153,
    },
    {
      bonus_rate: 0.0,
      created_at: "2021-01-20 18:18:19",
      due_date: null,
      fee: 0,
      id: 9796,
      interest_rate: 5.0,
      original_id: null,
      original_paid_at: null,
      paid_amount: 0,
      paid_at: null,
      partner_code: "FINHAY",
      payment_type: "BANK_TRANSFER",
      place_amount: 5000000,
      product_id: 11,
      request_id: null,
      server_time: "2021-01-29 14:45:36",
      source: "Android",
      status: "OPEN",
      temporary_interest: null,
      transaction_ref: null,
      transaction_type: "DEPOSIT",
      updated_at: "2021-01-20 18:18:19",
      user_id: 1153,
    },
    {
      bonus_rate: 0.0,
      created_at: "2021-01-21 09:21:06",
      due_date: null,
      fee: 0,
      id: 9797,
      interest_rate: 5.0,
      original_id: null,
      original_paid_at: null,
      paid_amount: 0,
      paid_at: null,
      partner_code: "FINHAY",
      payment_type: "BANK_TRANSFER",
      place_amount: 2222000,
      product_id: 11,
      request_id: null,
      server_time: "2021-01-29 14:45:36",
      source: null,
      status: "OPEN",
      temporary_interest: null,
      transaction_ref: null,
      transaction_type: "DEPOSIT",
      updated_at: "2021-01-21 13:48:35",
      user_id: 1153,
    },
    {
      bonus_rate: 0.0,
      created_at: "2021-01-21 11:01:32",
      due_date: null,
      fee: 0,
      id: 9805,
      interest_rate: 6.0,
      original_id: null,
      original_paid_at: null,
      paid_amount: 0,
      paid_at: null,
      partner_code: "FINHAY",
      payment_type: "BANK_TRANSFER",
      place_amount: 10000000,
      product_id: 10,
      request_id: null,
      server_time: "2021-01-29 14:45:36",
      source: "iOS",
      status: "OPEN",
      temporary_interest: null,
      transaction_ref: "abc_ref",
      transaction_type: "DEPOSIT",
      updated_at: "2021-01-21 11:01:32",
      user_id: 1153,
    },
    {
      bonus_rate: 0.0,
      created_at: "2021-01-21 14:58:12",
      due_date: null,
      fee: 0,
      id: 9810,
      interest_rate: 6.0,
      original_id: null,
      original_paid_at: null,
      paid_amount: 0,
      paid_at: null,
      partner_code: "FINHAY",
      payment_type: "BANK_TRANSFER",
      place_amount: 5000000,
      product_id: 10,
      request_id: null,
      server_time: "2021-01-29 14:45:36",
      source: "Android",
      status: "OPEN",
      temporary_interest: null,
      transaction_ref: null,
      transaction_type: "DEPOSIT",
      updated_at: "2021-01-21 14:58:12",
      user_id: 1153,
    },
    {
      bonus_rate: 0.0,
      created_at: "2021-01-21 15:03:48",
      due_date: null,
      fee: 0,
      id: 9812,
      interest_rate: 6.0,
      original_id: null,
      original_paid_at: null,
      paid_amount: 0,
      paid_at: null,
      partner_code: "MOMO",
      payment_type: "BANK_TRANSFER",
      place_amount: 13000000,
      product_id: 10,
      request_id: null,
      server_time: "2021-01-29 14:45:36",
      source: "iOS",
      status: "OPEN",
      temporary_interest: null,
      transaction_ref: null,
      transaction_type: "DEPOSIT",
      updated_at: "2021-01-21 15:03:48",
      user_id: 1153,
    },
    {
      bonus_rate: 0.0,
      created_at: "2021-01-21 18:12:33",
      due_date: null,
      fee: 0,
      id: 9851,
      interest_rate: 6.0,
      original_id: null,
      original_paid_at: null,
      paid_amount: 0,
      paid_at: null,
      partner_code: null,
      payment_type: "BANK_TRANSFER",
      place_amount: 1000000,
      product_id: 10,
      request_id: null,
      server_time: "2021-01-29 14:45:36",
      source: null,
      status: "OPEN",
      temporary_interest: null,
      transaction_ref: null,
      transaction_type: "DEPOSIT",
      updated_at: "2021-01-21 18:12:33",
      user_id: 1153,
    },
    {
      bonus_rate: 0.0,
      created_at: "2021-01-22 11:19:19",
      due_date: null,
      fee: 0,
      id: 9897,
      interest_rate: 5.0,
      original_id: null,
      original_paid_at: null,
      paid_amount: 0,
      paid_at: null,
      partner_code: null,
      payment_type: "BANK_TRANSFER",
      place_amount: 2222222,
      product_id: 11,
      request_id: null,
      server_time: "2021-01-29 14:45:36",
      source: "iOS",
      status: "OPEN",
      temporary_interest: null,
      transaction_ref: null,
      transaction_type: "DEPOSIT",
      updated_at: "2021-01-26 16:45:02",
      user_id: 1153,
    },
    {
      bonus_rate: 0.0,
      created_at: "2021-01-25 10:02:48",
      due_date: null,
      fee: 0,
      id: 97622,
      interest_rate: 6.0,
      original_id: null,
      original_paid_at: null,
      paid_amount: 0,
      paid_at: null,
      partner_code: null,
      payment_type: "SYSTEM",
      place_amount: 9999999,
      product_id: 9,
      request_id: null,
      server_time: "2021-01-29 14:45:36",
      source: "iOS",
      status: "OPEN",
      temporary_interest: null,
      transaction_ref: null,
      transaction_type: "DEPOSIT",
      updated_at: "2021-01-26 10:57:29",
      user_id: 1153,
    },
    {
      bonus_rate: 0.0,
      created_at: "2021-01-27 10:47:37",
      due_date: null,
      fee: 0,
      id: 97668,
      interest_rate: 6.0,
      original_id: null,
      original_paid_at: null,
      paid_amount: 0,
      paid_at: null,
      partner_code: null,
      payment_type: "SYSTEM",
      place_amount: 1000000,
      product_id: 9,
      request_id: null,
      server_time: "2021-01-29 14:45:36",
      source: "iOS",
      status: "OPEN",
      temporary_interest: null,
      transaction_ref: null,
      transaction_type: "DEPOSIT",
      updated_at: "2021-01-27 10:47:37",
      user_id: 1153,
    },
  ],
};

export default CONST_DATA;