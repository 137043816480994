import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Card, Typography } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {
    send_khop_lenh_cd_3_thang_api
} from './Api';
import { toast } from 'react-toastify';
import Axios from 'axios';
import CONST_DATA from 'constant';

const styles = theme => ({
    root: {
        padding: theme.spacing(2)
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    }
});

class KhopLenhCD3M extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data_success: false,
            data: null,
            partner_code: 'FINHAY',
            error: null
        };
    }

    notify = (type, message) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            default:
                break;
        }

    }

    send_khop_lenh = () => {
        let { access_token, user_id, money, ticket_id, payment_type, admin_id, transaction_ref } = this.props;
        // money = parseInt(money.replaceAll(',', ''))
        let transactionRef = transaction_ref
        if (this.props.payment_type != 2) {
            transactionRef = ticket_id
        }
        else {
            if (!transactionRef) {
              this.notify('error', 'Cần điền mã giao dịch cho OnePAY')
            }
            else if (!transactionRef.match(/^OnePAY-FT\w{14}$/)) {
              this.notify('error', 'Mã giao dịch OnePAY cần có định dạng OnePAY-FTxxxxxx')
              transactionRef = null
            }
          }
        if (transactionRef) {
            const body_req = {
                access_token,
                admin_id,
                user_id,
                money,
                ticket_id,
                payment_type,
                loai_lenh: 'cd_3_thang',
                partner_code: this.state.partner_code,
                source: "CS",
                transaction_ref: transactionRef
            }
            Axios.post(CONST_DATA.ke_toan_khop_lenh, body_req)
                .then(response => {
                    const result = response.data;
                    if (result.data.message === 'success') {
                        this.setState({
                            response_data: result.data.message
                        });
                        this.notify('success', 'Khớp lệnh thành công cho CD3M.')
                        setTimeout(() => {
                            window.location.reload()
                        }, 1500);
                    }
                    else {
                        this.notify('error', result.data.message)
                    }
                })
                .catch(error => {
                    this.notify('error', error.message)
                });
        }
    };

    handlePartnerRadioChange = (event) => {

    };

    async componentDidMount() {
        await this.get_data();
    }

    get_data = () => {
        const body_req = {
            user_id: this.props.admin_id,
            access_token: this.props.access_token,
            id_chuyen_tien_sai: this.props.id_chuyen_tien_sai
        };

        Axios.post(CONST_DATA.ketoan_get_by_id, body_req)
            .then(response => {
                if (response.data.status === 'success') {
                    this.setState({
                        data_success: true,
                        data: response.data.data
                    });

                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    render() {
        if (this.state.data_success) {
            return (
                <Card
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 20,
                        marginBottom: 20,
                        padding: 20,
                        backgroundColor: 'rgb(187,222,251)'
                    }}>
                    <Typography variant="h4" style={{ margin: 5 }}>
                        Tạo lệnh mới và khớp lệnh CD 3 tháng
                    </Typography>
                    <Typography variant="h4" style={{ margin: 5 }}>
                        Số tiền:{' '}
                        {this.state.data.so_tien
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        {' VND'}
                    </Typography>

                    <FormControl style={{ marginTop: "20px", marginLeft: "10px" }} component="fieldset">
                        <FormLabel component="legend"><strong>Partner code</strong></FormLabel>
                        <RadioGroup aria-label="partner_code" name="partner_code"
                            value={this.state.partner_code}
                            onChange={this.handlePartnerRadioChange}
                            defaultValue='FINHAY'
                        >
                            <FormControlLabel value="FINHAY" control={<Radio />} label="FINHAY" />
                            {/* <FormControlLabel value="MOMO" control={<Radio />} label="MOMO" /> */}
                        </RadioGroup>
                    </FormControl>
                    <Button
                        onClick={() => this.send_khop_lenh(this.state.data.so_tien)}
                        style={{ marginTop: 10 }}
                        variant="contained">
                        Xác nhận khớp lênh
                    </Button>
                </Card>
            );
        } else {
            return null;
        }
    }
}

export default withStyles(styles)(KhopLenhCD3M);
