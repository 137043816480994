import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TableSearchHoanTra from './TableSearchHoanTra';
import { toast } from 'react-toastify';
import { Typography, Button, Box, Card, CardContent, Paper, CircularProgress, Snackbar } from '@material-ui/core';
import CONST_DATA from 'constant';
import Axios from 'axios';
import {
  status_detail,
  to_date_min,
  bo_qua_detail
} from 'utility';

import CardCS from './CardCS';
import Note from './Note';
import CommentList from './CommentList';
import AddImageComment from './AddImageComment';
import GiaoViec from './GiaoViec';

import {
  remove_comment_api,
  send_note_api,
  send_status_api,
  send_ly_do_bo_qua_api,
  mo_lai_don_api
} from './Api';
import TableAll from './TableAll';
import ThongTinChuyenSai from './ThongTinChuyenSai';
import DonTrungLap from './DonTrungLap';
import EditComment from './EditComment';
import TransactionID from './TransactionID';
import TableAllHoanTra from './TableAllHoanTra';
import ThongTinHoanTra from './ThongTinHoanTra';
import LichSuGiaoViec from './LichSuGiaoViec';
import QuyTrinh from './QuyTrinh';
import TruyThu from './TruyThu';
import CommentNoiBo from './CommentNoiBo';
import CardImage from './CardImage';
import KhopLenh from './KhopLenh';

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
});
const ImageBase64 = ({ data }) => <img style={{ maxWidth: "500px" }} src={`${data}`} />
const renderType = (base64Data) => base64Data.substring("data:image/".length, base64Data.indexOf(";base64"));
class Issue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: '',
      trang_thai: null,
      edit_comment: false,
      access_token: null,
      user_id: null,

      comment_image_base64: null,
      comment_image_name: null,

      ticket_id: this.props.match.params.id,
      loading: true,

      data: null,
      data_success: false,
      role_admin: null,
      ly_do_bo_qua: null
    };
  }

  check_login = async () => {
    let login_data = localStorage.getItem(CONST_DATA.localStorage_login);
    // console.log(login_data);

    if (login_data == null) {
      this.props.history.push(CONST_DATA.router_sign_in);
    } else {
      login_data = JSON.parse(login_data);
      this.setState({
        access_token: login_data.access_token,
        user_id: login_data.user_id,
        role_admin: login_data.role
      });
    }
  };

  async componentDidMount() {
    await this.check_login();
    await this.get_ticket_detail(this.state.ticket_id);
  }

  get_ticket_detail = async ticket_id => {
    this.setState({ loading: true })
    if (ticket_id != null) {
      const body_req = {
        user_id: this.state.user_id,
        ticket_id: ticket_id,
        access_token: this.state.access_token
      };

      Axios.post(CONST_DATA.get_ticket_admin_ticket_id, body_req)
        .then(response => {
          if (response.data.status === 'success') {
            console.log(response.data.data)
            this.setState({
              data_success: true,
              data: response.data.data,
              loading: false
            });
          }
        })
        .catch(error => {
          console.log(error);
          this.setState({ loading: false })
        });
    }
  };

  checkBase64 = (data) => {
    try {
      return ['png', 'jpeg', 'jpg'].includes(renderType(data))
    } catch (error) {
      return false;
    }
  }

  send_note = note => {
    const body_req = {
      user_id: this.state.user_id,
      ticket_id: this.state.ticket_id,
      note: note,
      access_token: this.state.access_token
    };
    send_note_api(body_req, () => window.location.reload());
  };

  render_ticket_detail = (classes, item, index) => {
    if (item.data && typeof item.data === 'string' && item.data.includes('https://finhay.s3-ap-southeast-1.amazonaws.com')) {
      return <Paper key={index} variant="outlined">
        <img style={{ maxHeight: "400px" }} src={item.data} />
      </Paper>
    }
    if (item.type === 'image_upload') {
      return <CardImage key={index} data={item.data} />;
    }
    else {
      return (
        <>
          {this.checkBase64(item.data) ? <ImageBase64 data={item.data} ></ImageBase64> : <>
            <Typography gutterBottom>
              {item.type}
              {' : '}
              {item.data}
            </Typography>
          </>}

        </>
      );
    }
  };

  box_status = (text, status) => {
    return (
      <Button
        style={{ margin: 10 }}
        color={this.state.trang_thai == status ? 'primary' : 'default'}
        variant="contained"
        onClick={() => this.setState({ trang_thai: status })}>
        {text}
      </Button>
    );
  };

  box_ly_do_bo_qua = (text, ly_do_bo_qua) => {
    return (
      <Button
        style={{ margin: 10 }}
        color={this.state.ly_do_bo_qua == ly_do_bo_qua ? 'primary' : 'default'}
        variant="contained"
        onClick={() => this.setState({ ly_do_bo_qua: ly_do_bo_qua })}>
        {text}
      </Button>
    );
  };

  send_status = status_id => {
    const body_req = {
      user_id: this.state.user_id,
      ticket_id: this.state.ticket_id,
      status_id: status_id,
      access_token: this.state.access_token
    };

    send_status_api(body_req, () => window.location.reload());

    if (status_id == 200) {
      const body_req_bo_qua = {
        user_id: this.state.user_id,
        ticket_id: this.state.ticket_id,
        bo_qua_id: this.state.ly_do_bo_qua,
        access_token: this.state.access_token
      };
      send_ly_do_bo_qua_api(body_req_bo_qua, () => window.location.reload());
    }
  };

  mo_lai_don = () => {
    const body_req = {
      user_id: this.state.user_id,
      ticket_id: this.state.ticket_id,
      access_token: this.state.access_token
    };
    mo_lai_don_api(body_req, () => window.location.reload());
  };

  render_button_status = () => {
    if (this.state.data.status_id == 200 || this.state.data.status_id == 400) {
      return null;
    } else {
      return (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}>
          <Typography variant="h4">Chuyển trạng thái</Typography>
          {this.box_status('Thành công', 400)}
          {this.box_status('Chờ bổ sung thông tin', 300)}
          {this.box_status('Bỏ qua', 200)}
        </Box>
      );
    }
  };

  render_status = classes => {
    if (this.state.data.status_id == 10 || this.state.data.status_id == 350) {
      return (
        <Button
          style={{ margin: 10 }}
          color="primary"
          variant="contained"
          onClick={() => this.send_status(100)}>
          Đang giải quyết
        </Button>
      );
    } else {
      return (
        <Card
          style={{
            backgroundColor: 'rgb(187,222,251)',
            marginTop: 10,
            padding: 10
          }}>
          {this.render_button_status()}
          {this.state.trang_thai == 200 ? (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}>
              <Typography variant="h4">Lý do bỏ qua</Typography>
              {this.box_ly_do_bo_qua('Trùng đơn', 1)}
              {this.box_ly_do_bo_qua('Tự hoàn thành', 2)}
              {this.box_ly_do_bo_qua('Lý do khác', 3)}
            </Box>
          ) : null}
          {this.render_gui_trang_thai()}
        </Card>
      );
    }
  };

  box_xac_nhan_gui_trang_thai = () => {
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}>
        <Typography variant="h4">Xác nhận</Typography>
        <Button
          style={{ margin: 10 }}
          variant="contained"
          onClick={() => {
            this.send_status(this.state.trang_thai);
            this.setState({ trang_thai: null });
          }}>
          Gửi trạng thái
        </Button>
        <Button
          color="default"
          style={{ margin: 10 }}
          variant="contained"
          onClick={() => this.setState({ trang_thai: null })}>
          Hủy
        </Button>
      </Box>
    );
  };

  render_gui_trang_thai = () => {
    if (this.state.trang_thai != null) {
      if (this.state.trang_thai == 200) {
        if (this.state.ly_do_bo_qua != null) {
          return this.box_xac_nhan_gui_trang_thai();
        } else {
          return null;
        }
      } else {
        return this.box_xac_nhan_gui_trang_thai();
      }
    } else {
      return null;
    }
  };

  remove_comment = comment => {
    const body_req = {
      user_id: this.state.user_id,
      ticket_id: this.state.ticket_id,
      comment: comment,
      access_token: this.state.access_token
    };
    remove_comment_api(body_req, () => window.location.reload());
  };

  render_hoan_tra = () => {
    if (this.state.data.ticket_type == 'ĐỐI SOÁT') {
      if (
        // chi co cs moi dc chon don sai
        this.state.data.id_hoan_tra == null &&
        this.state.role_admin == CONST_DATA.ROLE_CS
      ) {
        return (
          <TableAllHoanTra
            ticket_id={this.state.ticket_id}
            access_token={this.state.access_token}
            user_id={this.state.user_id}
            refesh_page={() => window.location.reload()}
          />
        );
      }
      if (this.state.data.id_hoan_tra != null) {
        return (
          <ThongTinHoanTra
            ticket_id={this.state.ticket_id}
            id_hoan_tra={this.state.data.id_hoan_tra}
            access_token={this.state.access_token}
            user_id={this.state.user_id}
            refesh_page={() => window.location.reload()}
            role_admin={this.state.role_admin}
          />
        );
      }
    } else {
      return null;
    }
  };

  render_chuyen_tien_sai = () => {
    if (this.state.data.ticket_type == 'CHUYỂN TIỀN') {
      if (
        // chi co cs moi dc chon don sai
        this.state.data.id_chuyen_tien_sai == null &&
        (this.state.role_admin == CONST_DATA.ROLE_CS ||
          this.state.role_admin == CONST_DATA.ROLE_ADMIN) &&
        this.state.data.status_id != 200
      ) {
        return (
          <TableAll
            ticket_id={this.state.ticket_id}
            access_token={this.state.access_token}
            user_id={this.state.user_id}
            refesh_page={() => window.location.reload()}
          />
        );
      }
      if (this.state.data.id_chuyen_tien_sai != null) {
        return (
          <ThongTinChuyenSai
            ticket_id={this.state.ticket_id}
            id_chuyen_tien_sai={this.state.data.id_chuyen_tien_sai}
            access_token={this.state.access_token}
            user_id={this.state.user_id}
            refesh_page={() => window.location.reload()}
            status_id={this.state.data.status_id}
          />
        );
      }
    } else {
      return null;
    }
  };

  card_info = info => {
    return (
      <Card
        style={{
          backgroundColor: 'rgb(187,222,251)',
          marginTop: 10,
          padding: 10
        }}>
        {info.map((item, index) => (
          <Typography variant="h4" key={index} gutterBottom>
            {item}
          </Typography>
        ))}
      </Card>
    );
  };

  render_lydo_bo_qua = () => {
    if (this.state.data.bo_qua_id != null && this.state.data.status_id == 200) {
      return (
        <Typography variant="h4">
          {'Lý do bỏ qua: '}
          {bo_qua_detail(this.state.data.bo_qua_id)}
        </Typography>
      );
    }
  };

  render_khop_lenh = () => {
    if (
      (this.state.role_admin == CONST_DATA.ROLE_KE_TOAN_KHOP_LENH ||
        this.state.role_admin == CONST_DATA.ROLE_ADMIN) &&
      this.state.data.id_chuyen_tien_sai != null &&
      this.state.data.transaction_id == null &&
      this.state.data.ticket_type == 'CHUYỂN TIỀN' &&
      this.state.data.status_id != 10 &&
      this.state.data.status_id != 200 &&
      this.state.data.status_id != 400
    ) {
      return (
        <KhopLenh
          data={this.state.data}
          access_token={this.state.access_token}
          user_id={this.state.user_id}
          ticket_id={this.state.ticket_id}
          role_admin={this.state.role_admin}
        />
      );
    }
  };

  handleUpdateInfoBank = () => {

    this.setState({ loading: true })
    Axios({
      method: 'PUT',
      headers: { user_id: this.state.user_id, access_token: this.state.access_token },
      url: CONST_DATA.update_bank_info,
      data: {
        ticket_id: this.state.ticket_id
      }
    })
      .then(response => {
        this.setState({ loading: false });
        const result = response.data
        if (result.status === 'success') {

          // this.setState({
          //   data_success: true,
          //   data: response.data.data
          // });
          console.log(result);
          toast.success(`Cập nhật thông tin tài khoản thành công`)
        }
        else if (result.status === 'error') {
          toast.error(`Cập nhật không thành công: ${result.data}`)
        }
      })
      .catch(error => {
        this.setState({ loading: false });
      });
  }

  render_giao_dich_hoan_tra = () => {
    if (this.state.data.ticket_type == 'SỬA THÔNG TIN TÀI KHOẢN NGÂN HÀNG') {
      return (
        <TableSearchHoanTra
          data={this.state.data}
          access_token={this.state.access_token}
          user_id={this.state.user_id}
          ticket_id={this.state.ticket_id}
          role_admin={this.state.role_admin}
          id_hoan_tra={this.state.data.id_hoan_tra}
        />
      );
    }
  };



  render() {
    const { classes } = this.props;
    if (!this.state.loading) {
      return (
        <div>
          <Box
            display="flex"
            flex={1}
            style={{ margin: 30 }}
            flexDirection="row">
            <Box flex={1} style={{ margin: 10 }}>
              {this.state.data.ticket_type == 'CHUYỂN TIỀN' ? (
                <QuyTrinh
                  access_token={this.state.access_token}
                  admin_id={this.state.user_id}
                  role_admin={this.state.role_admin}
                  ticket_id={this.state.ticket_id}
                  quy_trinh={this.state.data.quy_trinh}
                />
              ) : null}

              <DonTrungLap
                access_token={this.state.access_token}
                admin_id={this.state.user_id}
                user_id={this.state.data.user_id}
                ticket_id={this.state.ticket_id}
              />
            </Box>
            <Box flex={2} style={{ margin: 10 }}>
              <Card style={{ padding: 10 }}>
                <Typography variant="h4" gutterBottom>
                  Chi tiết yêu cầu
                </Typography>
                {this.card_info([
                  this.state.ticket_id,
                  'Loại yêu cầu: ' + this.state.data.ticket_type,
                  'Trạng thái hiện tại: ' +
                  status_detail(this.state.data.status_id),
                  this.render_lydo_bo_qua(),
                  this.state.data.is_truy_thu ? 'Đơn dạng thuy thu' : null
                ])}

                {this.state.role_admin == CONST_DATA.ROLE_TU_VAN
                  ? null
                  : this.card_info([
                    'User id: ' + this.state.data.user_id,
                    this.state.data.user_profile == null
                      ? null
                      : 'SDT: ' + this.state.data.user_profile.phone_number,
                    this.state.data.user_profile == null
                      ? null
                      : 'Email: ' + this.state.data.user_profile.email
                  ])}

                {this.render_status(classes)}
                {this.state.role_admin == CONST_DATA.ROLE_ADMIN &&
                  this.state.data.status_id != 100 ? (
                  <div>
                    <Button
                      style={{ margin: 10 }}
                      color="primary"
                      variant="contained"
                      onClick={() => this.mo_lai_don()}>
                      Mở lại đơn - đang giải quyết
                    </Button>
                  </div>
                ) : null}

                <GiaoViec
                  data={this.state.data}
                  access_token={this.state.access_token}
                  user_id={this.state.user_id}
                  ticket_id={this.state.ticket_id}
                />
                <TruyThu
                  data={this.state.data}
                  access_token={this.state.access_token}
                  user_id={this.state.user_id}
                  ticket_id={this.state.ticket_id}
                />
                <CardContent>
                  {this.state.data.ticket_detail.map((item, index) => {
                    return this.render_ticket_detail(classes, item, index)
                  }
                  )}
                </CardContent>
                {this.state.data && this.state.data.ticket_type && this.state.data.ticket_type === "ĐỐI SOÁT" && this.state.data.ticket_detail.length > 4 && !!this.state.data.id_hoan_tra && this.state.data.status_id !== 200 && this.state.data.status_id !== 400 && <Button onClick={() => this.handleUpdateInfoBank()} style={{ marginLeft: "10px" }} variant="contained" color="primary">
                  Cập nhật thông tin ngân hàng
                </Button>}

                <TransactionID
                  data={this.state.data}
                  access_token={this.state.access_token}
                  user_id={this.state.user_id}
                  ticket_id={this.state.ticket_id}
                />
                {this.render_khop_lenh()}
              </Card>
            </Box>

            <Box flex={1} style={{ margin: 10 }}>
              <Card>
                <CardContent>
                  <Typography variant="h4" gutterBottom>
                    Comment
                  </Typography>
                  <EditComment
                    access_token={this.state.access_token}
                    user_id={this.state.user_id}
                    ticket_id={this.state.ticket_id}
                  />

                  <AddImageComment
                    access_token={this.state.access_token}
                    user_id={this.state.user_id}
                    ticket_id={this.state.ticket_id}></AddImageComment>
                  <CommentList
                    comment_log={this.state.data.comment_log}
                    edit_comment={this.state.edit_comment}
                    remove_comment={item =>
                      this.remove_comment(item)
                    }></CommentList>

                  {this.state.role_admin == 0 ? (
                    <Button
                      color="default"
                      style={{ margin: 10 }}
                      variant="contained"
                      onClick={() =>
                        this.setState({
                          edit_comment: !this.state.edit_comment
                        })
                      }>
                      Chỉnh sửa comment
                    </Button>
                  ) : null}
                </CardContent>
              </Card>
            </Box>

            <Box flex={0.75} style={{ margin: 10 }}>
              <Card>
                <CardContent>
                  <CommentNoiBo
                    data={this.state.data}
                    access_token={this.state.access_token}
                    user_id={this.state.user_id}
                    ticket_id={this.state.ticket_id}
                  />

                  {/* <Typography variant="h4">Ghi chú</Typography> */}
                  {this.state.data.note != null
                    ? this.state.data.note.map(item => (
                      <Typography>{item}</Typography>
                    ))
                    : null}

                  <Note
                    note={this.state.data.note}
                    send_note={note => this.send_note(note)}></Note>

                  <Typography variant="h4" gutterBottom>
                    Lịch sử thay đổi trạng thái
                  </Typography>
                  {this.state.data.status_log.map((item, index) => (
                    <CardCS
                      top={status_detail(item.status_id)}
                      mid={item.full_name}
                      bottom={to_date_min(item.date)}
                    />
                  ))}
                </CardContent>
              </Card>

              {this.state.data.admin_assign_log == null ? null : (
                <LichSuGiaoViec
                  admin_assign_log={this.state.data.admin_assign_log}
                />
              )}
            </Box>
          </Box>
          <Box style={{ margin: 50 }}>{this.render_chuyen_tien_sai()}</Box>

          <Box style={{ margin: 50 }}>{this.render_hoan_tra()}</Box>
          {/* <Box style={{ margin: 50 }}>{this.render_giao_dich_hoan_tra()}</Box> */}
        </div>
      );
    } else {
      return <CircularProgress style={{ marginTop: "20px" }} ></CircularProgress>;
    }
  }
}
export default withStyles(styles)(Issue);
