import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 3
  },
  title: {
    color: 'white',
    fontSize: 25
  },
  logo: {
    maxHeight: '30px',
    marginLeft: '30px'
  }
}));

const Topbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="white"
      position="fixed">
      <Toolbar>
        <RouterLink to="/">
          <img
            src="https://app.finhay.com.vn/images/logo.png"
            alt="logo"
            className={clsx(classes.logo)}
          />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
