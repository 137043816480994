import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import {
  UserList as UserListView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  Admin,
  Admin0,
  Detail,
  Ketoan,
  NewComment,
  KetoanHoantra,
  Cmt,
  DoiSoatEKyc,
  DanhSachNguoiDung
} from './views';
import CONST_DATA from 'constant';
import Tool from 'views/Tool';

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to={CONST_DATA.router_viec_cua_toi} />

      <RouteWithLayout
        component={(props) => (
          <UserListView {...props} type_get="viec_cua_toi" />
        )}
        exact
        layout={MainLayout}
        path={CONST_DATA.router_viec_cua_toi}
      />

      <RouteWithLayout
        component={(props) => <UserListView {...props} type_get="all" />}
        exact
        layout={MainLayout}
        path={CONST_DATA.router_all}
      />

      <RouteWithLayout
        component={(props) => <DoiSoatEKyc {...props} />}
        exact
        layout={MainLayout}
        path={CONST_DATA.router_doi_soat_ekyc}
      />
      <RouteWithLayout
        component={(props) => <UserListView {...props} type_get="can_xu_ly" />}
        exact
        layout={MainLayout}
        path={CONST_DATA.router_can_xu_ly}
      />

      <RouteWithLayout
        component={(props) => (
          <UserListView {...props} type_get="dang_giai_quyet" />
        )}
        exact
        layout={MainLayout}
        path={CONST_DATA.router_dang_giai_quyet}
      />

      <RouteWithLayout
        component={Detail}
        layout={MinimalLayout}
        exact
        path={CONST_DATA.router_detail + "/:id"}
      />

      <RouteWithLayout
        component={NewComment}
        layout={MainLayout}
        exact
        path={CONST_DATA.router_new_comment}
      />

      <RouteWithLayout
        component={Tool}
        layout={MainLayout}
        exact
        path={CONST_DATA.router_tool}
      />

      <RouteWithLayout
        component={Cmt}
        layout={MainLayout}
        exact
        path={CONST_DATA.router_cmt}
      />

      <RouteWithLayout
        component={Cmt}
        layout={MainLayout}
        exact
        path={CONST_DATA.router_cmt + "/:user_id"}
      />

      <RouteWithLayout
        component={Ketoan}
        layout={MainLayout}
        exact
        path={CONST_DATA.router_ketoan}
      />

      <RouteWithLayout
        component={KetoanHoantra}
        layout={MainLayout}
        exact
        path={CONST_DATA.router_ketoan_hoan_tra}
      />

      <RouteWithLayout
        component={Admin0}
        exact
        layout={MainLayout}
        path={CONST_DATA.router_admin_0}
      />

      <RouteWithLayout
        component={DanhSachNguoiDung}
        exact
        layout={MainLayout}
        path={CONST_DATA.router_list_user}
      />
      <RouteWithLayout
        component={Admin}
        exact
        layout={MainLayout}
        path={CONST_DATA.router_admin}
      />

      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path={CONST_DATA.router_sign_in}
      />

      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path={CONST_DATA.router_not_found}
      />
      <Redirect to={CONST_DATA.router_not_found} />
    </Switch>
  );
};

export default Routes;
