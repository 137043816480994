import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Card, Typography } from '@material-ui/core';


const styles = theme => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    backgroundColor: 'rgb(187,222,251)'
  }
});

class Issue extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;

    return (
      <Card className={classes.root}>
        <Typography variant="h5" component="h2">
          {this.props.top}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {this.props.mid}
        </Typography>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom>
          {this.props.bottom}
        </Typography>
        {this.props.extra_data}
      </Card>
    );
  }
}

export default withStyles(styles)(Issue);
