import React, { Component } from 'react';
import {
    Button,
    FormControl,
    FormGroup,
    Checkbox,
    FormControlLabel,
    Card,
    Box,
    Typography,
    TextField
} from '@material-ui/core';
import CONST_DATA from 'constant';
import Axios from 'axios';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import { list_check_fail } from './data';

class UpdateUserInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ly_do_reject: 0,
            reject: false,
            accept: false,
            deg_rotate: 0,
            list_check_fail: list_check_fail,

            is_accept: null,

            id_name: null,
            id_number: null,
            id_dob: null,
            id_address: null,
            id_issue_address: null,
            id_issue_date: null,
            data_true_id: null,
            unrender:false,
        };
    }

    componentDidMount() {
        if(this.props.data_cmt){
            let { full_name, identity_number, dob, id_issue_address, id_issue_date, address } = this.props.data_cmt;
            this.setState({
                id_name: full_name,
                id_number: identity_number,
                id_dob: dob,
                id_address: address,
                id_issue_date,
                id_issue_address,
                data_true_id: this.props.data_true_id
            })
        }
        else{
            alert("không có dữ liệu")
            this.setState({
                unrender:true
            })
        }
        
    }

    render_img = src_link => {
        let max_h = 400;
        return (
            <div style={{ maxHeight: max_h, maxWidth: max_h }}>
                <img
                    src={src_link}
                    style={{
                        transform: 'rotate(' + this.state.deg_rotate + 'deg)',
                        maxHeight: max_h,
                        maxWidth: max_h
                    }}
                    alt=""
                />
            </div>
        );
    };

    render_img_selfie = src_link => {
        let max_h = 150;
        return (
            <div style={{ height: max_h, width: max_h }}>
                <img src={src_link} style={{ maxHeight: max_h, maxWidth: max_h }} alt="" />
            </div>
        );
    };

    onChangeList_fail = event => {

        let pre_list_check = this.state.list_check_fail;
        let index_of_item = this.state.list_check_fail.findIndex(
            a => a.title === event.target.name
        );
        pre_list_check[index_of_item].check = event.target.checked;
        this.setState({ list_check_fail: pre_list_check });
    };

    card_text = (label, value, onChange, key) => {
        const isNotMatchTrueId = this.state.data_true_id && this.state.data_true_id[key] && this.state.data_true_id[key].toUpperCase() !== value.toUpperCase();
        // console.log({ value, trueId: this.state.data_true_id[key] })
        return (
            <div
                style={{
                    margin: 5,
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                <Typography style={{ flex: 1 }}>{label}</Typography>
                <TextField
                    style={{ flex: 3 }}
                    id="outlined-basic"
                    variant="outlined"
                    fullWidth
                    value={value}
                    onChange={event => onChange(event.target.value)}
                    helperText={isNotMatchTrueId ? `Thông tin không khớp với True ID: ${this.state.data_true_id[key]}` : null}
                    variant={isNotMatchTrueId ? 'filled' : 'standard'}
                />
            </div>
        );
    };

    render_accept = () => {
        return (
            <Card
                style={{
                    margin: 10,
                    padding: 10,
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                {this.card_text('Họ và tên', this.state.id_name, value =>
                    this.setState({ id_name: value }), 'name'
                )}
                {this.card_text('Số CMTND/CCCD hộ chiếu', this.state.id_number, value =>
                    this.setState({ id_number: value }), 'id_number'
                )}
                {this.card_text('Ngày sinh', this.state.id_dob, value =>
                    this.setState({ id_dob: value }), 'dob'
                )}
                {this.card_text('Địa chỉ', this.state.id_address, value =>
                    this.setState({ id_address: value }), 'id_address'
                )}
                {this.card_text('Ngày cấp', this.state.id_issue_date, value =>
                    this.setState({ id_issue_date: value }), 'given_date'
                )}
                {this.card_text('Nơi cấp', this.state.id_issue_address, value =>
                    this.setState({ id_issue_address: value }), 'given_place'
                )}
            </Card>
        );
    };

    render_rejcet = () => {
        return (
            <Card
                style={{
                    margin: 10,
                    padding: 10,
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                <FormControl required component="fieldset">
                    <FormGroup>
                        {this.state.list_check_fail.map(item => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={item.check}
                                        onChange={event => this.onChangeList_fail(event)}
                                        name={item.title}
                                    />
                                }
                                label={item.title}
                            />
                        ))}
                    </FormGroup>
                </FormControl>
            </Card>
        );
    };

    render_cs_confirm = () => {
        if (this.state.is_accept) {
            if (
                this.state.id_number != null &&
                this.state.id_name != null &&
                this.state.id_number != '' &&
                this.state.id_name != ''
            ) {
                return (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                            this.props.handleAccept({
                                identity_number: this.state.id_number,
                                full_name: this.state.id_name,
                                dob: this.state.id_dob,
                                address: this.state.id_address,
                                id_issue_date: this.state.id_issue_date,
                                id_issue_address: this.state.id_issue_address
                            })
                        }>
                        Xác nhận đổi thông tin người dùng và gửi thông báo
                    </Button>
                );
            }
        }

        // if (this.state.is_accept == false) {
        //     return (
        //         <Button
        //             variant="contained"
        //             onClick={() => {
        //                 let data = '';
        //                 this.state.list_check_fail.map(item => {
        //                     if (item.check) {
        //                         data = data + '- ' + item.title + '.</br>';
        //                     }
        //                 });
        //                 this.props.send_reject(data);
        //             }}
        //             style={{ backgroundColor: 'rgb(244,67,54)', color: 'white' }}>
        //             Xác nhận thông tin sai và gửi thông báo
        //         </Button>
        //     );
        // }
    };

    render() {
        return (
            <div style={{display: this.state.unrender ? 'none' : 'block' }}>
                <Card style={{ margin: 10, padding: 10 }}>
                    <Box style={{ margin: 10, display: 'flex', flexDirection: 'row' }}>
                        {this.render_img_selfie(this.props.data.result.selfie)}
                        {this.render_img(this.props.data.result.front)}
                        {this.render_img(this.props.data.result.back)}
                    </Box>

                    <Button
                        onClick={() =>
                            this.setState({ deg_rotate: this.state.deg_rotate + 90 })
                        }>
                        <RotateRightIcon />
                    </Button>
                </Card>

                <div>
                    <Button
                        variant="contained"
                        color="secondary"
                        style={{ marginLeft: "11px" }}
                        onClick={() => this.setState({ is_accept: true })}>
                        Accept
          </Button>
          <Button
                variant="contained"
                style={{
                  backgroundColor: "rgb(244,67,54)",
                  color: "white",
                  marginLeft: "10px",
                }}
                // onClick={() => this.setState({ is_accept: false })}
                onClick={() =>
                  this.props.send_confirm_selfie(0, this.state.ly_do_reject)
                }
              >
                Reject
              </Button>

                    {/* <Button
                        variant="contained"
                        style={{ backgroundColor: 'rgb(244,67,54)', color: 'white' }}
                        onClick={() => this.setState({ is_accept: false })}>
                        Reject
          </Button> */}
                    {this.state.is_accept == true ? this.render_accept() : null}
                    {this.state.is_accept == false ? this.render_rejcet() : null}

                    {this.render_cs_confirm()}
                </div>
            </div>
        );
    }
}

export default UpdateUserInfo;
