import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Card, Typography, TextField } from '@material-ui/core';
import Axios from 'axios';
import CONST_DATA from 'constant';
import CardCS from './CardCS';
import { to_date_min } from 'utility';

class CommentNoiBo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: ''
    };
  }

  componentDidMount() { }

  send_comment = () => {
    const body_req = {
      user_id: this.props.user_id,
      access_token: this.props.access_token,
      ticket_id: this.props.ticket_id,
      comment: this.state.comment
    };

    Axios.post(CONST_DATA.comment_noi_bo, body_req)
      .then(response => {
        if (response.data.status == 'success') {
          window.location.reload();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  render_comment = () => {
    return (
      <div>
        {this.props.data.comment_noi_bo.map((item, index) => (
          <CardCS
            key={index}
            top={item.full_name}
            mid={item.comment}
            bottom={to_date_min(item.date)}
          />
        ))}
      </div>
    );
  };

  render() {
    return (
      <div>
        <Typography variant="h4">Comment nội bộ</Typography>
        <TextField
          multiline={true}
          fullWidth
          type="text"
          variant="outlined"
          placeholder="message"
          value={this.state.comment}
          onChange={event => this.setState({ comment: event.target.value })}
        />
        {this.state.comment == '' ? null : (
          <Button
            style={{ margin: 10 }}
            color="primary"
            variant="contained"
            onClick={() => this.send_comment()}>
            Gửi
          </Button>
        )}
        {this.props.data.comment_noi_bo == null ? null : this.render_comment()}
      </div>
    );
  }
}

export default CommentNoiBo;
