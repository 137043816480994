import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button, Card } from '@material-ui/core';

import CONST_DATA from 'constant';
import Axios from 'axios';
import { status_hoan_tra } from '../../utility';

const styles = theme => ({
  root: {}
});

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: this.props.access_token,
      user_id: this.props.user_id,
      data: this.props.data,
      data_success: false
    };
  }

  get_data = () => {
    const body_req = {
      user_id: this.state.user_id,
      access_token: this.state.access_token,
      id_hoan_tra: this.props.id_hoan_tra
    };

    Axios.post(CONST_DATA.ketoan_hoan_tra_get_by_id, body_req)
      .then(response => {
        if (response.data.status == 'success') {
          let data = response.data.data || {}
          this.setState({
            data_success: true,
            data
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.get_data();
  }

  cs_undo_confirm = () => {
    const body_req = {
      user_id: this.state.user_id,
      access_token: this.state.access_token,
      id_hoan_tra: this.props.id_hoan_tra,
      ticket_id: this.props.ticket_id
    };

    Axios.post(CONST_DATA.ketoan_hoan_tra_cs_undo_match, body_req)
      .then(response => {
        if (response.data.status == 'success') {
          this.props.refesh_page();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  send_status = status_id => {
    const body_req = {
      user_id: this.state.user_id,
      access_token: this.state.access_token,
      status_id: status_id,
      id_hoan_tra: this.props.id_hoan_tra
    };

    Axios.post(CONST_DATA.ketoan_hoan_tra_change_status, body_req)
      .then(response => {
        if (response.data.status == 'success') {
          this.props.refesh_page();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  button_change_status = (status_id, text) => {
    return (
      <Button
        style={{ margin: 10 }}
        variant="outlined"
        size="small"
        color="primary"
        onClick={() => this.send_status(status_id)}>
        {text}
      </Button>
    );
  };

  render() {
    const { classes } = this.props;
    if (this.state.data_success) {
      const row = this.state.data;

      return (
        <Card style={{ padding: 20, margin: 10 }}>
          {(this.state.data.status_id == 300 ||
            this.state.data.status_id == 200) ? null : (
            <Button
              style={{ margin: 10, backgroundColor: 'red', color: 'white' }}
              onClick={() => this.cs_undo_confirm()}
              variant="outlined"
              size="small"
              color="primary">
              Xóa lựa chọn
            </Button>
          )}

          <Typography variant="h4" style={{ marginBottom: 10 }}>
            Thông tin hoàn trả
          </Typography>
          <Typography variant="h4" style={{ marginBottom: 10 }}>
            Trạng thái: {status_hoan_tra(row.status_id)}
          </Typography>
          <Typography>
            {'Ngày: '}
            {row.date}
          </Typography>
          <Typography>
            {'Số tiền: '}
            {row.so_tien.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </Typography>
          <Typography>
            {' '}
            {'Mã rút tiền: '} {row.ma_rut_tien}
          </Typography>
          <Typography>
            {'Diễn giải: '}
            {row.dien_giai}
          </Typography>
          <Typography>
            {'Mã hoàn trả: '}
            {row.id}
          </Typography>
          <Typography>
            {'Mã case : '} {row.ma_case}
          </Typography>
          <Typography>
            {row.note == null ? null : 'Ghi chú: ' + row.note.toString()}
          </Typography>

          {!this.props.id_hoan_tra && this.state.data.status_id !== 300 && this.button_change_status(100, 'Đã thông báo')}
          {!this.props.id_hoan_tra && this.state.data.status_id !== 300 && this.button_change_status(200, 'Đã cập nhật')}

          {this.props.role_admin == CONST_DATA.ROLE_KE_TOAN
            ? this.button_change_status(300, 'Đã chuyển tiền')
            : null}
        </Card>
      );
    } else {
      return null;
    }
  }
}

export default withStyles(styles)(User);
