import React, { Component } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import CONST_DATA from 'constant';
import TableAll from './TableAll';

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  }
});

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: null,
      user_id: null,
      data_success: false,
      data: null,
      data_ticket: null,
      data_ticket_success: false,
      role_admin: null
    };
  }

  check_login = () => {
    let login_data = localStorage.getItem(CONST_DATA.localStorage_login);

    if (login_data == null) {
      this.props.history.push(CONST_DATA.router_sign_in);
    } else {
      login_data = JSON.parse(login_data);
      this.setState({
        access_token: login_data.access_token,
        user_id: login_data.user_id,
        role_admin: login_data.role
      });
    }
  };

  async componentDidMount() {
    await this.check_login();
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            {this.state.access_token != null ? (
              <TableAll
                access_token={this.state.access_token}
                user_id={this.state.user_id}
                refesh_page={() => window.location.reload()}
                role_admin={this.state.role_admin}
              />
            ) : null}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(User);
