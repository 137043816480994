import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import Switch from "@material-ui/core/Switch";

import CONST_DATA from "constant";
import Axios from "axios";


const columns = [
  { label: "Họ tên", minWidth: 10 },
  { label: "Tên đăng nhập", minWidth: 10 },
  { label: "Trạng Thái", minWidth: 1 },
  { label: "Vai trò", minWidth: 1 },
  { label: "Thay đổi trạng thái kích hoạt", minWidth: 1 },
];

const styles = (theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  container: {
    maxHeight: "70vh",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 170,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});

class TableUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: this.props.access_token,
      user_id: this.props.user_id,
      data: this.props.data,
      data_success: false,
      open_delete: false,
      id_delete: null,
      data_delete: null,

      note_change: null,
      page: 1,
      totalPage: 50,
      limit: 20,
      checked: true,
    };
  }

  componentDidMount() {
    console.log(this.state.data)
  }

  

  checkRoleDisplay = (role) => {
    let roleDisplay = "";
    if (role == 0) {
      roleDisplay = "Admin";
    }
    if (role == 100) {
      roleDisplay = "CS";
    }
    if (role == 50) {
      roleDisplay = "Kế toán";
    }
    if (role == 55) {
      roleDisplay = "Kế toán khớp lệnh";
    }
    if (role == 70) {
      roleDisplay = "Tư vấn";
    }
    if (role == 1000) {
      roleDisplay = "test";
    }
    if (role == 10000) {
      roleDisplay = "kyc";
    }
    return roleDisplay;
  };

  handleChange = (id, currentStatus) => {
   
    const headers = {
      access_token: this.state.access_token,
      user_id: this.state.user_id,
    };
    if (currentStatus == false) {
      Axios.put(`${CONST_DATA.change_status_user}${id}/active`,{},{headers:headers})
        .then((res) => {
          if (res.data.status == "success") {
             window.location.reload();
           }
        })
        .catch((err) => {
        console.log(err)
      })
    }
     if (currentStatus == true) {
       Axios.put(
         `${CONST_DATA.change_status_user}${id}/inactive`,
         {},
         { headers: headers }
       )
         .then((res) => {
           if (res.data.status == "success") {
             window.location.reload();
           }
         })
         .catch((err) => {
           console.log(err);
         });
     }
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.data.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{item.full_name}</TableCell>
                      <TableCell>{item.user_name}</TableCell>
                      <TableCell>
                        {item.is_active === true
                          ? "Đang kích hoạt"
                          : "Không kích hoạt"}
                      </TableCell>
                      <TableCell>{this.checkRoleDisplay(item.role)}</TableCell>
                      <TableCell>
                        <Switch
                          checked={item.is_active}
                          onChange={() =>
                            this.handleChange(item.user_id, item.is_active)
                          }
                          color="primary"
                          name="checked"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <div className={classes.root}>
            <Pagination count={10}/>
          </div> */}
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(TableUser);
