import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    TextField,
    Box,
    Card
} from '@material-ui/core';

import CONST_DATA from 'constant';
import Axios from 'axios';
import { toast } from 'react-toastify';

const columns = [
    { label: 'Select', minWidth: 10 },
    { label: 'User Id', minWidth: 10 },
    { label: 'Ngày', minWidth: 10 },
    { label: 'Số tiền', minWidth: 10 },
    { label: 'Mã rút tiền', minWidth: 10 },
    { label: 'Diễn giải', minWidth: 10 },
    { label: 'ID', minWidth: 10 },
    { label: 'Mã case', minWidth: 10 },
    { label: 'CS', minWidth: 10 },
    { label: 'Ghi chú', minWidth: 10 }
];

const styles = theme => ({
    root: {},
    container: {
        maxHeight: '70vh'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 170
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    }
});

class TabelSearchHoanTra extends Component {
    constructor(props) {
        super(props);
        this.state = {
            access_token: this.props.access_token,
            user_id: this.props.user_id,
            data: this.props.data,
            data_success: false,
            open_delete: false,
            id_delete: null,
            data_delete: null,
            note_change: null,
            hoantra: null
        };
    }



    search_data = search_key => {
        const body_req = {
            user_id: this.props.user_id,
            access_token: this.props.access_token,
            search_key: search_key || ''
        };

        Axios.post(CONST_DATA.ketoan_hoan_tra_search_item, body_req)
            .then(response => {
                if (response.data.status === 'success') {
                    this.setState({
                        data_success: true,
                        data: response.data.data
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    notify = (type, message) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            default:
                break;
        }

    }

    get_hoan_tra_by_id = () => {
        const body_req = {
            user_id: this.props.user_id,
            access_token: this.props.access_token,
            id_hoan_tra: this.props.id_hoan_tra
        };

        Axios.post(CONST_DATA.ketoan_hoan_tra_get_by_id, body_req)
            .then(response => {
                if (response.data.status === 'success') {
                    this.setState({
                        data_success: true,
                        data: [response.data.data]
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    }




    componentDidMount() {
        if (this.props.id_hoan_tra) {
            this.get_hoan_tra_by_id()
        }
        else {
            this.search_data();
        }

    }

    handleConfim(data) {
        const body_req = {
            access_token: this.props.access_token,
            user_id: this.props.user_id,
            ticket_id: this.props.ticket_id,
            id_hoan_tra: data.id
        };

        if (this.props.id_hoan_tra) {
            Axios.post(CONST_DATA.cs_un_match_tk_ngan_hang, body_req)
                .then(response => {
                    const result = response.data;
                    if (result.status === 'success') {
                        this.setState({
                            response_data: result.data.message
                        });
                        this.notify('success', 'Hủy nối đơn thành công.')
                        setTimeout(() => {
                            window.location.reload()
                        }, 1500);
                    }
                    else {
                        this.notify('error', result.data.message)
                    }
                })
                .catch(error => {
                    this.notify('error', error.message)
                    console.log(error);
                });
        }
        else {
            Axios.post(CONST_DATA.cs_match_tk_ngan_hang, body_req)
                .then(response => {
                    const result = response.data;
                    if (result.status === 'success') {
                        this.setState({
                            response_data: result.data.message
                        });
                        this.notify('success', 'Nối đơn thành công.')
                        setTimeout(() => {
                            window.location.reload()
                        }, 1500);
                    }
                    else {
                        this.notify('error', result.data.message)
                    }
                })
                .catch(error => {
                    this.notify('error', error.message)
                    console.log(error);
                });
        }


    }






    render() {
        const { classes } = this.props;
        return (
            <div>
                <Box>
                    {!this.props.id_hoan_tra && <Card
                        style={{
                            flex: 1,
                            margin: 10,
                            flexDirection: 'row',
                            display: 'flex'
                        }}
                    >
                        <TextField
                            placeholder="Từ khóa tìm kiếm"
                            id="outlined-basic"
                            variant="outlined"
                            fullWidth
                            type="text"
                            className={classes.textField}
                            value={this.state.search_key}
                            onChange={event =>
                                this.setState({ search_key: event.target.value })
                            }
                        />
                        <Button
                            onClick={() => this.search_data(this.state.search_key)}
                            variant="outlined"
                            size="small"
                            color="primary">
                            Tìm kiếm
               </Button>
                    </Card>}

                </Box>
                <Paper className={classes.root}>
                    <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map(column => {
                                        return (
                                            <TableCell
                                                key={column.label}
                                                align={column.align ? column.align : 'left'}
                                                style={{ minWidth: column.minWidth }}>
                                                {column.label}
                                            </TableCell>
                                        )
                                    }
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.data_success
                                    ? this.state.data.map((row, index) => {
                                        return (
                                            <TableRow key={row.ma_rut_tien} hover tabIndex={-1}>
                                                <TableCell>
                                                    <Button
                                                        disabled={this.props.role_admin !== CONST_DATA.ROLE_CS}
                                                        onClick={() =>
                                                            this.handleConfim(row)
                                                        }
                                                        variant="outlined"
                                                        size="small"
                                                        color="primary">
                                                        {this.props.id_hoan_tra ? 'Delete' : 'Select'}
                                                    </Button>
                                                </TableCell>
                                                <TableCell>{row.user_id ? row.user_id : null}</TableCell>
                                                <TableCell>{row.date}</TableCell>
                                                <TableCell>
                                                    {row.so_tien
                                                        .toString()
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                </TableCell>

                                                <TableCell>{row.ma_rut_tien}</TableCell>
                                                <TableCell>{row.dien_giai}</TableCell>
                                                <TableCell>{row.id}</TableCell>
                                                <TableCell>{row.ma_case}</TableCell>
                                                <TableCell>{row.cs_match ? 'yes' : 'no'}</TableCell>
                                                <TableCell>{row.note ? row.note : null}</TableCell>

                                            </TableRow>
                                        )
                                    })
                                    : null}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
        );
    }
}

export default withStyles(styles)(TabelSearchHoanTra);
