import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button
} from '@material-ui/core';
import CONST_DATA from 'constant';
import SearchText from './SearchText';
import Axios from 'axios';

const columns = [
  { label: 'CS confirm', minWidth: 10 },
  { label: 'ID', minWidth: 10 },
  { label: 'Ngày', minWidth: 10 },
  { label: 'Diễn giải', minWidth: 10 },
  { label: 'Số bút toán', minWidth: 10 },
  { label: 'Số tiền', minWidth: 10 },
  { label: 'Bank', minWidth: 10 },
  { label: 'Mã case', minWidth: 10 },
  { label: 'CS Xác nhận', minWidth: 10 },
  { label: 'Ghi chú', minWidth: 10 }
];

const styles = theme => ({
  root: {},
  container: {
    maxHeight: '70vh'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 170
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
});

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: this.props.access_token,
      user_id: this.props.user_id,
      data: this.props.data,
      data_success: false,
      search_key: '',

      is_loading: false
    };
  }

  get_data = search_key => {
    const body_req = {
      user_id: this.state.user_id,
      access_token: this.state.access_token,
      search_key: search_key
    };

    this.setState({
      is_loading: true
    });

    Axios.post(CONST_DATA.ketoan_search_item, body_req)
      .then(response => {
        if (response.data.status === 'success') {
          this.setState({
            data_success: true,
            data: response.data.data,
            is_loading: false
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.get_data(this.state.search_key);
  }

  cs_confirm = (id_chuyen_tien_sai, ticket_id) => {
    const body_req = {
      user_id: this.state.user_id,
      access_token: this.state.access_token,
      id_chuyen_tien_sai: id_chuyen_tien_sai,
      ticket_id: ticket_id
    };

    Axios.post(CONST_DATA.ketoan_cs_confirm, body_req)
      .then(response => {
        if (response.data.status === 'success') {
          this.props.refesh_page();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <SearchText
          is_loading={this.state.is_loading}
          onClick={search_key => this.get_data(search_key)}
        />
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.data_success
                  ? this.state.data.map((row, index) => (
                    <TableRow hover tabIndex={-1}>
                      <TableCell>
                        <Button
                          onClick={() =>
                            this.cs_confirm(row.id, this.props.ticket_id)
                          }
                          variant="outlined"
                          size="small"
                          color="primary">
                          Select
                          </Button>
                      </TableCell>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.ngay}</TableCell>
                      <TableCell>{row.dien_giai}</TableCell>
                      <TableCell>{row.so_but_toan}</TableCell>
                      <TableCell>
                        {row.so_tien
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </TableCell>
                      <TableCell>{row.bank}</TableCell>
                      <TableCell>{row.ma_case}</TableCell>
                      <TableCell>{row.cs_confirm.toString()}</TableCell>
                      <TableCell>
                        {row.note === null ? null : row.note.toString()}
                      </TableCell>
                    </TableRow>
                  ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(User);
