import React, { Component } from 'react';
import { Button, Card, Typography } from '@material-ui/core';
import Axios from 'axios';
import CONST_DATA from 'constant';

class TruyThu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() { }

  add_truy_thu = is_truy_thu => {
    const body_req = {
      user_id: this.props.user_id,
      access_token: this.props.access_token,
      ticket_id: this.props.ticket_id,
      is_truy_thu: is_truy_thu
    };

    Axios.post(CONST_DATA.add_truy_thu, body_req)
      .then(response => {
        if (response.data.status === 'success') {
          window.location.reload();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    if (
      this.props.data.is_truy_thu == null &&
      this.props.data.ticket_type === 'CHUYỂN TIỀN' &&
      this.props.data.status_id !== 200
    ) {
      return (
        <Card
          style={{
            backgroundColor: 'rgb(187,222,251)',
            marginTop: 10,
            padding: 10,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}>
          <Typography variant="h5">Gắn tag truy thu</Typography>
          <Button
            style={{ margin: 10 }}
            color="primary"
            variant="contained"
            onClick={() => this.add_truy_thu(true)}>
            yes
          </Button>
          <Button
            onClick={() => this.add_truy_thu(false)}
            style={{ margin: 10 }}
            color="primary"
            variant="contained">
            no
          </Button>
        </Card>
      );
    } else {
      return null;
    }
  }
}

export default TruyThu;
