import React, { Component } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Button,
  Card
} from '@material-ui/core';
import CONST_DATA from 'constant';
import Axios from 'axios';
import { get_time_second } from 'utility';

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    margin: theme.spacing(1)
  },
  textField: {
    margin: theme.spacing(1)
  }
});

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ticket_id: null,
      new_user_id: null,
      password: null,
      admin_id: null,
      error_text: null
    };
  }

  render_send = () => {
    if (
      this.state.ticket_id != null &&
      this.state.new_user_id != null &&
      this.state.password != null
    ) {
      return (
        <Button
          style={{ margin: 10 }}
          color="primary"
          variant="contained"
          onClick={() => this.send_data()}>
          Xác nhận
        </Button>
      );
    } else {
      return null;
    }
  };

  send_data = () => {
    const body_req = {
      ticket_id: this.state.ticket_id,
      new_user_id: this.state.new_user_id,
      admin_id: this.props.admin_id,
      password: this.state.password
    };

    Axios.post(CONST_DATA.tao_ho_don, body_req)
      .then(response => {
        if (response.data.status == 'success') {
          window.location.reload();
        } else {
          this.setState({
            error_text: response.data.data
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <Card className={classes.root}>
        <Typography variant="h4">Tạo hộ đơn</Typography>
        <TextField
          multiline={true}
          className={classes.textField}
          fullWidth
          label="Mã đơn yêu cầu"
          type="text"
          variant="outlined"
          value={this.state.ticket_id}
          onChange={event => this.setState({ ticket_id: event.target.value })}
        />
        <TextField
          multiline={true}
          className={classes.textField}
          fullWidth
          label="UserID"
          type="number"
          variant="outlined"
          value={this.state.new_user_id}
          onChange={event => this.setState({ new_user_id: event.target.value })}
        />
        <input
          style={{ margin: 10, padding: 10 }}
          multiline={true}
          fullWidth
          placeholder="Password confirm"
          type="password"
          variant="outlined"
          value={this.state.password}
          onChange={event => this.setState({ password: event.target.value })}
        />
        <Typography>{this.state.error_text}</Typography>
        {this.render_send()}
      </Card>
    );
  }
}

export default withStyles(styles)(User);
