import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  FormControl,
  Select,
  MenuItem,
  Card,
  Typography
} from '@material-ui/core';
import { change_admin_api } from './Api';
import Axios from 'axios';
import CONST_DATA from 'constant';

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
});

class GiaoViec extends Component {
  constructor(props) {
    super(props);
    this.state = {
      all_admin_data: null,
      all_admin_success: false,
      admin_assign: this.props.data.admin_assign
    };
  }

  componentDidMount() {
    this.get_all_admin();
  }

  get_all_admin = () => {
    Axios.get(CONST_DATA.get_admin_giao_viec)
      .then(response => {
        if (response.data.status === 'success') {
          this.setState({
            all_admin_data: response.data.data,
            all_admin_success: true
          });
        }
      })
      .catch(error => {
      });
  };

  change_admin = admin_assign => {
    const body_req = {
      user_id: this.props.user_id,
      ticket_id: this.props.ticket_id,
      admin_assign: admin_assign,
      access_token: this.props.access_token
    };
    change_admin_api(body_req, () => window.location.reload());
  };

  render() {
    const { classes } = this.props;
    if (this.state.all_admin_success) {
      return (
        <Card
          style={{
            backgroundColor: 'rgb(187,222,251)',
            marginTop: 10,
            padding: 10,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}>
          <Typography variant="h4">Giao việc cho: </Typography>
          <FormControl className={classes.formControl} variant="outlined">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={
                this.state.admin_assign === null
                  ? null
                  : this.state.admin_assign.user_id
              }
              onChange={event => {
                this.change_admin(event.target.value);
              }}>
              {this.state.all_admin_data.map((item, index) => (
                <MenuItem key={index} value={item.user_id}>{item.full_name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Card>
      );
    } else {
      return null;
    }
  }
}

export default withStyles(styles)(GiaoViec);
