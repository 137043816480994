import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, CircularProgress } from '@material-ui/core';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  FormControl,
  Box,
  TextField
} from '@material-ui/core';

import {
  to_date_min,
  to_date_unix
} from 'utility';
import CONST_DATA from 'constant';
import Axios from 'axios';
import {
  Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
const columns = [
  { label: 'STT', minWidth: 10 },
  { label: 'ID_OCR', minWidth: 10 },
  { label: 'ID_Selfie_Compare', minWidth: 10 },

];

const styles = theme => ({
  root: {},
  container: {
    maxHeight: '70vh'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 170
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
});

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: this.props.access_token,
      user_id: this.props.user_id,
      data: this.props.data,
      data_success: false,
      loading: false,
      startDate: null,
      enDate: null
    };
  }



  get_data = () => {
    this.setState({
      loading: true
    });
    const body_req = {
      user_id: this.state.user_id,
      access_token: this.state.access_token,
    };
    if (this.state.startDate) {
      body_req.start_date = to_date_unix(this.state.startDate)
    }
    else {
      body_req.start_date = 1546448400
    }

    if (this.state.enDate) {
      body_req.end_date = to_date_unix(this.state.enDate)
    }
    else {
      body_req.end_date = 1615309200
    }

    Axios.post(CONST_DATA.doi_soat_ekyc, body_req)
      .then(response => {
        if (response.data.status === 'success') {
          this.setState({
            data_success: true,
            data: [response.data.data],
            loading: false
          });
        }
        else {
          this.setState({
            loading: false
          });
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({
          loading: false
        });
      });
  };

  componentDidMount() {
    this.get_data();
  }



  handleOnSearch = async () => {
    this.get_data()
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Box display="flex" alignItems="center" justifyContent="center">
          Từ
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              fullWidth
              className={classes.textField}
              value={this.state.startDate}
              onChange={event => {
                this.setState({ startDate: event.target.value });
              }}
              type="date"
            />

          </FormControl>
           Đến
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              fullWidth
              className={classes.textField}
              value={this.state.enDate}
              onChange={event => {
                this.setState({ enDate: event.target.value });
              }}
              type="date"
            />
          </FormControl>
          <Button
            style={{ margin: 10 }}
            variant="contained"
            color="primary"
            onClick={this.handleOnSearch}
          >
            <Typography style={{ color: 'white' }}>Tìm kiếm</Typography>
            <SearchIcon />
          </Button>
        </Box>
        {this.state.loading ? <CircularProgress></CircularProgress> : <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.length && columns.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.data_success
                  ? this.state.data.map((row, index) => (
                    <TableRow hover tabIndex={-1} key={index} >
                      <TableCell>
                        <span>
                          {index + 1}
                        </span>
                      </TableCell>
                      <TableCell>{row.count_ocr}</TableCell>
                      <TableCell>{row.count_selfie}</TableCell>

                    </TableRow>
                  ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>}

      </div>
    );
  }
}

export default withStyles(styles)(User);
