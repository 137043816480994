import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, TextField, TextareaAutosize } from '@material-ui/core';
import { get_time_second } from 'utility';
import { send_comment_api } from './Api';

class CommentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: null
    };
  }

  send_comment = text => {
    const body_req = {
      access_token: this.props.access_token,
      user_id: this.props.user_id,
      ticket_id: this.props.ticket_id,
      comment: text,
      date: get_time_second()
    };
    send_comment_api(body_req, () => window.location.reload());
  };

  render() {
    return (
      <Box display="flex" flex={1} flexDirection="column">
        <TextField
          multiline={true}
          fullWidth
          name="email"
          type="text"
          variant="outlined"
          placeholder="Để lại nhận xét và gửi"
          value={this.state.comment}
          onChange={event => this.setState({ comment: event.target.value })}
        />

        {this.state.comment != '' ? (
          <Button
            style={{ marginTop: 10 }}
            color="primary"
            variant="contained"
            onClick={() => {
              this.send_comment(this.state.comment);
              this.setState({ comment: '' });
            }}>
            Gửi
          </Button>
        ) : null}
      </Box>
    );
  }
}

export default CommentList;
