import React, { Component } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress
} from '@material-ui/core';
import UsersTable from './Table';
import CONST_DATA from 'constant';
import Axios from 'axios';
import { get_time_second, to_date_unix } from 'utility';

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  }
});

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: null,
      user_id: null,
      data_success: false,
      data: null,
      data_ticket: null,
      data_ticket_success: false
    };
  }

  check_login = () => {
    let login_data = localStorage.getItem(CONST_DATA.localStorage_login);

    if (login_data == null) {
      this.props.history.push(CONST_DATA.router_sign_in);
    } else {
      login_data = JSON.parse(login_data);
      this.setState({
        access_token: login_data.access_token,
        user_id: login_data.user_id
      });
    }
  };

  async componentDidMount() {
    await this.check_login();
    await this.get_data();
  }

  get_data = () => {
    const body_req = {
      user_id: this.state.user_id,
      access_token: this.state.access_token
    };

    Axios.post(CONST_DATA.get_ticket_new_comment, body_req)
      .then(response => {
        if (response.data.status == 'success') {
          this.setState({
            data_success: true,
            data: response.data.data
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  reload = () => {
    window.location.reload();
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            {this.state.data_success ? (
              <UsersTable
                user_id={this.state.user_id}
                access_token={this.state.access_token}
                data={this.state.data}
                reload={() => this.reload()}
              />
            ) : (
              <CircularProgress />
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(User);
