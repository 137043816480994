import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Button,
  Card,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Modal,
  Backdrop,
  Fade,
} from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import CONST_DATA from "constant";
import Axios from "axios";
import { get_time_second } from "utility";
import TableAll from "./TableAll";
import { ThreeSixtySharp, TransferWithinAStationSharp } from "@material-ui/icons";


const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
  },
  textField: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
});

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: null,
      full_name: "",
      user_name: "",
      data: null,
      data_success: false,
      role: "",
      open: false,
      user_id: "",
      isError: {
        isErrorFullName: false,
        isErrorUserName: false,
        isErrorRole: false,
      },
      errorFullNameText: "",
      errorUserNameText: "",
      errorRoleText: ""
    };
  }
  handleClose = () => {
    this.setState({
      open: false,
    });
  };
  handleOpen = () => {
    this.setState({
      open: true,
    });
  };
  check_login = () => {
    let login_data = localStorage.getItem(CONST_DATA.localStorage_login);

    if (login_data == null) {
      this.props.history.push(CONST_DATA.router_sign_in);
    } else {
      login_data = JSON.parse(login_data);
      this.setState({
        access_token: login_data.access_token,
        user_id: login_data.user_id,
      });
    }
  };

  async componentDidMount() {
    await this.check_login();
    await this.get_all_user();
  }

  get_all_user = () => {
    const headers = {
      user_id: this.state.user_id,
      access_token: this.state.access_token,
      "Content-Type": "application/json",
    };
    Axios.get(CONST_DATA.get_all_admin, { headers: headers })
      .then((response) => {
        if (response.data.status == "success") {
          console.log(response);
          this.setState({
            data: response.data.data,
            data_success: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render_list_admin = (item, classes) => {
    return (
      <Card className={classes.root}>
        <Typography>full_name: {item.full_name}</Typography>
        <Typography>user_name: {item.user_name}</Typography>
        <Typography>user_id: {item.user_id}</Typography>
      </Card>
    );
  };

  send_data_add = () => {
    const body_req = {
      full_name: this.state.full_name,
      user_name: this.state.user_name,
      role: this.state.role,
      is_active: false,
    };
    const headers = {
      access_token: this.state.access_token,
      user_id: this.state.user_id,
    };
    if (this.checkValidateFullName() && this.checkValidateUsername()) {
      Axios.post(CONST_DATA.creat_user_admin, body_req, { headers: headers })
        .then((response) => {
          if (response.data.status == "success") {
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else {
      this.checkValidateUsername();
      this.checkValidateFullName();
      this.checkValidateRole();
    }

  };
  checkValidateFullName = () => {
    let validate = true;
    if (this.state.full_name === "") {
      this.setState({
        isError: {
          isErrorFullName: true,
        },
        errorFullNameText: "Trường không được bỏ trống",
      });
      validate = false
    } else {
      this.setState({
        isError: {
          isErrorFullName: false,
        },
        errorFullNameText: "",
      });
    }
    return validate
  };
  checkValidateUsername = () => {
    let validate = true;
    if (this.state.user_name === "") {
      this.setState({
        isError: {
          isErrorUserName: true,
        },
        errorUserNameText: "Trường không được bỏ trống",
      });
      validate = false
    } else {
      this.setState({
        isError: {
          isErrorUserName: false,
        },
        errorUserNameText: "",
      });
    }
    return validate
  };
  checkValidateRole = () => {
    let validate = true;
    if (this.state.role === "") {
      this.setState({
        isError: {
          isErrorRole: true,
        },
        errorRoleText: "Chọn Role",
      });
      validate = false
    }
    else {
      this.setState({
        isError: {
          isErrorRole: false,
        },
        errorRoleText: "",
      });
    }
    return validate
  }

  render() {
    const { classes } = this.props;
    const { open } = this.state;
    return (
      <div className={classes.root}>
        <Grid spacing={4}>
          <Grid item lg={6} md={6} xl={12} xs={12}>
            <Grid
              direction="row"
              justify="flex-end"
              alignItems="center"
              container
            >
              <Button
                style={{ margin: 10 }}
                color="primary"
                variant="contained"
                onClick={this.handleOpen}
              >
                Thêm mới người dùng
              </Button>
            </Grid>
            {this.state.data_success ? (
              <TableAll
                data={this.state.data}
                user_id={this.state.user_id}
                access_token={this.state.access_token}
              ></TableAll>
            ) : (
              <CircularProgress />
            )}
          </Grid>
        </Grid>

        {/* MODAL THEM MOI */}
        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={this.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div className={classes.paper}>
                <Card className={classes.root}>
                  <Typography>Thêm mới người dùng</Typography>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    label="Tên người dùng"
                    variant="outlined"
                    value={this.state.full_name}
                    error={this.state.isError.isErrorFullName}
                    helperText={this.state.errorFullNameText}
                    onChange={(event) =>
                      this.setState({ full_name: event.target.value })
                    }
                    onBlur={this.checkValidateFullName}
                    required
                  />
                  <TextField
                    className={classes.textField}
                    fullWidth
                    label="Tên đăng nhập"
                    variant="outlined"
                    value={this.state.user_name}
                    error={this.state.isError.isErrorUserName}
                    helperText={this.state.errorUserNameText}
                    onChange={(event) =>
                      this.setState({ user_name: event.target.value })
                    }
                    onBlur={this.checkValidateUsername}
                    required
                  />
                  <Grid>
                    <FormControl
                      className={classes.formControl}
                      error={this.state.isError.isErrorRole}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Role
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.role}
                        onChange={(event) =>
                          this.setState({ role: event.target.value })
                        }
                      >
                        <MenuItem value={0}>Admin</MenuItem>
                        <MenuItem value={100}>CS</MenuItem>
                        <MenuItem value={50}>Kế toán</MenuItem>
                        <MenuItem value={55}>Kế toán khớp lệnh</MenuItem>
                        <MenuItem value={70}>Tư vấn</MenuItem>
                        <MenuItem value={10000}>kyc</MenuItem>
                      </Select>
                      <FormHelperText>
                        {this.state.errorRoleText}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Button
                    style={{ margin: 10 }}
                    color="primary"
                    variant="contained"
                    onClick={() => this.send_data_add()}
                  >
                    Add admin
                  </Button>
                </Card>
              </div>
            </Fade>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(User);
