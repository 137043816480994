import moment from 'moment';
import jwt from 'jsonwebtoken';

const to_date = date_num => {
  let time = moment(date_num * 1000)
    .locale('vi')
    .format('DD / MM / YYYY, h A');
  return time;
};

const to_date_min = date_num => {
  let time = moment(date_num * 1000)
    .locale('vi')
    .format('DD/MM/YYYY (hh:mm A)');
  return time;
};

const to_date_search = date_num => {
  let time = moment(date_num * 1000)
    .locale('vi')
    .format('DD/MM/YYYY');
  return time;
};

const to_date_unix = date_num => {
  let ts = moment(date_num, 'YYYY-MM-DD').valueOf();
  return ts / 1000;
};

const convert_date_to_search = date_num => {
  let time = moment(date_num)
    .locale('vi')
    .format('DD/MM/YYYY');
  return time;
};

const get_time_second = () => {
  let time_now = new Date().getTime() / 1000;
  time_now = Math.round(time_now);
  return time_now;
};

const bo_qua_detail = id => {
  id = parseInt(id);
  let result = null;
  if (id == 1) {
    result = 'Trùng đơn';
  }
  if (id == 2) {
    result = 'Tự hoàn thành';
  }
  if (id == 4) {
    result = 'Quá thời gian chờ bổ sung thông tin';
  }
  if (id == 3) {
    result = 'Khác';
  }
  return result;
};

const status_detail = id => {
  id = parseInt(id);
  let result = null;
  if (id == 1) {
    result = 'Tạo đơn';
  } else if (id == 10) {
    result = 'Nhận đơn';
  } else if (id == 100) {
    result = 'Đang giải quyết';
  } else if (id == 110) {
    result = 'Giao cho kế toán';
  } else if (id == 120) {
    result = 'Giao cho kế toán khớp lệnh';
  } else if (id == 200) {
    result = 'Bỏ qua';
  } else if (id == 300) {
    result = 'Chờ bổ sung thông tin';
  } else if (id == 350) {
    result = 'Điều chỉnh';
  } else if (id == 400) {
    result = 'Thành công';
  }
  else if (id == 250) {
    result = 'Cập nhật tài khoản ngân hàng';
  }
  else if (id == 500) {
    result = 'Đã chuyển tiền';
  }
  return result;
};

const gen_token = data => {
  const token = jwt.sign(data, 'finhaycs');
  return token;
};

const status_hoan_tra = id => {
  id = parseInt(id);
  let result = null;
  if (id == 0) {
    result = 'Khởi tạo';
  }
  if (id == 100) {
    result = 'Đã thông báo';
  }
  if (id == 200) {
    result = 'Đã cập nhật';
  }
  if (id == 300) {
    result = 'Đã chuyển tiền';
  }
  return result;
};

const portfolio_type_to_string = id => {
  id = parseInt(id);

  let result = null;
  if (id == 1) {
    return 'Rùa Hoàn Kiếm';
  }
  if (id == 2) {
    return 'Cò Trắng';
  }
  if (id == 3) {
    return 'Voi Rừng';
  }
  if (id == 4) {
    return 'Sao La';
  }
  if (id == 5) {
    return 'Trâu Nước';
  } else {
    return id;
  }
};

export {
  portfolio_type_to_string,
  bo_qua_detail,
  to_date_unix,
  convert_date_to_search,
  to_date_search,
  gen_token,
  to_date,
  to_date_min,
  status_detail,
  get_time_second,
  status_hoan_tra
};
