import React, { Component } from 'react';
import { TextField, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seach_text: ''
    };
  }

  render() {
    return (
      <div>
        <TextField
          placeholder="User_id, SDT, email, Mã yêu câu"
          id="outlined-basic"
          label="Tìm kiếm"
          variant="outlined"
          value={this.state.seach_text}
          onChange={event => this.setState({ seach_text: event.target.value })}
        />
        <IconButton>
          <SearchIcon
            onClick={() => this.props.onClick(this.state.seach_text)}
          />
        </IconButton>
      </div>
    );
  }
}

export default User;
