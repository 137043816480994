import React, { Component } from 'react';
import {
  Button,
  Card,
  Typography
} from '@material-ui/core';
import Axios from 'axios';
import CONST_DATA from 'constant';

// const styles = theme => ({
//   root: {
//     padding: theme.spacing(2)
//   },
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 200
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2)
//   }
// });

class CardImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text_ocr: null
    };
  }

  componentDidMount() { }

  get_ocr = image_name => {
    Axios.get(CONST_DATA.ocr_get_ocr + image_name)
      .then(response => {
        if (response.data.status === 'success') {
          this.setState({
            text_ocr: response.data.data
          });
        }
      })
      .catch(error => {
      });
  };

  render() {
    return (
      <Card style={{ padding: 10 }}>
        <img
          alt="demo"
          style={{ maxWidth: 400 }}
          src={CONST_DATA.get_image + this.props.data}
        />
        <Typography>{this.state.text_ocr}</Typography>

        {this.state.text_ocr === null ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.get_ocr(this.props.data)}>
            lấy thông tin từ ảnh
          </Button>
        ) : null}
      </Card>
    );
  }
}

export default CardImage;
