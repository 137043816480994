
import React, { Component } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  TextField,
  IconButton,
  FormControlLabel,
  InputLabel,
  Select,
  FormControl,
  MenuItem
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CONST_DATA from 'constant';
import Axios from 'axios';

const styles = theme => ({
  root: {},
  container: {
    maxHeight: '70vh'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 170
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
});

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      all_admin_data: null,
      all_admin_success: false
    };
  }

  componentDidMount() {
    this.get_all_admin();
  }

  get_all_admin = () => {
    Axios.get(CONST_DATA.get_admin_giao_viec)
      .then(response => {
        if (response.data.status == 'success') {
          this.setState({
            all_admin_data: response.data.data,
            all_admin_success: true
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Lọc theo admin</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={this.state.type_of_status}
          onChange={event => {
            this.setState({ type_of_status: event.target.value });
            this.props.onChange(event.target.value);
          }}>
          {this.state.all_admin_success
            ? this.state.all_admin_data.map(item => (
              <MenuItem value={item.user_id}>{item.full_name}</MenuItem>
            ))
            : null}
        </Select>
      </FormControl>
    );
  }
}

export default withStyles(styles)(User);