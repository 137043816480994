import React, { Component } from 'react';
import { Card, Typography } from '@material-ui/core';
import { change_transaction_id_api } from './Api';

class GiaoViec extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transaction_id: this.props.data.transaction_id
    };
  }

  change_transaction = transaction_id => {
    const body_req = {
      user_id: this.props.user_id,
      ticket_id: this.props.ticket_id,
      transaction_id: transaction_id,
      access_token: this.props.access_token
    };
    change_transaction_id_api(body_req, () => window.location.reload());
  };

  render() {
    if (
      this.props.data.ticket_type == 'CHUYỂN TIỀN' &&
      this.props.data.status_id >= 100
    ) {
      return (
        <Card
          style={{
            marginTop: 20,
            marginBottom: 20,
            padding: 20,
            backgroundColor: 'rgb(187,222,251)'
          }}>
          <Typography variant="h4">
            Transaction ID: {this.state.transaction_id}
          </Typography>
        </Card>
      );
    } else {
      return null;
    }
  }
}

export default GiaoViec;
